export const i18n = {
  title: 'Changelog',
  showMore: 'Show More',
  showLess: 'Show Less',
  version: 'Version: ',
  all: 'All Types',
  noMatch:
    'Nothing found. Use the filters on the left to select the changelog types to display.',
  label: {
    fix: {
      name: 'Fix',
      summary:
        'A change to address a defect that is significant enough to note in the changelog.',
    },
    new: {
      name: 'New',
      summary: 'A newly created endpoint.',
    },
    feature: {
      name: 'Feature',
      summary:
        'An addition to the endpoint that provides new functionality. Such as a query filter, additional data in the response, etc.',
    },
    refinement: {
      name: 'Refinement',
      summary:
        'A change to how the endpoint is used or responds, not directly related to new features. For example, changes that make the endpoint more consistent or easier to use.',
    },
    deprecated: {
      name: 'Deprecated',
      summary:
        'The resource endpoint is superseded by a newer version. See <a href="https://developers.procore.com/documentation/rest-api-lifecycle" target="_blank" rel="noopener noreferrer">API Lifecycle</a> for additional information.',
    },
    breaking: {
      name: 'Breaking',
      summary:
        'Changes that potentially cause failure to applications will only be introduced in a new version of a resource according to our <a href="https://developers.procore.com/documentation/rest-api-overview" target="_blank" rel="noopener noreferrer">versioning architecture</a>.',
    },
  },
  changeTypes: 'Change Types',
  searchChangelogs: 'Find Changelogs',
};
