import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    needHelp: 'Need help?',
    contactUs: 'Contact Us',
    helpSubject: 'Procore Marketplace - Help with',
    marketplaceError: 'Error: unable to retrieve marketplace app.',
    requiredField: 'Required Field',
    approve: 'Approve',
    reject: 'Reject',
    longUploadInProgress:
      'Please stay on this page while uploading. Navigating away from this page will stop the upload.',
    errors: {
      bannerTitle: 'The following errors occurred:',
      marketplaceUpdate:
        'An error occurred while updating the marketplace listing.',
      marketplaceFetch:
        'An error occurred while loading the marketplace listing.',
      notValid: 'Please correct the errors below before submitting for review',
    },
    labels: {
      marketplaceApp: {
        publishSilently:
          'Publish this app without notifying owner and collaborators',
        adminMessage: 'Admin Message',
        rejectionMessage: 'Marketplace submission status: Rejected',
        approvalMessage: 'Marketplace submission status: Approved',
        detailsButton: 'Details',
        statusMessage: "This marketplace listing's current status is: ",
        inReview: 'In Review',
        inReviewMessage: 'Marketplace submission status: In Review',
        inReviewDetails:
          'You must withdraw from review if you want to make changes.',
        about: 'About Your Company',
        regions: 'Regions',
        builtBy: 'Built By',
        soldBy: 'Sold By',
        regionOrCountry: 'You must select a region or country',
        name: 'Name',
        searchDescription: 'Search Description',
        tagline: 'Tagline',
        description: 'Description',
        videos: 'Videos',
        screenshots: 'Screenshots',
        logo: 'Logo',
        howItWorks: 'How it Works',
        category: 'Category',
        learnHowUrl: 'Learn How URL',
        contactUsEmail: 'Contact Us Email',
        supportEmail: 'Support Email',
        supportWebsiteUrl: 'Support Website URL',
        supportWebsiteLabel: 'Support Website Label',
        supportPhone: 'Support Phone',
        supportDocumentation: 'Support Documentation',
        salesInfo: 'Sales Info',
        salesName: 'Sales Contact Name',
        salesPhone: 'Sales Contact Phone',
        salesEmail: 'Sales Contact Email',
        helpfulLinkUrl: 'Link URL',
        helpfulLinkLabel: 'Link Label',
        featureBullet: 'Feature',
        productTool: 'Product',
        integrationRequirement: 'Requirement',
        beta: 'Beta',
        costsMoney: 'Costs Money',
        submitForApproval: 'Submit for Review',
        resubmitForApproval: 'Resubmit for Review',
        save: 'Save',
        saveDraft: 'Save Draft',
        preview: 'Preview',
        withdrawForApproval: 'Withdraw from Review',
        showInConcierge: 'Show In Concierge',
        securityTrust: {
          header: 'Security & Trust - Partner Self-Certified',
          badgeTitle: 'Security & Trust Badge',
          badgeDescription: 'Enable the Security & Trust Badge on the Procore App Marketplace.',
          selfAssessmentDocument: 'Self-Assessment Document',
        },
        info: {
          about: 'Information about your company',
          adminMessage:
            'Include direct feedback to the partner here if the marketplace listing is to be rejected.',
          builtBy: 'The name of your company',
          soldBy: 'The name of the organization selling the app',
          name: 'The app name that will appear on the Marketplace Tile page',
          searchDescription: 'Used for finding the app',
          tagline: 'A tagline for the app',
          description:
            'A thorough description of the features, capability, and functionality of your app',
          howItWorks:
            'Provide a description of how the integration between Procore and your App works. 500 character maximum.',
          logo: {
            size:
              '200 x 200 px dimensions with a transparent or white background. File Formats: Vector .EPS and .PNG',
            distinct:
              'Distinctive logo that does not resemble the Procore logo',
            copyright:
              "Logo does not infringe on anyone else's copyright or trademark",
          },
          category: 'Choose up to three categories your app falls under',
          learnHowUrl: 'The URL to learn how to install your app',
          contactUsEmail: 'The email address for the sales team for your app',
          supportEmail: 'The email address for the support team for your app',
          supportWebsiteUrl:
            'The URL for the support website link for your app',
          supportWebsiteLabel:
            'The label for the support website link for your app',
          supportPhone: 'The phone number for your support team.',
          supportDocumentation:
            'A link to the user documentation for your app.',
          salesName: 'The contact name of the primary salesperson for your app',
          salesPhone:
            'The phone number for the primary salesperson for your app',
          salesEmail:
            'The email address for the primary salesperson for your app',
          screenshots: {
            quantity:
              'Provide a minimum of 3 screenshots. Accepted File Formats: .PNG or .JPG. (Aspect ratio can be anywhere between 4:3 and 16:9 with minimum resolution 720x480 and maximum resolution 1920x1080.)',
            content:
              'Show the application in use, and not merely title art, login page, or a splashscreen.',
          },
          videos: {
            format:
              'Accepted File Formats: .MP4 or .MOV, Minimum resolution: 720p, 24fps screen recording. Must include instructional voice over.',
            instructions:
              'You can use the Demo Video as your Marketplace Video or make a separate promotional video that highlights the integration',
          },
          helpfulLinks:
            'A list of external links you want to include in your listing. These can be documentation, your company website, etc.',
          helpfulLinkUrl:
            'The url of a link to include in the "helpful links" section of your listing',
          helpfulLinkLabel:
            'The label of a link to include in the "helpful links" section of your listing',
          featureBullets:
            'This should be a list of features your app offers users. They will be shown as bullet points at the top of your marketplace listing. There is a 500 character limit per feature.',
          productTool:
            'Please select the Procore product tools that are required to use your app.',
          regionsRequirement:
            'Select regions/countries where your app is available.',
          regionsNote:
            'You may select an entire region or individual countries within that region.',
          contactNote:
            'The marketplace has options to either generate a "Sales lead" or help describe a "Self-service" option on how to purchase or install your software.',
          contactSecondNote:
            'For sales leads, a "Contact Us Button" creates a sales lead form to be sent to a specified sales email.',
          contactThirdNote:
            'If the app is "self-service" and does not need to go through a personal sales process, use the "Learn How Button" to refer to a website to inform the customer of the process to install/purchase the app.',
          contactFourthNote:
            'Here are some reference examples:',
          contactFifthNote:
            'Contact Us - Here is a link to',
          contactSixthNote:
            'in the marketplace where the "Contact Us" button is used.',
          contactSeventhNote:
            'Learn How - Here is a link to',
          contactEighthNote:
            'in the marketplace where the "Learn How" button is used.',
          contactNinthNote:
            'Use the toggle to control what is displayed in the marketplace listing:',
          contactUsButton:
            'Contact us Button',
          learnHowButton:
            'Learn how Button',
          createLeadButton:
            'Create direct lead in Salesforce',
          createLeadNote:
            'By selecting this option the marketplace will display a contact button, no email will be needed, this will create a direct lead in Salesforce.',
          createLeadSecondNote:
            'If you want an email to be sent instead, please select Contact Us Button and provide an email.',
          regionsOtherNote:
            'NOTE: When selecting an entire region, we will include any new countries Procore adds to that region.',
          integrationRequirement:
            'List any requirements that must be met in order for your App to function properly. For example, product subscriptions, proper licensing, third-party systems, etc.',
        },
      },
    },
    marketplaceListing: 'Marketplace Listing',
    placeholders: {
      appType: 'Select an App Type...',
      category: 'Select a Category...',
      description: 'Please enter description.',
      supportEmail: 'Who to request support from',
      supportUrl:
        'A website users can get information and support for your app.',
      supportPhone: 'x-xxx-xxx-xxxx',
      marketplaceApp: {
        category: 'Category',
        phone: 'x-xxx-xxx-xxxx',
      },
    },
    modals: {
      confirmAccept: {
        title: 'Confirm Listing Approval',
        body:
          'By approving this listing, you are confirming that all fields collected are acceptable by marketplace standards. If the listing requires any changes, do not approve it.',
        back: 'Back',
        approve: 'Approve',
      },
      confirmReject: {
        title: 'Confirm Listing Rejection',
        body:
          'When you reject this listing, a notification will be sent to the author asking them to correct it. Please make sure any issues you see are listed in your admin message.',
        back: 'Back',
        reject: 'Reject',
      },
      adminMessage: {
        title: 'Reviewer Notes',
        back: 'Back',
      },
      marketplaceSubmitConfirmation: {
        title: 'Submit for Review',
        body:
          'Once you submit your listing for review, our team will verify that you have met all Marketplace listing requirements. Once our review is complete, you will receive an email notification with the results.',
        confirm: 'Submit for Review',
        back: 'Back',
      },
      marketplaceWithdrawConfirmation: {
        title: 'Withdraw from Review',
        body:
          'If you withdraw your listing from review, you will need to resubmit in order to publish updates to your Marketplace listing.',
        confirm: 'Withdraw from Review',
        back: 'Back',
      },
    },
    published: 'Published',
    publishedDetails:
      'A version of this app is currently published in the Marketplace. New changes must be submitted for review and approved before taking effect.',
    marketplaceEnabled: 'Enable Marketplace Listing',
    download: 'Download',
    confirmDeletionModal: {
      header: 'Delete File?',
      body: 'Are you sure you want to delete %{file_name}?',
      confirm: 'Delete File',
      cancel: 'Cancel',
    },
    filePreviewModal: {
      noPreviewAvailable: 'No Preview Available',
      close: 'Close',
      viewDocument: 'View the document here',
    },
  },
});
