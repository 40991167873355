import React, { useState } from 'react';
import PT from 'prop-types';
import {
  Box,
  Button,
  Flex,
  FlexList,
  Required,
  Typography,
} from '@procore/core-react';
import CardFooter from '@/react/shared/card/CardFooter';
import { MARKETPLACE_APP_STATES } from '@/react/shared/constants';
import { MARKETPLACE_APP_FIELDS_PT } from '@/react/shared/developerAppShowPropTypes';
import ConfirmAcceptModal from './modals/ConfirmAcceptModal';
import ConfirmRejectModal from './modals/ConfirmRejectModal';
import MarketplaceSubmitConfirmationModal from './modals/MarketplaceSubmitConfirmationModal';
import MarketplaceWithdrawConfirmationModal from './modals/MarketplaceWithdrawConfirmationModal';

export const AdminFooter = ({
  fields,
  saveForm,
  getSaveFields,
  I18n,
  onChange,
  trackEvent,
  marketplaceDraftListingPath,
  newDraftPreviewActive,
}) => {
  const isPublished = fields.state === MARKETPLACE_APP_STATES.PUBLISHED;
  const saveDisabled = !fields.__modified__;
  const previewDisabled = !saveDisabled;
  const approveDisabled = fields.__modified__ || isPublished;
  const rejectDisabled = fields.__modified__ || isPublished;

  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [approveDisabledState, setApproveDisabledState] = useState(approveDisabled);

  if (fields.id != null && newDraftPreviewActive) {
    marketplaceDraftListingPath = marketplaceDraftListingPath.replace('{{:submission_id}}', fields.id);
  }

  return (
    <CardFooter>
      <Flex justifyContent="flex-end">
        <FlexList size="sm">
          <Button
            disabled={saveDisabled}
            data-qa="marketplace-app-update"
            data-testid="marketplace-app-update"
            variant="primary"
            onClick={() => {
              saveForm(getSaveFields());
            }}
          >
            {I18n.t('labels.marketplaceApp.save')}
          </Button>

          {(fields.id != null) &&
          <Button
            disabled={previewDisabled}
            data-qa="marketplace-app-update"
            variant="primary"
            onClick={() => {
              !previewDisabled && window.open(marketplaceDraftListingPath);
            }}
          >
            {I18n.t('labels.marketplaceApp.preview')}
          </Button>}
      
          <>
            <Button
              data-qa="marketplace-app-approve"
              onClick={() => {
                  if (!approveDisabledState) {
                    setApproveModalOpen(true);
                    setApproveDisabledState(true);
                  }
                }
              }
              disabled={approveDisabled}
            >
              {I18n.t('approve')}
            </Button>

            <ConfirmAcceptModal
              isVisible={approveModalOpen}
              hide={() => setApproveModalOpen(false)}
              I18n={I18n}
              fields={fields}
              toggleSilent={() =>
                onChange('silent', !fields.silent, 'marketplaceApp')
              }
              save={() => {
                saveForm({ state: 'published' });
                trackEvent(
                  'developers.apps.marketplace_listing.review_state_changed',
                  { state: 'approved' }
                );
              }}
            />
          </>

          <>
            <Button
              data-qa="marketplace-app-reject"
              onClick={() => setRejectModalOpen(true)}
              disabled={rejectDisabled}
            >
              {I18n.t('reject')}
            </Button>

            <ConfirmRejectModal
              isVisible={rejectModalOpen}
              hide={() => setRejectModalOpen(false)}
              I18n={I18n}
              id={fields.id}
              save={() => {
                saveForm({ state: 'draft' });
                trackEvent(
                  'developers.apps.marketplace_listing.review_state_changed',
                  { state: 'rejected' }
                );
              }}
            />
          </>
        </FlexList>
      </Flex>
    </CardFooter>
  );
};

AdminFooter.propTypes = {
  fields: PT.shape(MARKETPLACE_APP_FIELDS_PT.TYPES).isRequired,
  saveForm: PT.func.isRequired,
  getSaveFields: PT.func.isRequired,
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  onChange: PT.func.isRequired,
  trackEvent: PT.func,
  marketplaceDraftListingPath: PT.string.isRequired,
  newDraftPreviewActive: PT.bool.isRequired,
};

AdminFooter.defaultProps = {
  trackEvent: () => {},
};

export const Footer = ({
  canEdit,
  fields,
  canSubmitMarketplaceApp,
  marketplaceDraftListingPath,
  newDraftPreviewActive,
  valid,
  helpEmail,
  I18n,
  saveForm,
  getSaveFields,
  trackEvent,
  validateFields,
  setError,
}) => {
  const saveDisabled = !canEdit || !fields.__modified__;
  const previewDisabled = !saveDisabled;
  const submitDisabled =
    !canSubmitMarketplaceApp || !valid || fields.__modified__;
  const { IN_REVIEW, DRAFT } = MARKETPLACE_APP_STATES;
  const helpUrl = `mailto:${helpEmail}`;

  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);

  if (fields.id != null && newDraftPreviewActive) {
    marketplaceDraftListingPath = marketplaceDraftListingPath.replace('{{:submission_id}}', fields.id);
  }
  
  return (
    <CardFooter>
      <Flex justifyContent="space-between">
        <Required showLabel />
        <Box display="flex-column">
          <FlexList size="sm">
            <Button
              data-qa="marketplace-app-update"
              data-testid="marketplace-app-update"
              disabled={saveDisabled}
              variant="primary"
              onClick={() => {
                !saveDisabled && saveForm(getSaveFields());
              }}
            >
              {I18n.t('labels.marketplaceApp.saveDraft')}
            </Button>

            {(fields.id != null) &&
            <Button
              data-qa="marketplace-app-update"
              disabled={previewDisabled}
              variant="primary"
              onClick={() => {
                window.open(marketplaceDraftListingPath);
                validateFields(
                  () => {},
                  () => setError({message: I18n.t('errors.notValid')})
                );
              }}
            >
              {I18n.t('labels.marketplaceApp.preview')}
            </Button>}
            

            {!(fields.state === IN_REVIEW) && (
              <>
                <MarketplaceSubmitConfirmationModal
                  open={submitModalOpen}
                  hide={() => setSubmitModalOpen(false)}
                  I18n={I18n}
                  onSubmit={() => {
                    saveForm({ state: IN_REVIEW });
                    trackEvent(
                      'developers.apps.marketplace_listing.review_state_changed',
                      { state: 'submitted' }
                    );
                    setSubmitModalOpen(false);
                  }}
                />

                <Button
                  data-qa="marketplace-app-submit"
                  disabled={submitDisabled}
                  variant="primary"
                  onClick={() => {
                    if (!submitDisabled) {
                      validateFields(
                        () => setSubmitModalOpen(true),
                        () => setError({message: I18n.t('errors.notValid')})
                      );
                    }
                  }}
                >
                  {fields.admin_message
                    ? I18n.t('labels.marketplaceApp.resubmitForApproval')
                    : I18n.t('labels.marketplaceApp.submitForApproval')}
                </Button>
              </>
            )}

            {fields.state === IN_REVIEW && (
              <>
                <MarketplaceWithdrawConfirmationModal
                  open={withdrawModalOpen}
                  hide={() => setWithdrawModalOpen(false)}
                  I18n={I18n}
                  onWithdraw={() => {
                    saveForm({ state: DRAFT });
                    setWithdrawModalOpen(false);
                  }}
                />

                <Button
                  data-qa="marketplace-app-withdraw"
                  variant="primary"
                  onClick={() => setWithdrawModalOpen(true)}
                >
                  {I18n.t('labels.marketplaceApp.withdrawForApproval')}
                </Button>
              </>
            )}
          </FlexList>
          <Box paddingTop="sm" paddingBottom="sm" alignSelf="flex-end">
            <Typography intent="small">
              <i>
                {I18n.t('needHelp')} <a href={helpUrl}>{I18n.t('contactUs')}</a>
              </i>
            </Typography>
          </Box>
        </Box>
      </Flex>
    </CardFooter>
  );
};

Footer.propTypes = {
  canEdit: PT.bool.isRequired,
  canSubmitMarketplaceApp: PT.bool.isRequired,
  marketplaceDraftListingPath: PT.string.isRequired,
  newDraftPreviewActive: PT.bool.isRequired,
  fields: PT.shape(MARKETPLACE_APP_FIELDS_PT.TYPES).isRequired,
  helpEmail: PT.string,
  saveForm: PT.func.isRequired,
  getSaveFields: PT.func.isRequired,
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  setError: PT.func.isRequired,
  trackEvent: PT.func,
  valid: PT.bool.isRequired,
  validateFields: PT.func.isRequired,
};

Footer.defaultProps = {
  trackEvent: () => {},
  helpEmail: '',
};
