import React from 'react';
import { Card } from '@procore/core-react';
import MarketplaceAppForm from '@/react/common/marketplace_form';
import { serialize } from 'object-to-formdata';
import { UseShowContext } from '../Show.context';
import { ListingApplication } from '../cards/marketplace/ListingApplication';

const MarketplaceForm = () => {
  const {
    developerAppId,
    canEdit,
    regions,
    productTools,
    marketplaceReviewerEmail,
    marketplaceDraftListingPath,
    marketplaceListingApplicationEnabled,
    newDraftPreviewActive,
    api,
    isProcoreEmployee,
    state: { devApp, setDevApp, trackEvent },
  } = UseShowContext();

  const createOrUpdateMarketplaceApp = (marketplaceApp, onSuccess, onError) => {
    const marketplaceAppId = devApp?.last_marketplace_app?.id;

    const formattedFormData = (items) => {
      return items.reduce((acc, curr, currIdx) => {
        acc[currIdx] = curr;
        return acc;
      }, {});
    };

    const formData = serialize(
      {
        submission: {
          ...marketplaceApp,
          ...{
            pictures_attributes: formattedFormData(
              marketplaceApp.pictures || []
            ),
            videos_attributes: formattedFormData(marketplaceApp.videos || []),
          },
        },
      },
      { allowEmptyArrays: true }
    );

    let apiCall;

    if (marketplaceAppId && marketplaceApp.state !== 'published') {
      apiCall = api.updateMarketplaceApp(marketplaceAppId, formData);
    } else {
      apiCall = api.createMarketplaceApp(developerAppId, formData);
    }

    apiCall
      .then(({ data }) => {
        setDevApp({ ...devApp, last_marketplace_app: data });
        onSuccess();
      })
      .catch(onError);
  };

  return (
    <>
      {(devApp.marketplace_enabled || !marketplaceListingApplicationEnabled) && (
        <Card data-testid="marketplace-form">
          <MarketplaceAppForm
            isAdminView={false}
            canEdit={canEdit}
            canSubmitMarketplaceApp
            marketplaceDraftListingPath={marketplaceDraftListingPath}
            newDraftPreviewActive={newDraftPreviewActive}
            unmappedFields={devApp.last_marketplace_app}
            save={createOrUpdateMarketplaceApp} // Save expects a function that returns a promise
            uploadWistiaVideo={api.uploadWistiaVideo} // uploadWistiaVideo expects a function that returns a promise
            productTools={productTools}
            regions={regions}
            helpEmail={marketplaceReviewerEmail}
            hasPublishedApp={false}
            trackEvent={trackEvent}
            isProcoreEmployee={isProcoreEmployee}
          />
        </Card>
      )}
      {marketplaceListingApplicationEnabled && !devApp.marketplace_enabled && (
        <ListingApplication devAppId={developerAppId} />
      )}
    </>
  );
};

export default MarketplaceForm;
