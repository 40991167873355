import React from 'react';
import { Box, Button } from '@procore/core-react';
import Form from '@/react/shared/form';
import CopyOnClick from '@/react/shared/copyOnClick/CopyOnClick';
import ChangeIndicator from '../ChangeIndicator';

const MarketplaceFormLabel = ({
  changeFields,
  contentI18nTag,
  copyText,
  I18n,
  showCopyButton,
  showPopover,
  hasChanged,
}) => {
  return (
    <Box
      display="flex-row"
      alignItems="center"
      marginBottom={showCopyButton ? 'sm' : 'none'}
    >
      <ChangeIndicator changed={hasChanged(changeFields)}>
        {showPopover ? (
          <Form.PopoverLabel
            labelContent={I18n.t(`labels.marketplaceApp.${contentI18nTag}`)}
            popoverContent={I18n.t(
              `labels.marketplaceApp.info.${contentI18nTag}`
            )}
          />
        ) : (
          <span>{I18n.t(`labels.marketplaceApp.${contentI18nTag}`)}</span>
        )}
      </ChangeIndicator>
      {showCopyButton && (
        <Box marginLeft="sm">
          <CopyOnClick textToCopy={copyText}>
            <Button variant="secondary" size="sm">
              Copy
            </Button>
          </CopyOnClick>
        </Box>
      )}
    </Box>
  );
};

export default MarketplaceFormLabel;
