import React from 'react';
import PT from 'prop-types';
import { pathOr } from 'ramda';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../StateContainer';

class TabContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: 0 }; // 0: no show, 1: show contact us, 2: show learn how, 3: create lead.
  }

  radioHandler = (status) => {
    this.setState({ status });
  };

  render() {
    const {
      fields,
      validations,
      onFieldChange,
      I18n,
      isProcoreEmployee,
      isDisabled,
      FormLabel,
    } = this.props;

    const { status } = this.state;

    const onChange = (...fieldPath) => (value) =>
      onFieldChange([MARKETPLACE_TABS.CONTACT, ...fieldPath], value);

    return (
      <div>
        <p>{I18n.t('labels.marketplaceApp.info.contactNote')}&nbsp;
        {I18n.t('labels.marketplaceApp.info.contactSecondNote')}&nbsp;
        {I18n.t('labels.marketplaceApp.info.contactThirdNote')}</p>
        <p>{I18n.t('labels.marketplaceApp.info.contactFourthNote')}</p>
        <ul>
          <li>{I18n.t('labels.marketplaceApp.info.contactFifthNote')} <a href="https://marketplace.procore.com/apps/procore-estimating">Procore Estimating</a> {I18n.t('labels.marketplaceApp.info.contactSixthNote')}</li>
          <br />
          <li>{I18n.t('labels.marketplaceApp.info.contactSeventhNote')} <a href="https://marketplace.procore.com/apps/procore-drive">Procore Drive</a> {I18n.t('labels.marketplaceApp.info.contactEighthNote')}</li>
        </ul>
        <p>{I18n.t('labels.marketplaceApp.info.contactNinthNote')}</p>
        <div style={{display: 'flex', flexDirection: 'row'}} data-qa="marketplace-app-contact-toggle">
        <Form.RadioButton
          checked={status === 1 || (status === 0 && !fields.create_lead && !!fields.contact_us_email)}
          text={I18n.t('labels.marketplaceApp.info.contactUsButton')}
          onChange={() => {
            this.radioHandler(1);
            onChange('create_lead')(false)
          }}
        />

        <Form.RadioButton
          checked={status === 2 || (status === 0 && !fields.contact_us_email && !fields.create_lead && !!fields.learn_how_url)}
          text={I18n.t('labels.marketplaceApp.info.learnHowButton')}
          onChange={() => {
            this.radioHandler(2);
            onChange('create_lead')(false)
          }}
        />

        {isProcoreEmployee && <Form.RadioButton
          checked={status === 3 || (status === 0 && fields.create_lead)}
          text={I18n.t('labels.marketplaceApp.info.createLeadButton')}
          onChange={(e) => {
            this.radioHandler(3);
            onChange('create_lead')(e.target.checked)
          }}
        />}
        </div>

        <Form.Content>
          <Form.Column>
            {(status === 1 || (status === 0 && !fields.create_lead && fields.contact_us_email)) && <Form.Field
              data-qa="marketplace-app-contact-us-email"
              disabled={isDisabled}
              label={
                <FormLabel
                  changeFields={['contact_us_email']}
                  contentI18nTag="contactUsEmail"
                  copyText={fields.contact_us_email}
                />
              }
              type="email"
              value={fields.contact_us_email}
              errors={validations.contact_us_email}
              onChange={(e) => onChange('contact_us_email')(e.target.value)}
            />}

            {(status === 2 || (status === 0 && !fields.contact_us_email && !fields.create_lead && fields.learn_how_url)) && <Form.Field
              data-qa="marketplace-app-learn-how-url"
              disabled={isDisabled}
              label={
                <FormLabel
                  changeFields={['learn_how_url']}
                  contentI18nTag="learnHowUrl"
                  copyText={fields.learn_how_url}
                />
              }
              type="text"
              value={fields.learn_how_url}
              errors={validations.learn_how_url}
              onChange={(e) => onChange('learn_how_url')(e.target.value)}
            />}

            {(status === 3 || (status === 0 && fields.create_lead)) && <div><p>
              <br />{I18n.t('labels.marketplaceApp.info.createLeadNote')}
              <br />{I18n.t('labels.marketplaceApp.info.createLeadSecondNote')}
            </p></div>}
          </Form.Column>
        </Form.Content>
      </div>
    );
  }
}

TabContact.propTypes = {
  I18n: PT.shape({}).isRequired,
  fields: PT.shape({}).isRequired,
  publishedFields: PT.shape({}).isRequired,
  validations: PT.shape({}).isRequired,
  isDisabled: PT.bool.isRequired,
  isProcoreEmployee: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  hasChanged: PT.func.isRequired,
};

export default TabContact;
