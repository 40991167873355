import React from 'react';
import PT from 'prop-types';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../StateContainer';
import ChangeIndicator from '../ChangeIndicator';

class TabLinks extends React.Component {
  renderLinkFields(link, index) {
    const {
      I18n,
      onFieldChange,
      isDisabled,
      hasChanged,
      FormLabel,
    } = this.props;

    const onChange = (...fieldPath) => (value) =>
      onFieldChange([MARKETPLACE_TABS.LINKS, ...fieldPath], value);

    return (
      <li key={index}>
        <Form.Row>
          <Form.Field
            data-qa={`marketplace-app-helpful-link-url-${index}`}
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['helpful_links', index, 'url']}
                contentI18nTag="helpfulLinkUrl"
                copyText={link.url}
              />
            }
            type="text"
            value={link.url}
            onChange={(e) =>
              onChange('helpful_links', index, 'url')(e.target.value)
            }
          />

          <Form.Field
            data-qa={`marketplace-app-helpful-link-label-${index}`}
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['helpful_links', index, 'label']}
                contentI18nTag="helpfulLinkLabel"
                copyText={link.label}
              />
            }
            type="text"
            value={link.label}
            onChange={(e) =>
              onChange('helpful_links', index, 'label')(e.target.value)
            }
          />
        </Form.Row>
      </li>
    );
  }

  render() {
    const { fields, publishedFields, I18n } = this.props;

    return (
      <>
        <Form.Content vertical>
          <p>{I18n.t('labels.marketplaceApp.info.helpfulLinks')}</p>
          <ul>
            {[
              this.renderLinkFields(fields.helpful_links[0] || {}, 0),
              this.renderLinkFields(fields.helpful_links[1] || {}, 1),
              this.renderLinkFields(fields.helpful_links[2] || {}, 2)
            ]}
          </ul>
        </Form.Content>
      </>
    );
  }
}

TabLinks.propTypes = {
  I18n: PT.shape({}).isRequired,
  fields: PT.shape({}).isRequired,
  publishedFields: PT.shape({}).isRequired,
  isDisabled: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  hasChanged: PT.func.isRequired,
};

export default TabLinks;
