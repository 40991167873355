/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */

import PT from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  Input,
  Table,
  Box,
  H1,
  H2,
  H3,
  Modal,
} from '@procore/core-react';
import { clone } from 'ramda';

import {
  retrieveBuckets,
  deleteBucket,
  updateBucket,
  submitBucket,
} from '../api';

const bucketServers = [
  'US01_k8s',
  'US02_k8s',
  'Sandbox_US01_k8s',
  'Sandbox_US02_k8s',
];

const errorCallback = (msg) => window.alert(`${msg}`);

const parseBuckets = (buckets, server) => {
  const parsedBuckets = {};

  buckets.forEach((bucket) => {
    bucket.bucket_templates = bucket.bucket_templates.map((template) => {
      template.server = server;
      return template;
    });
    bucket.ids = {
      [server]: bucket.id,
    };
    parsedBuckets[bucket.domain] = bucket;
  });
  return parsedBuckets;
};

const squashBucketTemplates = (parsedBuckets, serverBuckets, server) => {
  serverBuckets.forEach((bucket) => {
    bucket.bucket_templates = bucket.bucket_templates.map((template) => {
      template.server = server;
      return template;
    });
    if (parsedBuckets[bucket.domain]) {
      // Removes any duplicate buckets for display
      parsedBuckets[bucket.domain].bucket_templates = [
        ...parsedBuckets[bucket.domain].bucket_templates,
        ...bucket.bucket_templates,
      ].filter(
        (v, i, a) =>
          a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
      );
    } else {
      parsedBuckets[bucket.domain] = bucket;
      parsedBuckets[bucket.domain].ids = {};
    }

    parsedBuckets[bucket.domain].ids[server] = bucket.id;
  });
  return parsedBuckets;
};

const getAndSetBuckets = (
  server,
  buckets,
  setBuckets,
  retrievingBucket,
  setRetrievingBucket
) => {
  setRetrievingBucket({
    ...retrievingBucket,
    [server]: true,
  });

  retrieveBuckets(server, errorCallback).then((bucketData) => {
    setBuckets({
      ...buckets,
      [server]: bucketData,
    });

    setRetrievingBucket({
      ...retrievingBucket,
      [server]: false,
    });
  });
};

const handleDeleteBucket = (
  bucketInfo,
  bucketID,
  server,
  buckets,
  setBuckets,
  retrievingBucket,
  setRetrievingBucket
) => {
  if (
    window.confirm(`Confirm deletion? \n
                      Bucket: ${bucketInfo}`)
  ) {
    setRetrievingBucket({
      ...retrievingBucket,
      [server]: true,
    });
    deleteBucket(bucketID, server, errorCallback).then(() => {
      getAndSetBuckets(
        server,
        buckets,
        setBuckets,
        retrievingBucket,
        setRetrievingBucket
      );
    });
  }
};

const handleSubmitBucket = (
  bucketData,
  server,
  buckets,
  setBuckets,
  setSubmitting,
  retrievingBucket,
  setRetrievingBucket,
  isEdit = false
) => {
  if (window.confirm('Submit this bucket?')) {
    setSubmitting(true);
    if (isEdit) {
      updateBucket(
        bucketData.ids[server],
        bucketData,
        server,
        errorCallback
      ).then(() => {
        setSubmitting(false);
        getAndSetBuckets(
          server,
          buckets,
          setBuckets,
          retrievingBucket,
          setRetrievingBucket
        );
      });
    } else {
      submitBucket(bucketData, server, errorCallback).then(() => {
        setSubmitting(false);
        getAndSetBuckets(
          server,
          buckets,
          setBuckets,
          retrievingBucket,
          setRetrievingBucket
        );
      });
    }
  }
};

const RateLimitsTableRow = ({
  bucket,
  buckets,
  setBuckets,
  retrievingBucket,
  setRetrievingBucket,
  setEditBucket,
}) => (
  <>
    <Table.BodyRow>
      <Table.BodyCell>
        {Object.entries(bucket.ids).map(([key, value]) => (
          <Button
            key={`${bucket.domain}=${key}`}
            onClick={() => {
              handleDeleteBucket(
                `${bucket.name}:${bucket.client_id}:${bucket.domain} on ${key}`,
                value,
                key,
                buckets,
                setBuckets,
                retrievingBucket,
                setRetrievingBucket
              );
            }}
          >
            Delete Bucket on {key}
          </Button>
        ))}
        <Button
          variant="secondary"
          onClick={() => {
            const bucketClone = { ...bucket };
            bucketClone.bucket_templates = [
              {
                name: 'main',
                fill_type: 'count',
                interval_ms: 3600000,
                limit: 7200,
              },
              {
                name: 'spike',
                fill_type: 'count',
                interval_ms: 10000,
                limit: 100,
              },
            ];
            setEditBucket(bucketClone);
          }}
        >
          Edit Bucket
        </Button>
      </Table.BodyCell>
      <Table.BodyCell>
        <Table.TextCell>
          {`${bucket.name}:${bucket.client_id}:${bucket.domain}`}
        </Table.TextCell>
      </Table.BodyCell>
      <Table.BodyCell>
        <Table.Container>
          <Table>
            <Table.Header>
              <Table.HeaderRow>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Interval</Table.HeaderCell>
                <Table.HeaderCell>Limit</Table.HeaderCell>
                <Table.HeaderCell>Server</Table.HeaderCell>
              </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
              {bucket.bucket_templates &&
                bucket.bucket_templates.map((template, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Table.BodyRow key={`${template.name}-${index}`}>
                    <Table.BodyCell>
                      <Table.TextCell>{template.name}</Table.TextCell>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Table.TextCell>{template.interval_ms}</Table.TextCell>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Table.TextCell>{template.limit}</Table.TextCell>
                    </Table.BodyCell>
                    <Table.BodyCell>
                      <Table.TextCell>{template.server}</Table.TextCell>
                    </Table.BodyCell>
                  </Table.BodyRow>
                ))}
            </Table.Body>
          </Table>
        </Table.Container>
      </Table.BodyCell>
    </Table.BodyRow>
  </>
);

const RateLimitsTable = ({
  parsedBuckets,
  buckets,
  setBuckets,
  retrievingBucket,
  setRetrievingBucket,
}) => {
  const [editBucket, setEditBucket] = useState(null);

  return (
    <>
      <Modal open={editBucket} onClickOverlay={() => setEditBucket(null)}>
        <Modal.Header onClose={() => setEditBucket(null)}>
          Edit Bucket
        </Modal.Header>
        <Modal.Body>
          <RateLimitsForm
            buckets={buckets}
            bucketServers={bucketServers}
            setRetrievingBucket={setRetrievingBucket}
            setBuckets={setBuckets}
            retrievingBucket={retrievingBucket}
            initialFormState={editBucket}
            isEdit
          />
        </Modal.Body>
      </Modal>
      <Table.Container>
        <Table>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell>Actions</Table.HeaderCell>
              <Table.HeaderCell>Item</Table.HeaderCell>
              <Table.HeaderCell>Bucket Details</Table.HeaderCell>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {Object.entries(parsedBuckets).map(([key, value]) => (
              <RateLimitsTableRow
                key={key}
                bucket={value}
                buckets={buckets}
                setBuckets={setBuckets}
                retrievingBucket={retrievingBucket}
                setRetrievingBucket={setRetrievingBucket}
                setEditBucket={setEditBucket}
              />
            ))}
          </Table.Body>
        </Table>
      </Table.Container>
    </>
  );
};

const RateLimitsForm = ({
  buckets,
  bucketServers,
  setBuckets,
  retrievingBucket,
  setRetrievingBucket,
  initialFormState = {
    name: '',
    domain: '',
    client_id: '',
    bucket_templates: [
      {
        name: 'main',
        fill_type: 'count',
        interval_ms: 3600000,
        limit: 7200,
      },
      {
        name: 'spike',
        fill_type: 'count',
        interval_ms: 10000,
        limit: 100,
      },
    ],
  },
  isEdit = false,
}) => {
  const [formValues, setFormValues] = useState(initialFormState);
  const [submitting, setSubmitting] = useState(false);

  const handleInputChange = (field, value) => {
    setFormValues({ ...formValues, [field]: value });
  };

  const handleBucketInputChange = (field, value, bucketIndex) => {
    const updatedBuckets = formValues.bucket_templates;
    updatedBuckets[bucketIndex][field] = value;

    setFormValues({ ...formValues, bucket_templates: updatedBuckets });
  };

  const addBucketTemplate = () => {
    setFormValues({
      ...formValues,
      bucket_templates: [
        ...formValues.bucket_templates,
        {
          name: '',
          fill_type: 'count',
          interval_ms: 10000,
          limit: 100,
        },
      ],
    });
  };

  const helperLabelText = {
    lineHeight: '26px',
    borderBottom: '1px dashed #000',
    height: '10px'
  }

  return (
    <>
      {!isEdit && (
        <Box paddingBottom="md">
          <H2>Add Bucket</H2>
        </Box>
      )}
      <Box paddingBottom="xl">
        <Flex>
          <Box>
            <label style={helperLabelText} title="this is the name of the bucket">Name</label>
            <Input
              id="bucketName"
              value={formValues.name}
              onChange={(e) => {
                handleInputChange('name', e.target.value);
              }}
            />
          </Box>
          <Box paddingLeft="md">
            <label style={helperLabelText} title="this should always be just `*`">Client ID</label>
            <Input
              id="bucketClientID"
              value={formValues.client_id}
              onChange={(e) => {
                handleInputChange('client_id', e.target.value);
              }}
            />
          </Box>
          <Box paddingLeft="md">
            <label style={helperLabelText} title="this is the OAuth App ID of a particular application (on this page: https://developers.procore.com/admin/oauth_applications)">Domain</label>
            <Input
              value={formValues.domain}
              onChange={(e) => {
                handleInputChange('domain', e.target.value);
              }}
            />
          </Box>
        </Flex>
        <Box paddingTop="md" paddingBottom="md">
          <H3>Bucket Templates</H3>
        </Box>
        {formValues.bucket_templates.map((bucket, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box paddingBottom="md" key={index}>
            <Flex>
              <Box>
                <label>Name (main/spike)</label>
                <Input
                  value={bucket.name}
                  onChange={(e) => {
                    handleBucketInputChange('name', e.target.value, index);
                  }}
                />
              </Box>
              <Box paddingLeft="md">
                <label>Fill Type (quota/count)</label>
                <Input
                  value={bucket.fill_type}
                  onChange={(e) => {
                    handleBucketInputChange('fill_type', e.target.value, index);
                  }}
                />
              </Box>
              <Box paddingLeft="md">
                <label>Limit</label>
                <Input
                  type="number"
                  value={bucket.limit}
                  onChange={(e) => {
                    handleBucketInputChange('limit', e.target.value, index);
                  }}
                />
              </Box>
              <Box paddingLeft="md">
                <label>Interval (milliseconds)</label>
                <Input
                  type="number"
                  value={bucket.interval_ms}
                  onChange={(e) => {
                    handleBucketInputChange(
                      'interval_ms',
                      e.target.value,
                      index
                    );
                  }}
                />
              </Box>
            </Flex>
          </Box>
        ))}
        <Box paddingBottom="md">
          <Button onClick={addBucketTemplate} variant="secondary">
            Add Another Bucket Template
          </Button>
          <Box paddingTop="md">
            {bucketServers.map((server) => (
              <Button
                key={`submit-bucket-${server}`}
                disabled={submitting}
                onClick={() => {
                  handleSubmitBucket(
                    formValues,
                    server,
                    buckets,
                    setBuckets,
                    setSubmitting,
                    retrievingBucket,
                    setRetrievingBucket,
                    isEdit
                  );
                }}
                style={{
                  margin: '5px',
                  backgroundColor: server.endsWith('_k8s') ? 'blue' : '',
                  color: server.endsWith('_k8s') ? 'white' : '',
                  padding: '0', // Remove padding
                }}
              >
                Submit Bucket to{server}
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const QuotaMinderServers = () => {
  const [buckets, setBuckets] = useState({
    US01_k8s: [],
    US02_k8s: [],
    Sandbox_US01_k8s: [],
    Sandbox_US02_k8s: [],
  });

  const [parsedBuckets, setParsedBuckets] = useState({});

  const [retrievingBucket, setRetrievingBucket] = useState({
    US01_k8s: false,
    US02_k8s: false,
    Sandbox_US01_k8s: false,
    Sandbox_US02_k8s: false,
  });

  // Parse and render buckets if the buckets item changes
  // from deletion, creation, or retrieval
  useEffect(() => {
    let reparsedBuckets;

    reparsedBuckets = parseBuckets(clone(buckets.US01_k8s), 'US01_k8s');

    reparsedBuckets = squashBucketTemplates(
      reparsedBuckets,
      clone(buckets.US02_k8s),
      'US02_k8s'
    );

    reparsedBuckets = squashBucketTemplates(
      reparsedBuckets,
      clone(buckets.Sandbox_US01_k8s),
      'Sandbox_US01_k8s'
    );

    reparsedBuckets = squashBucketTemplates(
      reparsedBuckets,
      clone(buckets.Sandbox_US02_k8s),
      'Sandbox_US02_k8s'
    );

    setParsedBuckets(reparsedBuckets);
  }, [buckets]);

  return (
    <Box padding="xl">
      <Box paddingBottom="xl">
        <H1>QuotaMinder</H1>
      </Box>
      <RateLimitsForm
        buckets={buckets}
        bucketServers={bucketServers}
        setBuckets={setBuckets}
        retrievingBucket={retrievingBucket}
        setRetrievingBucket={setRetrievingBucket}
      />
      <Box paddingBottom="xl">
        <H2>Retrieve Buckets</H2>
        <Box>
          {bucketServers.map((server) => (
            <Button
              key={`retrieve-${server}`}
              onClick={() => {
                getAndSetBuckets(
                  server,
                  buckets,
                  setBuckets,
                  retrievingBucket,
                  setRetrievingBucket
                );
              }}
              style={{
                margin: '5px',
                backgroundColor: server.endsWith('_k8s') ? 'blue' : '',
                color: server.endsWith('_k8s') ? 'white' : '',
              }}
            >
              {server}
            </Button>
          ))}
        </Box>
      </Box>
      <Box paddingBottom="xl">
        <H2>View Buckets</H2>
      </Box>
      <Box>
        <RateLimitsTable
          parsedBuckets={parsedBuckets}
          buckets={buckets}
          setBuckets={setBuckets}
          retrievingBucket={retrievingBucket}
          setRetrievingBucket={setRetrievingBucket}
        />
      </Box>
    </Box>
  );
};

const bucketPropTypes = PT.shape({
  name: PT.string.isRequired,
  client_id: PT.string.isRequired,
  domain: PT.string.isRequired,
  ids: PT.objectOf(PT.number),
  bucket_templates: PT.arrayOf(
    PT.shape({
      name: PT.string.isRequired,
      interval_ms: PT.number.isRequired,
      limit: PT.number.isRequired,
      server: PT.string,
    })
  ).isRequired,
});

const bucketState = PT.objectOf(PT.arrayOf(bucketPropTypes));

RateLimitsForm.propTypes = {
  buckets: bucketState.isRequired,
  bucketServers: PT.arrayOf(PT.string).isRequired,
  setBuckets: PT.func.isRequired,
  retrievingBucket: PT.shape({}).isRequired,
  setRetrievingBucket: PT.func.isRequired,
};

RateLimitsTable.propTypes = {
  parsedBuckets: PT.objectOf(bucketPropTypes).isRequired,
  buckets: bucketState.isRequired,
  setBuckets: PT.func.isRequired,
  retrievingBucket: PT.shape({}).isRequired,
  setRetrievingBucket: PT.func.isRequired,
};

RateLimitsTableRow.propTypes = {
  bucket: bucketPropTypes.isRequired,
  buckets: bucketState.isRequired,
  setBuckets: PT.func.isRequired,
  retrievingBucket: PT.shape({}).isRequired,
  setRetrievingBucket: PT.func.isRequired,
};

export default QuotaMinderServers;
