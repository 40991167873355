import { path, pickAll } from 'ramda';
import React from 'react';
import PT from 'prop-types';
import { MARKETPLACE_APP_STATES } from '@/react/shared/constants';
import { Box, Tabs } from '@procore/core-react';
import Form from '@/react/shared/form';
import { Error } from '@procore/core-icons';
import {
  MARKETPLACE_APP_FIELDS_PT,
  PRODUCT_TOOLS_PT,
  REGIONS_PT,
} from '@/react/shared/developerAppShowPropTypes';
import MarketplaceFormLabel from '../MarketplaceFormLabel';
import { MARKETPLACE_TABS } from '../StateContainer';
import TabAppInfo from './TabAppInfo';
import TabRegions from './TabRegions';
import TabLinks from './TabLinks';
import TabFeatures from './TabFeatures';
import TabSupport from './TabSupport';
import TabProducts from './TabProducts';
import TabRequirements from './TabRequirements';
import TabMedia from './TabMedia';
import TabContact from './TabContact';

const TabbedFormBody = (props) => {
  const {
    isAdminView,
    canEdit,
    fields,
    I18n,
    onChange,
    publishedFields,
    tab,
    validations,
    productTools,
    isProcoreEmployee,
    regions,
    switchTab,
    errorsPresent,
  } = props;

  const isDisabled = isAdminView
    ? false
    : !canEdit || fields.state === MARKETPLACE_APP_STATES.IN_REVIEW;

  const onFieldChange = (field, value) => {
    onChange(field, value, 'marketplaceApp');
  };

  const tabProps = {
    I18n,
    isAdminView,
    isDisabled,
    onFieldChange,
    fields: fields[tab] || {},
    publishedFields: publishedFields[tab] || {},
    validations: validations[tab] || {},
  };

  const hasChanged = (fieldPath) =>
    isAdminView &&
    path(fieldPath, fields[tab]) !== path(fieldPath, publishedFields[tab]);

  tabProps.hasChanged = hasChanged;

  /* eslint-disable react/prop-types */
  tabProps.FormLabel = ({
    changeFields,
    contentI18nTag,
    copyText = '',
    showCopyButton = true,
    showPopover = true,
  }) => {
    return (
      <MarketplaceFormLabel
        changeFields={changeFields}
        contentI18nTag={contentI18nTag}
        copyText={copyText}
        I18n={I18n}
        showCopyButton={isAdminView && showCopyButton}
        showPopover={showPopover}
        hasChanged={hasChanged}
      />
    );
  };
  /* eslint-enable react/prop-types */

  const TabName = ((tabName) => {
    switch (tabName) {
      case MARKETPLACE_TABS.APP:
        return TabAppInfo;
      case MARKETPLACE_TABS.REGIONS:
        tabProps.regions = regions;
        return TabRegions;
      case MARKETPLACE_TABS.LINKS:
        return TabLinks;
      case MARKETPLACE_TABS.FEATURES:
        return TabFeatures;
      case MARKETPLACE_TABS.SUPPORT:
        return TabSupport;
      case MARKETPLACE_TABS.TOOLS:
        tabProps.productTools = productTools;
        return TabProducts;
      case MARKETPLACE_TABS.REQUIREMENTS:
        return TabRequirements;
      case MARKETPLACE_TABS.CONTACT:
        tabProps.isProcoreEmployee = isProcoreEmployee;
        return TabContact;
      case MARKETPLACE_TABS.MEDIA:
        Object.assign(
          tabProps,
          pickAll(
            [
              'loadingValuesMap',
              'setError',
              'setImages',
              'setLoadingValue',
              'setVideos',
              'uploadWistiaVideo',
            ],
            props
          )
        );

        return TabMedia;
      default:
        return React.Fragment; // Raise an error that there's no tab selected?
    }
  })(tab);

  return (
    <Box padding="none xl xl xl">
      <Tabs>
        {Object.entries(MARKETPLACE_TABS).map(([key, value]) => (
          <Tabs.Tab
            key={key}
            data-qa={`tab-${value.toLowerCase().replace(' ', '-')}`}
            variant={tab === value ? 'active' : ''}
          >
            <Tabs.Link onClick={() => switchTab(value)}>
              {value}

              {validations[value] && errorsPresent(validations[value]) && (
                <span
                  style={{
                    color: 'red',
                    marginLeft: '5px',
                    display: 'inline-flex',
                  }}
                >
                  <Error icon="error-o" size="sm" />
                </span>
              )}
            </Tabs.Link>
          </Tabs.Tab>
        ))}
      </Tabs>
      <Form vertical>
        <Form.Row>
          <TabName {...tabProps} />
        </Form.Row>
      </Form>
    </Box>
  );
};

TabbedFormBody.propTypes = {
  isAdminView: PT.bool,
  canEdit: PT.bool,
  fields: PT.shape(MARKETPLACE_APP_FIELDS_PT.TYPES).isRequired,
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  onChange: PT.func.isRequired,
  publishedFields: PT.shape(MARKETPLACE_APP_FIELDS_PT.TYPES),
  tab: PT.string.isRequired,
  validations: PT.shape({}),
  productTools: PT.arrayOf(PT.shape(PRODUCT_TOOLS_PT.TYPES)),
  regions: PT.arrayOf(PT.shape(REGIONS_PT.TYPES)),
  switchTab: PT.func.isRequired,
  errorsPresent: PT.func.isRequired,
};

export default TabbedFormBody;
