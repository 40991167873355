import React from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import { compose } from 'recompose';
import { Typography } from '@procore/core-react';
import { withFocus } from '../decorators/Focus';
import { withRevealOnHover } from '../decorators/RevealOnHover';
import { Label } from './Form';

const StatelessField = ({
  className,
  focused,
  required,
  label,
  value,
  readOnly,
  type,
  onBlur,
  onChange,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  errors,
  ...rest
}) => (
  <div className={cx('a-field', className, { 'a-field--selected': focused })}>
    <Label required={required} label={label} />
    {errors && (
      <Typography color="red50" intent="small" italic>
        {errors.join(', ')}
      </Typography>
    )}
    <input
      className="a-input"
      readOnly={readOnly}
      type={type}
      value={value || ''}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...rest}
    />
  </div>
);

StatelessField.propTypes = {
  className: PT.string,
  label: PT.node,
  onChange: PT.func,
  onClick: PT.func,
  required: PT.bool,
  type: PT.string,
  value: PT.string,
};

StatelessField.defaultProps = {
  className: '',
  label: '',
  required: false,
  type: 'text',
  value: '',
};

export const Field = withFocus(StatelessField);
export const RevealOnHover = compose(
  withFocus,
  withRevealOnHover
)(StatelessField);
