import React from 'react';
import PropTypes from 'prop-types';
import MarkdownRenderer from '@/react/shared/MarkdownRenderer';
import Card from '../shared/card/Card';
import CardBlock from '../shared/card/CardBlock';
import notificationDateTimeUtil from './notificationDateTimeUtil';

const Notification = ({
  hasDivider,
  className,
  title,
  timestamp,
  markdown,
  footer,
}) => (
  <Card className={className}>
    <CardBlock hasDivider={hasDivider}>
      <div className="dev-notification-header">
        <h2>{title}</h2>
        <span className="dev-notification-timestamp">
          {notificationDateTimeUtil.getNotificationDisplayTime(timestamp)}
        </span>
      </div>
      <MarkdownRenderer className="dev-notification-body" source={markdown} />
      {footer}
    </CardBlock>
  </Card>
);

Notification.propTypes = {
  hasDivider: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  timestamp: PropTypes.string,
  markdown: PropTypes.string,
  footer: PropTypes.node,
};

Notification.defaultProps = {
  hasDivider: false,
  className: '',
  footer: null,
  markdown: '',
  timestamp: undefined,
};

export default Notification;
