import React, { useContext } from 'react';
import PT from 'prop-types';
import ReactJSONPretty from 'react-json-pretty';
import { BugsnagContext } from '../BugsnagProvider';
import { isValidJSON } from '../../../utils/jsonUtil';

const PrettyJSONViewer = ({ json, space, ...props }) => {
  const bugsnag = useContext(BugsnagContext);
  let jsonData = {};
  try {
    jsonData = json.json || json;
    isValidJSON(jsonData);
  } catch (err) {
    bugsnag.notify(
      new Error(
        `Error parsing JSON for ${window.location.pathname} err: ${err}`
      )
    );
  }

  return <ReactJSONPretty json={jsonData} space={space} {...props} />;
};

PrettyJSONViewer.propTypes = {
  json: PT.oneOfType([PT.arrayOf(PT.shape({})), PT.shape({}), PT.string]),
  space: PT.number,
};

PrettyJSONViewer.defaultProps = {
  json: {},
  space: 2,
};

export default PrettyJSONViewer;
