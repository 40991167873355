import React from 'react';
import { Button as CoreButton } from '@procore/core-react';
import cx from 'classnames';
import PT from 'prop-types';
import { Label } from './Form';

class Button extends React.Component {
  render() {
    const {
      label,
      className,
      buttonClassName,
      text,
      required,
      onClick,
      'data-qa': qaTag,
      ...rest
    } = this.props;

    return (
      <div className={cx('a-field', className)}>
        <Label required={required} label={label} />
        <div className="a-button-wrapper">
          <CoreButton
            data-qa={qaTag}
            onClick={onClick}
            className={cx('a-button', buttonClassName)}
            variant="secondary"
            size="lg"
            {...rest}
          >
            {text}
          </CoreButton>
        </div>
      </div>
    );
  }
}

Button.propTypes = {
  text: PT.string.isRequired,
  label: PT.string,
  className: PT.string,
  required: PT.bool,
  onClick: PT.func.isRequired,
};

Button.defaultProps = {
  className: '',
  label: '',
  required: false,
};

export default Button;
