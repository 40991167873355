import { colors, Tabs } from '@procore/core-react';
import styled from 'styled-components';

export const StyledTab = styled(Tabs.Tab)`
  border-left: 4px solid ${colors.gray90};
  margin-bottom: 0px !important;
  padding: 4px 0 4px 16px !important;

  &.selected {
    border-color: ${colors.blue50};
    color: ${colors.blue50};
  }
`;

export const DEFAULT_TABS = {
  CONFIGURATIONS: 'configurations',
  VERSIONS: 'versions',
  SETTINGS: 'settings',
  OAUTH_CREDENTIALS: 'oauthCredentials',
  COLLABORATORS: 'collaborators',
  MARKETPLACE_LISTING: 'marketplaceListing',
};

export const ALL_TABS = {
  ...DEFAULT_TABS,
  METRICS: 'metrics',
}

export const conditionalTabs = (devApp) => {
  if (devApp?.published_app_version_id) {
    return {
      METRICS: 'metrics',
    };
  }

  return {};
}
