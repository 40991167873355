import React from 'react';
import PT from 'prop-types';
import { Box, Popover } from '@procore/core-react';
import { Info } from '@procore/core-icons';

const InfoPopover = ({ children, placement, trigger }) => (
  <Popover
    placement={placement}
    trigger={trigger}
    overlay={
      <Popover.Content fluid>
        <Box padding="md">{children}</Box>
      </Popover.Content>
    }
  >
    {() => (
      <Info
        size="sm"
        style={{
          display: 'inline',
          cursor: trigger === 'click' ? 'pointer' : ''
        }}
        className="margin-l-xs"
      />
    )}
  </Popover>
);

InfoPopover.propTypes = {
  children: PT.node.isRequired,
  placement: PT.oneOf([
    'top',
    'top-left',
    'top-right',
    'bottom',
    'bottom-left',
    'bottom-right',
    'left',
    'left-top',
    'left-bottom',
    'right',
    'right-top',
    'right-bottom',
    'center',
  ]),
  trigger: PT.oneOf(['click', 'hover']),
};

InfoPopover.defaultProps = {
  placement: 'top',
  trigger: 'click',
};

export default InfoPopover;
