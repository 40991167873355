import React from 'react';
import { withStateHandlers } from 'recompose';

export const withRevealOnHover = withStateHandlers(
  (props) => ({ type: props.type || 'text' }),
  {
    onMouseEnter: () => () => ({ type: 'text' }),
    onMouseLeave: () => () => ({ type: 'password' }),
  }
);

export default withRevealOnHover(({ children, ...props }) => children(props));
