import React from 'react';
import cx from 'classnames';
import { FlexList, Typography } from '@procore/core-react';
import DropzoneComponent from '../dropzone';
import InfoPopover from '../infoPopover/InfoPopover';

export const Description = ({ className, children }) => (
  <div className={cx('a-form--description', className)}>{children}</div>
);

export const Content = ({ className, children, vertical }) => {
  const classes = cx('a-form--content', className, {
    vertical: !!vertical,
  });

  return <div className={classes}>{children}</div>;
};

export const Row = ({ className, children }) => (
  <div className={cx('a-form--row', className)}>{children}</div>
);

export const Column = ({ className, children }) => (
  <div className={cx('a-form--col', className)}>{children}</div>
);

export const Label = ({ className, required, label }) => (
  <label className={cx('a-label', className)}>
    <FlexList size="xs">
      {label}
      {required && <span className={cx('required')}>*</span>}
    </FlexList>
  </label>
);

export const PopoverLabel = ({ labelContent, popoverContent }) => (
  <span>
    {labelContent}
    <InfoPopover>{popoverContent}</InfoPopover>
  </span>
);

export const Dropzone = ({
  required,
  requirements,
  label,
  children,
  errors,
  ...rest
}) => (
  <div className="a-field">
    <Label label={label} required={required} />
    {errors && (
      <Typography color="red50" intent="small" italic>
        {errors.join(', ')}
      </Typography>
    )}

    {requirements && (
      <ul>
        {requirements.map((requirement, idx) => (
          <li key={idx}>{requirement}</li>
        ))}
      </ul>
    )}
    <DropzoneComponent {...rest}>{children}</DropzoneComponent>
  </div>
);
