import PT from 'prop-types';

export const PRODUCT_TOOLS_PT = {
  TYPES: {
    id: PT.string,
    title: PT.string,
  },
  DEFAULTS: {
    id: '',
    title: '',
  },
};

export const COUNTRIES_PT = {
  TYPES: {
    id: PT.string,
    name: PT.string,
  },
  DEFAULTS: {
    id: '',
    name: '',
  },
};

export const REGIONS_PT = {
  TYPES: {
    id: PT.string,
    name: PT.string,
    countries: PT.arrayOf(PT.shape(COUNTRIES_PT.TYPES)),
  },
  DEFAULTS: {
    id: '',
    name: '',
  },
};

export const MARKETPLACE_APP_PT = {
  TYPES: {
    id: PT.string,
    about: PT.string,
    admin_message: PT.string,
    approval_state: PT.string,
    beta: PT.bool,
    built_by: PT.string,
    category_ids: PT.arrayOf(PT.string),
    costs_money: PT.bool,
    created_at: PT.string,
    description: PT.string,
    developer_app_id: PT.string,
    feature_bullets: PT.arrayOf(PT.string),
    helpful_links: PT.arrayOf(
      PT.shape({
        url: PT.string,
        label: PT.string,
      })
    ),
    how_it_works: PT.string,
    is_concierge_app: PT.bool,
    live: PT.bool,
    made_by_procore: PT.bool,
    pictures: PT.arrayOf(
      PT.shape({
        id: PT.string,
        description: PT.string,
        name: PT.string,
        original_filename: PT.string,
        type: PT.string,
        url: PT.string,
      })
    ),
    procore_certified_partner: PT.bool,
    product_tools: PT.arrayOf(PT.shape(PRODUCT_TOOLS_PT.TYPES)),
    regions: PT.arrayOf(PT.shape(REGIONS_PT.TYPES)),
    countries: PT.arrayOf(PT.shape(COUNTRIES_PT.TYPES)),
    public_name: PT.string,
    requirements: PT.arrayOf(PT.string),
    small_logo_url: PT.string,
    state: PT.string,
    support_email: PT.string,
    tagline: PT.string,
    updated_at: PT.string,
    version: PT.number,
    videos: PT.arrayOf(
      PT.shape({
        id: PT.string,
        description: PT.string,
        name: PT.string,
        original_filename: PT.string,
        type: PT.string,
        url: PT.string,
      })
    ),
    website_link: PT.shape({}),
  },
  DEFAULTS: {
    id: '',
    about: '',
    admin_message: '',
    approval_state: 'unreviewed',
    beta: false,
    built_by: '',
    category_ids: [],
    costs_money: false,
    created_at: '',
    description: '',
    developer_app_id: '',
    feature_bullets: [],
    helpful_links: [],
    how_it_works: '',
    is_concierge_app: false,
    live: false,
    made_by_procore: false,
    pictures: [],
    procore_certified_partner: false,
    product_tools: [],
    regions: [],
    countries: [],
    public_name: '',
    requirements: [],
    small_logo_url: '',
    state: 'draft',
    support_email: '',
    tagline: '',
    updated_at: '',
    version: 1,
    videos: [],
    website_link: {},
  },
};

export const SANDBOX_PT = {
  TYPES: {
    app_id: PT.string,
    app_secret: PT.string,
    confidential: PT.bool,
    id: PT.string,
    redirect_url: PT.string,
    url: PT.string,
  },
  DEFAULTS: {
    app_id: '',
    app_secret: '',
    confidential: false,
    id: '',
    redirect_url: 'http://localhost/',
    url: '',
  },
};

export const SETTINGS_PT = {
  TYPES: {
    internal_name: PT.string,
    thumbnail: {
      preview: PT.string,
    },
  },
  DEFAULTS: {
    internal_name: '',
    thumbnail: {
      preview: '',
    },
  },
};

export const MANIFEST_PT = {
  TYPES: {
    id: PT.number.isRequired,
    app_version_id: PT.string.isRequired,
    content: PT.shape({}).isRequired,
    created_at: PT.string,
    developer_app_name: PT.string.isRequired,
    next_version_content: PT.shape({}).isRequired,
    promotable: PT.bool.isRequired,
    published_to_marketplace: PT.bool.isRequired,
    state: PT.string.isRequired,
    submittable: PT.bool.isRequired,
    updated_at: PT.string,
    version: PT.number.isRequired,
  },
  DEFAULTS: {
    created_at: '',
    updated_at: '',
  },
};

export const METRICS_DATA_PT = {
  TYPES: {
    developer_app_id: PT.string,
    app_installations: PT.shape({}),
    daily_app_installations_aggregates: PT.shape({}),
    daily_marketplace_app_views_aggregates: PT.shape({}),
  },
  DEFAULTS: {
    developer_app_id: '',
    app_installations: {},
    daily_app_installations_aggregates: {},
    daily_marketplace_app_views_aggregates: {},
  },
};

export const DEVELOPER_APP_PT = {
  TYPES: {
    production_access: PT.string,
    published_at: PT.string,
    marketplaceApp: PT.shape(MARKETPLACE_APP_PT.TYPES),
    settings: PT.shape(SETTINGS_PT.TYPES).isRequired,
    credentials: PT.shape({}).isRequired,
    sandbox: PT.shape({}).isRequired,
    productionManifests: PT.arrayOf(MANIFEST_PT.DEFAULTS),
    sandboxManifests: PT.arrayOf(MANIFEST_PT.DEFAULTS),
  },
  DEFAULTS: {
    marketplaceApp: {},
    production_access: '',
    published_at: '',
    productionManifests: [],
    sandboxManifests: [],
  },
};

export const APP_FIELD_PERMISSIONS_PT = {
  TYPES: {
    internal_name: PT.shape({
      editable: PT.bool,
    }),
    redirect_uri: PT.shape({
      editable: PT.bool,
    }),
    remove_thumbnail: PT.shape({
      editable: PT.bool,
    }),
    thumbnail: PT.shape({
      editable: PT.bool,
    }),
    thumbnail_cache: PT.shape({
      editable: PT.bool,
    }),
  },
  DEFAULTS: {
    internal_name: {
      editable: false,
    },
    redirect_uri: {
      editable: false,
    },
    remove_thumbnail: {
      editable: false,
    },
    thumbnail: {
      editable: false,
    },
    thumbnail_cache: {
      editable: false,
    },
  },
};

export const MARKETPLACE_APP_FIELDS_PT = {
  TYPES: {
    __modified__: PT.bool,
    id: PT.string,
    about: PT.string,
    admin_message: PT.string,
    approval_state: PT.string,
    beta: PT.bool,
    built_by: PT.string,
    category_ids: PT.arrayOf(PT.string),
    costs_money: PT.bool,
    created_at: PT.string,
    description: PT.string,
    developer_app_id: PT.string,
    feature_bullets: PT.arrayOf(PT.string),
    helpful_links: PT.arrayOf(
      PT.shape({
        url: PT.string,
        label: PT.string,
      })
    ),
    how_it_works: PT.string,
    is_concierge_app: PT.bool,
    live: PT.bool,
    made_by_procore: PT.bool,
    pictures: PT.arrayOf(PT.shape({})),
    procore_certified_partner: PT.bool,
    product_tools: PT.arrayOf(PT.shape(PRODUCT_TOOLS_PT.TYPES)),
    regions: PT.arrayOf(PT.shape(REGIONS_PT.TYPES)),
    countries: PT.arrayOf(PT.shape(COUNTRIES_PT.TYPES)),
    public_name: PT.string,
    requirements: PT.arrayOf(PT.string),
    small_logo_url: PT.string,
    state: PT.string,
    support_email: PT.string,
    tagline: PT.string,
    updated_at: PT.string,
    version: PT.number,
    videos: PT.arrayOf(PT.shape({})),
    website_link: PT.shape({
      url: PT.string,
      label: PT.string,
    }),
  },
  DEFAULTS: {
    __modified__: false,
    id: '',
    about: '',
    admin_message: '',
    approval_state: '',
    beta: false,
    built_by: '',
    category_ids: [],
    costs_money: false,
    created_at: '',
    description: '',
    developer_app_id: '',
    feature_bullets: [],
    helpful_links: [],
    how_it_works: '',
    is_concierge_app: false,
    live: false,
    made_by_procore: false,
    pictures: [],
    procore_certified_partner: false,
    product_tools: [],
    regions: [],
    countries: [],
    public_name: [],
    requirements: [],
    small_logo_url: '',
    state: '',
    support_email: '',
    tagline: '',
    updated_at: '',
    version: 1,
    videos: [],
    website_link: {
      url: '',
      label: '',
    },
  },
};
