import React from 'react';
import PT from 'prop-types';
import { equals } from 'ramda';
import Form from '@/react/shared/form';
import ChangeIndicator from '../ChangeIndicator';
import { MARKETPLACE_TABS } from '../StateContainer';

const generateWistiaLink = (hashedVideoId) =>
  `https://fast.wistia.net/embed/iframe/${hashedVideoId}`;

const mediaArraysEqual = (arrOne = [], arrTwo = []) => {
  const sortedUrls = (arr) => arr.map((el) => el.url).sort();
  return equals(sortedUrls(arrOne), sortedUrls(arrTwo));
};

class TabMedia extends React.Component {
  render() {
    const {
      I18n,
      isDisabled,
      onFieldChange,
      fields,
      validations,
      publishedFields,
      uploadWistiaVideo,
      setVideos,
      setImages,
      setError,
      loadingValuesMap,
      setLoadingValue,
      isAdminView,
    } = this.props;

    const onChange = (...fieldPath) => (value) =>
      onFieldChange([MARKETPLACE_TABS.MEDIA, ...fieldPath], value);

    return (
      <Form.Content>
        <Form.Column>
          <Form.Dropzone
            requirements={[
              I18n.t('labels.marketplaceApp.info.logo.size'),
              I18n.t('labels.marketplaceApp.info.logo.distinct'),
              I18n.t('labels.marketplaceApp.info.logo.copyright'),
            ]}
            loading={loadingValuesMap.logo}
            label={
              <ChangeIndicator
                changed={
                  isAdminView &&
                  fields?.small_logo_url !== publishedFields?.small_logo_url
                }
              >
                {I18n.t('labels.marketplaceApp.logo')}
              </ChangeIndicator>
            }
            multiple={false}
            required
            errors={validations.small_logo_url}
            disabled={isDisabled}
            data-qa="marketplace-app-logo"
            onDrop={(accepted) => {
              onChange('remove_small_logo')(false);
              onChange('small_logo')(accepted[0]);
              onChange('small_logo_url')(accepted[0].preview);
            }}
            acceptedTypes={['image/jpeg', 'image/png']}
            files={
              fields.small_logo?.preview
                ? [
                    {
                      url: fields.small_logo.preview,
                      type: 'Logo',
                      name: 'Logo',
                    },
                  ]
                : []
            }
            removeFile={() => {
              onChange('small_logo')({});
              onChange('small_logo_url')('');
              onChange('remove_small_logo')(true);
            }}
          />

          <Form.Dropzone
            requirements={[
              I18n.t('labels.marketplaceApp.info.screenshots.quantity'),
              I18n.t('labels.marketplaceApp.info.screenshots.content'),
            ]}
            loading={loadingValuesMap.screenshot}
            label={
              <ChangeIndicator
                changed={
                  isAdminView &&
                  !mediaArraysEqual(fields.pictures, publishedFields.pictures)
                }
              >
                {I18n.t('labels.marketplaceApp.screenshots')}
              </ChangeIndicator>
            }
            multiple
            required
            errors={validations.pictures}
            disabled={isDisabled}
            data-qa="marketplace-app-screenshots"
            onDrop={(accepted) => {
              const pictures = accepted.map((file) => {
                return {
                  file,
                  type: 'Picture',
                  description: file.name,
                  name: file.name,
                };
              });
              onChange('pictures')([...(fields.pictures || []), ...pictures]);
            }}
            acceptedTypes={['image/jpeg', 'image/png']}
            files={fields.pictures?.map((picture) => {
              if (picture.description) {
                picture.name = picture.description;
              }
              return picture;
            })}
            removeFile={(file) => {
              const newImages = fields.pictures?.filter((image) => {
                return image !== file;
              });
              if (file.id) {
                onChange('remove_media_ids')([
                  ...(fields.remove_media_ids || []),
                  file.id,
                ]);
              }
              setImages(newImages);
            }}
          />

          <Form.Dropzone
            requirements={[
              I18n.t('labels.marketplaceApp.info.videos.format'),
              I18n.t('labels.marketplaceApp.info.videos.instructions'),
            ]}
            loading={loadingValuesMap.video}
            label={
              <ChangeIndicator
                changed={
                  isAdminView &&
                  !mediaArraysEqual(fields.videos, publishedFields.videos)
                }
              >
                {I18n.t('labels.marketplaceApp.videos')}
              </ChangeIndicator>
            }
            loadingLabel={I18n.t('longUploadInProgress')}
            multiple
            disabled={isDisabled}
            data-qa="marketplace-app-videos"
            onDrop={(accepted) => {
              setLoadingValue('video', true);
              const requests = accepted.map((file) => {
                return uploadWistiaVideo(file);
              });
              Promise.all(requests)
                .then((resps) => {
                  const newVideos = resps.map((resp) => {
                    const { data } = resp;
                    return {
                      type: 'Video',
                      external_url: generateWistiaLink(data.hashed_id),
                      original_filename: data.name,
                      description: data.name,
                      name: data.name,
                    };
                  });
                  onChange('videos')([...(fields.videos || []), ...newVideos]);
                  setLoadingValue('video', false);
                })
                .catch((error) => {
                  setError(error);
                  setLoadingValue('video', false);
                });
            }}
            acceptedTypes={['video/mp4', 'video/quicktime']}
            files={fields.videos}
            removeFile={(file) => {
              const newVideos = fields.videos.filter((video) => {
                return video !== file;
              });
              // Remove video wistia link from marketplace app
              if (file.id) {
                onChange('remove_media_ids')([
                  ...(fields.remove_media_ids || []),
                  file.id,
                ]);
              }
              setVideos(newVideos);
            }}
          />
        </Form.Column>
      </Form.Content>
    );
  }
}

TabMedia.propTypes = {
  I18n: PT.shape({
    t: PT.func.isRequired,
  }).isRequired,
  isAdminView: PT.bool.isRequired,
  fields: PT.shape({
    id: PT.string,
    pictures: PT.arrayOf(PT.shape({})),
    videos: PT.arrayOf(PT.shape({})),
    remove_media_ids: PT.arrayOf(PT.string),
    small_logo: PT.shape({
      preview: PT.string,
    }),
    small_logo_url: PT.string,
  }).isRequired,
  publishedFields: PT.shape({
    pictures: PT.shape([]),
    videos: PT.shape([]),
    small_logo_url: PT.string,
  }).isRequired,
  isDisabled: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  setError: PT.func.isRequired,
  setImages: PT.func.isRequired,
  setVideos: PT.func.isRequired,
  validations: PT.shape({
    small_logo_url: PT.arrayOf(PT.string),
  }),
  uploadWistiaVideo: PT.func.isRequired,
  loadingValuesMap: PT.shape({
    global: PT.bool,
    logo: PT.bool,
    screenshot: PT.bool,
    video: PT.bool,
  }),
  setLoadingValue: PT.func,
};

TabMedia.defaultProps = {
  loadingValuesMap: {
    global: false,
    logo: false,
    screenshot: false,
    video: false,
  },
  validations: {},
  setLoadingValue: () => {},
};

export default TabMedia;
