import axios from 'axios';
import { getHeaders } from '@/utils/apiUtil';

async function fetch(url, branchName) {
  let respData = null;
  try {
    respData = await axios.get(url, {
      headers: getHeaders(),
    });
  } catch (err) {
    console.warn(`Error fetching data for branch: ${branchName}`);
  }

  if (!respData) {
    console.warn(`Error fetching data for branch: ${branchName}`);
  }
  return respData || [];
}

export function getResourceData({ devApiUrl, branchName, link }) {
  const branchParam = branchName ? `&custom_service_sha=${branchName}` : '';
  return fetch(`${devApiUrl}${link}${branchParam}`, branchName);
}

export function getIndexData({
  devApiUrl,
  branchName,
  version,
  group,
  types,
  breaking,
  search,
  page,
  perPage,
}) {
  const groupParam = group ? `/${group}` : '';
  const branchParam = branchName ? `&custom_service_sha=${branchName}` : '';
  const versionParam = version ? `version=${version}` : '';
  const pageParam = page ? `&page=${page}` : '';
  const perPageParam = perPage ? `&per_page=${perPage}` : '';
  const filtersType = types ? `&filters[type]=[${types}]` : '';
  const filtersBreaking = breaking ? '&filters[breaking]=true' : '';
  const searchParam = search ? `&filters[search]=${search}` : '';
  return fetch(
    `${devApiUrl}/api/v1/resource_groups${groupParam}?${versionParam}${branchParam}${filtersType}${filtersBreaking}${searchParam}${pageParam}${perPageParam}`,
    branchName
  );
}

