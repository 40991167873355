import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import "ace-builds/src-noconflict/theme-textmate";

import {
  Box,
  Button,
  Flex,
  FlexList,
  Modal,
  Spinner,
  useI18nContext,
  P,
  Typography,
} from '@procore/core-react';
import classNames from 'classnames/bind';
import styled from 'styled-components';
import Form from '@/react/shared/form';
import PrettyJSONViewer from '../../shared/PrettyJSONViewer';
import ValidationErrorsDisplayer from './ValidationErrorsDisplayer';

import styles from './styles.module.scss';
import InfoPopover from '../../shared/infoPopover/InfoPopover';

import PermissionsBuilder from '../Permissions';

import Snippets from './Snippets';

const cx = classNames.bind(styles);

const FullscreenModal = styled(Modal)`
  width: 100vw;
  height: 100vh;
  > div {
    max-height: none;
  }
`;

const FullscreenModalBody = styled(Modal.Body)`
  flex: 1;
  margin: 0;
  max-width: none;
  width: 100vw;
`;

// eslint-disable-next-line complexity
const ManifestModal = ({
  manifest,
  semanticVersion,
  onClose,
  viewOnly,
  onChange,
  onChangeSemanticVersion,
  onCreate,
  validations,
  parentApp,
  templatesLoading,
  onManifestValidationSuccess,
  templateSnippets,
  hasExistingManifest,
  latestManifestVersion,
  canViewClientCredentials,
  canSetSemanticVersions,
  dmsaPermissions,
}) => {
  const I18n = useI18nContext();
  const createMode = !viewOnly && onCreate;
  const {
    handlers: { validateSchema, validateJSON },
    state: { apiError, jsonErrors, schemaErrors, validating, passedValidation },
  } = validations;

  const hasErrors =
    apiError || jsonErrors.length > 0 || schemaErrors.length > 0;
  const createDisabled = hasErrors || validating || !passedValidation;

  const [schemaVersion, setSchemaVersion] = useState('3');

  const [json, setJson] = useState(manifest || manifest.json);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(json);
  };

  useEffect(() => {
    setSchemaVersion(JSON.parse(manifest)?.schema_version || '3');
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    try {
      setSchemaVersion(JSON.parse(manifest)?.schema_version || '3');
    } catch {}
  }, [manifest]);

  useEffect(() => {
    if (passedValidation) {
      onManifestValidationSuccess(manifest);
    }
  }, [passedValidation]);

  /**
   * client_credential permissions functionality
   */
  const getClientCredentialsComponent = (manifestObject) => {
    try {
      manifestObject = manifestObject || JSON.parse(manifest);
      if (schemaVersion === '4.1') {
        return {
          permissions: manifestObject.permissions || {},
        };
      }
      return manifestObject?.components?.oauth?.instances?.find(
        (instance) => instance?.grant_type === 'client_credentials'
      );
    } catch {
      return false;
    }
  };

  const injectPermissions = (permissions) => {
    const manifestObject = JSON.parse(manifest);

    if (schemaVersion === '4.1') {
      manifestObject.permissions = permissions;
    } else {
      const clientCredentialsComponent =
        getClientCredentialsComponent(manifestObject);
      clientCredentialsComponent.permissions = permissions;
    }

    onChange(JSON.stringify(manifestObject));
    validateSchema(
      parentApp.id,
      parentApp.type,
      JSON.stringify(manifestObject),
      true
    );
  };

  const injectComponent = (componentSnippet, componentType) => {
    const manifestObject = JSON.parse(manifest);
    manifestObject[componentType] = componentSnippet;

    onChange(JSON.stringify(manifestObject));
    validateSchema(
      parentApp.id,
      parentApp.type,
      JSON.stringify(manifestObject),
      true
    );
  };

  return (
    <FullscreenModal
      className={cx(styles.manifestEditorModal)}
      open
      onClickOverlay={() => {
        onClose({ resetContent: true });
      }}
    >
      <Modal.Header
        onClose={() => {
          onClose({ resetContent: true });
        }}
      >
        {createMode ? I18n.t('createNewVersion') : I18n.t('manifestContent')}
        {viewOnly && (
          <Button
            variant="primary"
            size="sm"
            data-qa="manifest-modal-copy-button"
            style={{ marginLeft: 'auto' }}
            onClick={copyToClipboard}
          >
            Copy
          </Button>
        )}
      </Modal.Header>
      <FullscreenModalBody>
        {!viewOnly && (
          <Flex marginBottom="md">
            <FlexList direction="row" size="xs">
              {canSetSemanticVersions && (
                <>
                  <Form.Field
                    data-qa="semantic-version"
                    label={
                      <>
                        <Box>
                          <strong>
                            {I18n.t('enterVersionNumber')}*
                            <InfoPopover placement="right">
                              {I18n.t('semanticVersionInfo.intro')}
                              <ul>
                                <li>{I18n.t('semanticVersionInfo.major')}</li>
                                <li>{I18n.t('semanticVersionInfo.minor')}</li>
                                <li>{I18n.t('semanticVersionInfo.patch')}</li>
                              </ul>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: I18n.t(
                                    'semanticVersionInfo.more_info'
                                  ),
                                }}
                              />
                            </InfoPopover>
                          </strong>
                        </Box>
                        <Box>{I18n.t('useSemanticVersion')}</Box>
                      </>
                    }
                    type="text"
                    value={semanticVersion}
                    errors={[]}
                    onChange={(e) => {
                      onChangeSemanticVersion(e.currentTarget.value);
                    }}
                    placeholder={I18n.t('semanticVersionPlaceholder')}
                  />
                  <>
                    {I18n.t('lastSemanticVersion')}: {latestManifestVersion}
                  </>
                </>
              )}
              {templateSnippets && (
                <Snippets
                  I18n={I18n}
                  templateSnippets={templateSnippets}
                  injectComponent={injectComponent}
                  hasExistingManifest={hasExistingManifest}
                  schemaVersion={schemaVersion}
                />
              )}
            </FlexList>
            <FlexList direction="column" size="xs" marginLeft="md">
              {canViewClientCredentials && (
                <>
                  <Box marginBottom="xs">
                    <strong>{I18n.t('appToolPermissions')}</strong>
                  </Box>
                  <Box display="flex-column" marginTop="xs">
                    <Box marginBottom="xs">
                      {I18n.t('permissionsBuilder')}
                      <InfoPopover>
                        <Box>
                          <P
                            dangerouslySetInnerHTML={{
                              __html: I18n.t('permissionsBuilderTooltip'),
                            }}
                          />
                        </Box>
                      </InfoPopover>
                    </Box>
                  </Box>
                  <PermissionsBuilder
                    disabled={
                      validating ||
                      hasErrors ||
                      !getClientCredentialsComponent()
                    }
                    hasErrors={hasErrors}
                    injectPermissions={injectPermissions}
                    clientCredentialsComponent={getClientCredentialsComponent()}
                    validating={validating}
                    dmsaPermissions={dmsaPermissions}
                  />
                </>
              )}
            </FlexList>
          </Flex>
        )}
        <ValidationErrorsDisplayer
          I18n={I18n}
          apiError={apiError}
          errors={schemaErrors}
        />
        {viewOnly ? (
          <PrettyJSONViewer data-qa="manifest-json-viewer" json={manifest} />
        ) : (
          <Box data-qa="manifest-json-editor" flex={1}>
            <Spinner
              loading={templatesLoading && !manifest}
              style={{ height: '100%', paddingBottom: '40px' }}
            >
              <Box marginBottom="sm">
                <strong>{I18n.t('appManifest')}</strong>
              </Box>
              <AceEditor
                theme="textmate"
                style={{
                  width: '100%',
                  height: '100%',
                  border: '1px solid #DFDFE0',
                }}
                editorProps={{ $blockScrolling: Infinity }}
                value={manifest}
                onChange={(m) => {
                  onChange(m);
                  validateSchema(parentApp.id, parentApp.type, m, true);
                }}
                mode="json"
                onValidate={validateJSON}
                tabSize={2}
                setOptions={{
                  showPrintMargin: false,
                  useWorker: false,
                }}
              />
            </Spinner>
          </Box>
        )}
        {createMode && (
          <>
            <i>{I18n.t('createInfo')}</i>
            <br />
          </>
        )}
        <i>{I18n.t('appManifestSectionInfo')}</i>
      </FullscreenModalBody>
      <Modal.Footer>
        {validating && (
          <Flex alignItems="center">
            <Spinner size="md" loading />
            <Box paddingLeft="sm">
              <Typography intent="small" color="gray45">
                {I18n.t('validating')}
              </Typography>
            </Box>
          </Flex>
        )}
        <Modal.FooterButtons>
          <Button
            variant="tertiary"
            onClick={() => {
              onClose({ resetContent: true });
            }}
            data-qa="manifest-modal-cancel-button"
          >
            {I18n.t('cancel')}
          </Button>
          {createMode && (
            <Button
              data-qa="manifest-modal-create-button"
              disabled={createDisabled}
              onClick={() => {
                onCreate(manifest);
                onClose({ resetContent: false });
              }}
            >
              {I18n.t('create')}
            </Button>
          )}
        </Modal.FooterButtons>
      </Modal.Footer>
    </FullscreenModal>
  );
};

ManifestModal.propTypes = {
  manifest: PT.string.isRequired,
  onClose: PT.func.isRequired,
  templatesLoading: PT.bool.isRequired,
  viewOnly: PT.bool,
  onChange: PT.func,
  onCreate: PT.func,
  parentApp: PT.shape({
    id: PT.string.isRequired,
    type: PT.string.isRequired,
  }).isRequired,
  onManifestValidationSuccess: PT.func,
  validations: PT.shape({
    handlers: PT.shape({
      validateSchema: PT.func.isRequired,
      validateJSON: PT.func.isRequired,
    }),
    state: PT.shape({
      apiError: PT.instanceOf(Error),
      jsonErrors: PT.arrayOf(
        PT.shape({
          column: PT.number.isRequired,
          row: PT.number.isRequired,
          text: PT.string.isRequired,
          type: PT.string.isRequired,
        })
      ),
      schemaErrors: PT.arrayOf(PT.string),
      validating: PT.bool,
      passedValidation: PT.bool,
    }).isRequired,
  }).isRequired,
  templateSnippets: PT.shape({
    snippets: PT.shape({
      iframe: PT.string,
      oauth: PT.string,
    }),
  }).isRequired,
  hasExistingManifest: PT.bool,
  canViewClientCredentials: PT.bool.isRequired,
  semanticVersion: PT.string,
  onChangeSemanticVersion: PT.func.isRequired,
  latestManifestVersion: PT.string,
  canSetSemanticVersions: PT.bool.isRequired,
  dmsaPermissions: PT.object.isRequired,
};

ManifestModal.defaultProps = {
  manifest: '', // eslint-disable-line react/default-props-match-prop-types
  viewOnly: false,
  onCreate: null,
  onChange: null,
  onManifestValidationSuccess: () => {},
  hasExistingManifest: false,
};

export default ManifestModal;
