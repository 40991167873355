import React from 'react';
import { I18nProvider, useI18nContext } from '@procore/core-react';
import { compose } from 'recompose';
import withBugsnag from '@/react/shared/BugsnagProvider';
import Show from './Show';
import i18n from './i18n';
import StateContainer from './StateContainer';
import { ToastAlertProvider, useToastAlertContext } from '@procore/toast-alert';

const WrappedComponent = (props) => {
  const I18n = useI18nContext();
  const { showToast } = useToastAlertContext();
  return <Show I18n={I18n} enqueueToast={showToast} {...props} />;
};

const ShowWithI18n = (props) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <ToastAlertProvider>
      <WrappedComponent {...props} />
    </ToastAlertProvider>
  </I18nProvider>
);

export default compose(
  withBugsnag,
  StateContainer
)(ShowWithI18n);
