import React from 'react';
import { withStateHandlers } from 'recompose';
import { assocPath, omit } from 'ramda';
import { validator, validations, errorsPresent } from '../../shared/validator';
import I18n from './i18n';

const { arrayPresent, present, supported, maxStringLength } = validations;

export const MARKETPLACE_TABS = {
  APP: 'App',
  REGIONS: 'Regions',
  SUPPORT: 'Support',
  FEATURES: 'Features',
  LINKS: 'Links',
  TOOLS: 'Required Procore Tools',
  REQUIREMENTS: 'Integration Requirements',
  MEDIA: 'Media',
  CONTACT: 'Contact',
};

// Validations list
const VALIDATIONS = [
  {
    path: [MARKETPLACE_TABS.APP, 'category_ids'],
    name: I18n.t('en.labels.marketplaceApp.category'),
    validators: [arrayPresent],
  },
  {
    path: [MARKETPLACE_TABS.APP, 'description'],
    name: I18n.t('en.labels.marketplaceApp.description'),
    validators: [present, maxStringLength(2000)],
  },
  {
    path: [MARKETPLACE_TABS.APP, 'tagline'],
    name: I18n.t('en.labels.marketplaceApp.tagline'),
    validators: [present, maxStringLength(40)],
  },
  {
    path: [MARKETPLACE_TABS.APP, 'built_by'],
    name: I18n.t('en.labels.marketplaceApp.builtBy'),
    validators: [present],
  },
  {
    path: [MARKETPLACE_TABS.REGIONS, 'region_or_country'],
    name: I18n.t('en.labels.marketplaceApp.regionOrCountry'),
    validators: [supported],
  },
  {
    path: [MARKETPLACE_TABS.SUPPORT, 'support_email'],
    name: I18n.t('en.labels.marketplaceApp.supportEmail'),
    validators: [present],
  },
  {
    path: [MARKETPLACE_TABS.SUPPORT, 'website_link', 'label'],
    name: I18n.t('en.labels.marketplaceApp.supportWebsiteLabel'),
    validators: [present],
  },
  {
    path: [MARKETPLACE_TABS.SUPPORT, 'website_link', 'url'],
    name: I18n.t('en.labels.marketplaceApp.supportWebsiteUrl'),
    validators: [present],
  },
  {
    path: [MARKETPLACE_TABS.APP, 'about'],
    name: I18n.t('en.labels.marketplaceApp.about'),
    validators: [present],
  },
  {
    path: [MARKETPLACE_TABS.APP, 'how_it_works'],
    name: I18n.t('en.labels.marketplaceApp.howItWorks'),
    validators: [present],
  },
  {
    path: [MARKETPLACE_TABS.FEATURES, 'feature_bullets', '0'],
    name: `${I18n.t('en.labels.marketplaceApp.featureBullet')} 1`,
    validators: [present, maxStringLength(500)],
  },
  {
    path: [MARKETPLACE_TABS.FEATURES, 'feature_bullets', '1'],
    name: `${I18n.t('en.labels.marketplaceApp.featureBullet')} 2`,
    validators: [present, maxStringLength(500)],
  },
  {
    path: [MARKETPLACE_TABS.FEATURES, 'feature_bullets', '2'],
    name: `${I18n.t('en.labels.marketplaceApp.featureBullet')} 3`,
    validators: [present, maxStringLength(500)],
  },
  {
    path: [MARKETPLACE_TABS.MEDIA, 'small_logo_url'],
    name: I18n.t('en.labels.marketplaceApp.logo'),
    validators: [present],
  },
  {
    path: [MARKETPLACE_TABS.MEDIA, 'pictures'],
    name: I18n.t('en.labels.marketplaceApp.screenshots'),
    validators: [arrayPresent],
  },
];

const marketplaceValidator = validator(VALIDATIONS);

const mapFields = (unmappedFields) => {
  const fieldsClone = { ...unmappedFields };
  const nestedFields = [];

  // these functions delete fields from the clone
  // as they are plucked, so that the remaining
  // fields can be added on at the top level after
  // the nested ones are added to specific tabs
  const marketplaceFieldOr = (field, fallback) => {
    const value = fieldsClone[field];

    if (value) {
      nestedFields.push(field);
      return value;
    }
    return fallback;
  };

  const marketplaceFields = (...keys) =>
    keys.reduce((acc, key) => {
      if (key === 'small_logo_url') {
        acc.small_logo = {
          preview: fieldsClone.small_logo_url || '',
        };
      }
      acc[key] = fieldsClone[key];
      nestedFields.push(key);
      return acc;
    }, {});

  const regions = marketplaceFieldOr('regions', []).reduce(
    (acc, product) => {
      acc[product.id] = true;
      return acc;
    },
    {}
  );

  const countries = marketplaceFieldOr('countries', []).reduce(
    (acc, product) => {
      acc[product.id] = true;
      return acc;
    },
    {}
  );

  const productTools = marketplaceFieldOr('product_tools', []).reduce(
    (acc, product) => {
      acc[product.id] = true;
      return acc;
    },
    {}
  );

  return {
    id: fieldsClone.id,
    [MARKETPLACE_TABS.APP]: marketplaceFields(
      'public_name',
      'description',
      'tagline',
      'category_ids',
      'built_by',
      'about',
      'how_it_works',
      'costs_money',
      'beta',
      'security_badge',
      'security_questionnaire_document',
      'sold_by'
    ),
    [MARKETPLACE_TABS.FEATURES]: {
      feature_bullets: marketplaceFieldOr('feature_bullets', ['', '', '']),
    },
    [MARKETPLACE_TABS.LINKS]: {
      helpful_links: marketplaceFieldOr('helpful_links', [
        { label: '', url: '' },
        { label: '', url: '' },
        { label: '', url: '' },
      ]),
    },
    [MARKETPLACE_TABS.MEDIA]: marketplaceFields(
      'id',
      'small_logo_url',
      'pictures',
      'videos'
    ),
    [MARKETPLACE_TABS.CONTACT]: marketplaceFields(
      'learn_how_url',
      'contact_us_email',
      'create_lead',
    ),
    [MARKETPLACE_TABS.REGIONS]: { regions: regions, countries: countries },
    [MARKETPLACE_TABS.TOOLS]: { product_tools: productTools },
    [MARKETPLACE_TABS.REQUIREMENTS]: {
      requirements: marketplaceFieldOr('requirements', ['', '', '']),
    },
    [MARKETPLACE_TABS.SUPPORT]: {
      ...{ website_link: marketplaceFieldOr('website_link', {}) },
      ...marketplaceFields('support_email'),
    },
    ...omit(nestedFields, fieldsClone), // Add any remaining keys at the top level
  };
};

export default (WrappedComponent) =>
  withStateHandlers(
    {
      fields: {},
      publishedFields: {},
      tab: MARKETPLACE_TABS.APP,
      loadingValuesMap: {
        global: false,
        video: false,
      },
      validations: {},
      valid: true,
      errors: [],
      errorsPresent,
    },
    {
      switchTab: () => (tab) => ({ tab }),
      setValid: () => (valid) => ({ valid }),
      setError: () => (error) => {
        if (!error) {
          return { errors: [] };
        }

        if (!error.request) {
          return { errors: [error.message] };
        }

        try {
          const resp = JSON.parse(error.request.response);
          if (resp.errors) {
            return { errors: resp.errors };
          }
        } catch (err) {
          return { errors: [err.message] };
        }

        return { errors: [] };
      },
      mapMarketplaceAppFields: () => (
        unmappedFields,
        unmappedPublishedFields
      ) => {
        return {
          fields: mapFields(unmappedFields),
          publishedFields: mapFields(unmappedPublishedFields),
        };
      },
      setVideos: ({ fields }) => (videos) => {
        return {
          fields: assocPath([MARKETPLACE_TABS.MEDIA, 'videos'], videos, fields),
        };
      },
      setImages: ({ fields }) => (images) => {
        return {
          fields: assocPath(
            [MARKETPLACE_TABS.MEDIA, 'pictures'],
            images,
            fields
          ),
        };
      },
      setLoadingValue: ({ loadingValuesMap }) => (key, value) => ({
        loadingValuesMap: {
          ...loadingValuesMap,
          [key]: !!value,
        },
      }),
      validateFields: ({ fields }) => (success, error) => {
        const fieldsClone = {...fields};
        fieldsClone.Regions.region_or_country = {...fields.Regions.regions, ...fields.Regions.countries};
        const validatedFields = marketplaceValidator.validateFields(fieldsClone);
        const valid = !errorsPresent(validatedFields);

        if (valid) {
          success();
        } else {
          error();
        }

        return {
          validations: validatedFields,
          valid,
        };
      },
      // eslint-disable-next-line no-shadow
      onChange: ({ fields, validations }, { onChange }) => (
        fieldPath,
        value
      ) => {
        const fieldsClone = { ...fields };
        const validationsClone = { ...validations };

        // fieldPath here is either a string key
        // for a field, or an array that gives a
        // deep path to the field in the state
        const fullPath =
          typeof fieldPath === 'string' ? [fieldPath] : fieldPath;

        const updatedFields = assocPath(fullPath, value, fieldsClone);
        const updatedValidations = assocPath(
          fullPath,
          marketplaceValidator.validateField(fullPath, value),
          validationsClone
        );

        // Set the section modified flag to true
        updatedFields.__modified__ = true;

        // If an onChange handler is passed in, call it as well.
        onChange && onChange(fields, updatedFields);

        return {
          fields: updatedFields,
          validations: updatedValidations,
        };
      },
    }
  )(({ ...props }) => <WrappedComponent {...props} />);
