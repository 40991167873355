import { assocPath, path } from 'ramda';
import I18n from './i18n';

export const validations = {
  present: (field, value) => {
    const notPresent =
      value === undefined ||
      value === null ||
      (value.constructor === String && value.trim() === '');

    if (notPresent) {
      return I18n.t('en.present', { field });
    }
  },

  arrayPresent: (field, value) => {
    if (!value?.length) {
      return I18n.t('en.present', { field });
    }
  },

  supported: (field, value) => {
    if (!Object.keys(value).length) {
      return I18n.t('en.supported', { field });
    }
  },

  maxStringLength: (length) => (field, value) => {
    if (value && value.length > length) {
      return I18n.t('en.maxStringLength', { field, length });
    }
  },
};

// iterate recursively down tree and if
// any leaves are an array with errors present
// return true
export const errorsPresent = (errorsMap) => {
  return Object.keys(errorsMap).reduce((accumulator, errorKey) => {
    const value = errorsMap[errorKey];

    if (value.constructor === Object) {
      return errorsPresent(value) ? true : accumulator;
    } else if (value.constructor === Array) {
      return errorsPresent({ ...value }) ? true : accumulator;
    } else {
      return !value || value.length === 0 ? accumulator : true;
    }
  }, false);
};

export default class Validator {
  constructor(validations) {
    this.validations = validations;
  }

  validationsMap() {
    if (!this.validationsAsMap) {
      this.validationsAsMap = this.validations.reduce(
        (accumulator, validation) => {
          return assocPath(
            validation.path,
            {
              name: validation.name,
              validators: validation.validators,
            },
            accumulator
          );
        },
        {}
      );
    }

    return this.validationsAsMap;
  }

  // Array of errors for a particular field
  validateField(fieldPath, value) {
    const validation = path(fieldPath, this.validationsMap());

    if (validation) {
      return validation.validators.reduce((accumulator, validator) => {
        const errorMessage = validator(validation.name, value);

        if (errorMessage) {
          accumulator.push(errorMessage);
        }

        return accumulator;
      }, []);
    } else {
      return [];
    }
  }

  // Errors object for all fields
  validateFields = (fields) => {
    return this.validations.reduce((accumulator, validation) => {
      const fieldErrors = this.validateField(
        validation.path,
        path(validation.path, fields)
      );

      return assocPath(validation.path, fieldErrors, accumulator);
    }, {});
  };
}
