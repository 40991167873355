import React from 'react';
import PT from 'prop-types';

const CopyOnClick = ({ textToCopy, textSpacing, children }) => {
  const elem = React.Children.only(children);

  const copyToClipboard = (e) => {
    const textarea = document.createElement('textarea');

    if (typeof textToCopy === 'string') {
      textarea.textContent = textToCopy;
    } else {
      textarea.textContent = JSON.stringify(textToCopy, null, textSpacing);
    }

    textarea.style.cssText = 'position: fixed; opacity: 0;';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    const message = document.createElement('div');
    message.innerHTML = 'Copied';
    message.style.cssText = `
      position: fixed;
      left: ${e.clientX}px;
      top: ${10 + e.clientY}px;
      z-index: 9999;`;
    message.setAttribute('class', 'copied-text-message');
    document.body.appendChild(message);

    window.setTimeout(() => {
      message.remove();
    }, 1500);
  };

  const onClick = (e) => {
    copyToClipboard(e);

    // Bypass onClick if it was present
    if (elem && elem.props && typeof elem.props.onClick === 'function') {
      elem.props.onClick(e);
    }
  };

  return React.cloneElement(elem, { onClick });
};

CopyOnClick.propTypes = {
  children: PT.node.isRequired,
  textToCopy: PT.oneOfType([PT.string, PT.shape({})]),
  textSpacing: PT.oneOfType([PT.string, PT.number]),
};

CopyOnClick.defaultProps = {
  textSpacing: 2,
};

export default CopyOnClick;
