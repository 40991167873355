import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    appLogo: 'App Logo',
    credentialsError: 'Error: could not retrieve app credentials from Procore.',
    marketplaceError: 'Error: unable to retrieve marketplace app.',
    errorOccurred: 'An error occurred',
    appSettings: 'App Settings',
    appCredentials: 'Production OAuth Credentials',
    apiKeyApproval:
      'API keys will be made available once your app is approved.',
    beta: 'beta',
    cancel: 'Cancel',
    documentationInfo: 'Documentation can be found',
    exportCSV: 'Export CSV',
    provideFeedback: 'Please provide feedback at %{feedback_email}',
    generateSandbox: 'Generate Sandbox',
    generateFakeSandbox: 'Generate Fake Sandbox',
    documentationLocationInfo: 'Documentation can be found %{here_text}',
    publishedAppWarning:
      'This is a published integration so some fields have been disabled.',
    productionCredentials: 'Production Credentials',
    requiredField: 'Required Field',
    update: 'Update',
    sandboxClientIdHelp: 'Learn more about Sandbox Account credentials.',
    clientIdHelp: 'Learn more about application production credentials.',
    manifestHelp: 'Learn more about creating a manifest.',
    redirectUriHelp:
      'Learn more about setting the redirect URI for your application.',
    oneLineURI: 'Use one line per URI.',
    acceptableURI: 'http://localhost is the only acceptable non-HTTPS URI.',
    urnRedirectlessOAuth:
      'Use urn:ietf:wg:oauth:2.0:oob for redirectless OAuth.',
    sandboxAccount: 'Sandbox OAuth Credentials',
    sandboxSupport: 'Sandbox Support',
    moreInfo: 'More info',
    errors: {
      marketplaceUpdate:
        'An error occurred while updating the marketplace listing.',
      marketplaceFetch: 'An error occurred while loading the developer app.',
      loadingDeveloperApp: 'An error occurred while loading the developer app.',
    },
    labels: {
      appAvatar: 'App Avatar',
      appName: 'App Name',
      appType: 'App Type',
      category: 'Category',
      clientId: 'Client ID',
      clientSecret: 'Client Secret',
      description: 'Description',
      logoUpload: 'Drop image file here, or click to select file to upload.',
      logo: 'Logo',
      sandboxUrl: 'Sandbox URL',
      supportEmail: 'Support Email',
      supportUrl: 'Site URL',
      supportPhone: 'Support Phone',
      redirectUri: 'Redirect URI',
      marketplaceApp: {
        about: 'About Your Company',
        builtBy: 'Built By',
        regions: 'Regions',
        name: 'Name',
        searchDescription: 'Search Description',
        tagline: 'Tagline',
        description: 'Description',
        howItWorks: 'How it Works',
        category: 'Category',
        rejectionMessage: 'Marketplace submission status: Rejected',
        rejectionReasonButton: 'See Why',
        inReviewMessage: 'Marketplace submission status: In Review',
        inReviewDetails:
          'You must withdraw from review if you want to make changes.',
        learnHowUrl: 'Learn How URL',
        contactUsEmail: 'Contact Us Email',
        supportEmail: 'Support Email',
        supportWebsiteUrl: 'Support Website URL',
        supportWebsiteLabel: 'Support Website Label',
        supportPhone: 'Support Phone',
        supportDocumentation: 'Support Documentation',
        salesInfo: 'Sales Info',
        salesName: 'Sales Contact Name',
        salesPhone: 'Sales Contact Phone',
        salesEmail: 'Sales Contact Email',
        helpfulLinkUrl: 'Link URL',
        helpfulLinkLabel: 'Link Label',
        featureBullet: 'Feature',
        productTool: 'Product',
        integrationRequirement: 'Requirement',
        beta: 'Beta',
        costsMoney: 'Costs Money',
        submitForApproval: 'Submit for Review',
        resubmitForApproval: 'Resubmit for Review',
        saveDraft: 'Save Draft',
        preview: 'Preview',
        withdrawForApproval: 'Withdraw from Review',
        info: {
          about: 'Information about your company',
          builtBy: 'The name of your company',
          name: 'The app name that will appear on the Marketplace Tile page',
          searchDescription: 'Used for finding the app',
          tagline: 'A tagline for the app',
          description:
            'A thorough description of the features, capability, and functionality of your app',
          screenshots: 'Some screenshots',
          howItWorks:
            'Provide a description of how the integration between Procore and your App works. 500 character maximum.',
          category: 'Choose up to three categories your app falls under',
          learnHowUrl: 'The URL to learn how to install your app',
          contactUsEmail: 'The email address for the sales team for your app',
          supportEmail: 'The email address for the support team for your app',
          supportWebsiteUrl:
            'The URL for the support website link for your app',
          supportWebsiteLabel:
            'The label for the support website link for your app',
          supportPhone: 'The phone number for your support team.',
          supportDocumentation:
            'A link to the user documentation for your app.',
          salesName: 'The contact name of the primary salesperson for your app',
          salesPhone:
            'The phone number for the primary salesperson for your app',
          salesEmail:
            'The email address for the primary salesperson for your app',
          helpfulLinks:
            'A list of external links you want to include in your listing. These can be documentation, your company website, etc.',
          helpfulLinkUrl:
            'The url of a link to include in the "helpful links" section of your listing',
          helpfulLinkLabel:
            'The label of a link to include in the "helpful links" section of your listing',
          featureBullets:
            'This should be a list of features your app offers users. They will be shown as bullet points at the top of your marketplace listing. There is a 100 character limit per feature.',
          regionsRequirement: 'Select regions where your app is available.',
          productTool:
            'Please check any Procore products required to use your app',
          integrationRequirement:
            'A requirement a company must fulfill before installing your app',
        },
      },
    },
    manageManifests: 'Manage Manifests',
    manifestProductionCredentialsRequired: `Production credentials must be requested before
    production manifests can be created.`,
    manifestSandboxCredentialsRequired: `A sandbox must be generated before
    sandbox manifests can be created.`,
    marketplaceListing: 'Marketplace Listing',
    placeholders: {
      appType: 'Select an App Type...',
      category: 'Select a Category...',
      description: 'Please enter description.',
      supportEmail: 'Who to request support from',
      supportUrl:
        'A website users can get information and support for your app.',
      supportPhone: 'x-xxx-xxx-xxxx',
      marketplaceApp: {
        category: 'Category',
        phone: 'x-xxx-xxx-xxxx',
      },
    },
    modals: {
      adminMessage: {
        title: 'Reviewer Notes',
        back: 'Back',
      },
      formDrivenNotAvailable: {
        body: "We've upgraded your building app experience to provide you with the best in functionality. However, we've identified that this app cannot be automatically converted to the new experience. You will need to migrate your manifest to version 4.1 and can ",
        bodyLink: 'follow this document to help.',
        confirm: 'Continue',
        title: 'App Not Updated',
      },
      marketplaceSubmitConfirmation: {
        title: 'Submit for Review',
        body: 'Once you submit for listing for review, our team will verify that you have met all marketplace listing requirements. You will receive a notification when your listing is accepted or rejected.',
        confirm: 'Submit for Review',
        back: 'Back',
      },
      marketplaceWithdrawConfirmation: {
        title: 'Withdraw from Review',
        body: 'If you withdraw from review, you will need to resubmit to have any updates to your listing published.',
        confirm: 'Withdraw from Review',
        back: 'Back',
      },
      requestCredentialsConfirmation: {
        confidentialMessage:
          'Requesting Production Credentials will generate a Client ID and Client Secret for your App',
        publicMessage:
          'Requesting Production Credentials will generate a Client ID for your App.',
        button: 'Request Production Access',
        submit: 'Request Production Access',
        cancel: 'Cancel',
        title: 'Request Production Credentials',
      },
      showProductionCredentials: {
        button: 'Show Client Secret',
      },
      resetProductionCredentials: {
        generateNewClientSecret: 'Reset client secret',
        title: 'Reset Client Secret',
        submit: 'Reset My Client Secret',
        cancel: 'Cancel',
        message: 'Here you may reset the client secret for your app.',
        confirmMessage:
          'I understand that generating a new secret will revoke my previous secret and all tokens generated using it.',
      },
      productionCredentials: {
        title: 'Your Production Credentials',
        close: 'Close',
      },
      credentials: {
        implicitProductionAccessRequestExplanation:
          '%{important_text} You will not be issued a Client Secret. If your App uses the OAuth 2.0 Authorization Code flow and you require a Client Secret, close this confirmation box and choose the ‘Confidential’ option.',
        importantText: 'Important!',
        onceEverText: 'once, ever',
        singleUseCredentialsExplanation:
          '%{important_text} The Client Secret for your App is only shown here %{once_ever_text}. You will not be able to retrieve the same Client Secret from your Developer Portal account, ever. Therefore, it is imperative that you immediately store the displayed Client Secret in a safe and secure location. In the event the Client Secret is lost, you must generate a new one which invalidates the old one and may cause a temporary loss of functionality. In addition, your users will be required to re-authenticate before they are allowed to continue using your App.',
      },
    },
    credentials: {
      appIsCurrentlySetTo: 'App is set to',
      confidentialDescriptionFalse:
        'Public Apps use the OAuth 2.0 Implicit Grant flow. Public Apps, such as single-page javascript browser applications, do not utilize or require a Client Secret. See %{oauth2GrantType} and %{oauth2ImplicitGrantFlow} for additional information',
      confidentialDescriptionTrue:
        'Confidential Apps use the OAuth 2.0 Authorization Code Grant flow. Confidential Apps store the Client Secret in a safe and secure (i.e., server-side) location and do not expose it to end users. See %{oauth2GrantType} and %{oauth2AuthCodeGrantFlow} for additional information.',
      confidentialFalse: 'Implicit Grant Type',
      confidentialTrue: 'Authorization Code Grant Type',
      client_credentials: 'Client Credentials Grant Type',
      confidentialInformation: 'Learn about %{oauth2GrantType}',
      confidentialityMessage: 'Your app is...',
      noCredentialsMessage:
        'You can generate app credentials by defining a data connection component in your manifest and promoting a Sandbox manifest to production. Read more about data connection components %{dataConnectionDocLink}',
      noCredentialsMessageLinkText: 'here.',
      oauth2GrantType: 'Choosing an OAuth 2.0 Grant Type',
      oauth2AuthCodeGrantFlow: 'OAuth 2.0 Authorization Code Grant Flow',
      oauth2ImplicitGrantFlow: 'OAuth 2.0 Implicit Grant Flow',
      requestCredentialsMessage:
        'Once your app is ready for release, you must request Production Credentials in order to access the Procore production environment. These credentials are to be used only in the Procore production environment and may not be used for development purposes.',
      setConfidentialityMessage:
        'Select whether your app is a confidential or public app:',
    },
    sandbox: {
      requestInfo:
        'A Sandbox can be furnished on your request! The generation of the Sandbox takes about 10 minutes.',
      completedInfo:
        'Once completed, Sandbox credentials will be available and you can visit your Sandbox company at %{sandbox_url}',
      confirmSandbox: 'Confirm Sandbox',
      contactSupport: 'Contact Support',
      generationInfo:
        'We will be generating a sandbox environment for you. This may take some time. You will receive an email once it is complete. Once generated, you will have access to your sandbox client ID and sandbox client secret.',
      somethingWentWrong: 'Something went wrong building your sandbox.',
      teamNotified:
        'The team has been notified and will attempt to resolve this issue. Your sandbox status will be updated automatically.',
      ongoingIssue:
        'If this is an ongoing issue, feel free to %{contact_support}.',
      buildingSandbox: 'Building your sandbox',
      thisMayTakeTime:
        'This may take several minutes. You will receive an email once it is complete.',
      supportSite: 'Support Site',
      visitSupportSite:
        'Feel free to visit our %{support_site} to access additional learning resources.',
      sandboxAccount: 'Sandbox OAuth Credentials',
      askOwner: 'Please ask the app owner to generate a sandbox',
    },
    metricsGraph: {
      title: 'App Engagement Over Time',
      tooltip: {
        header: 'App Engagement',
      },
      metrics: {
        daily_marketplace_app_views_aggregates: 'Marketplace Visits',
        daily_app_installations_aggregates: 'Company Installations',
      },
    },
    viewMetricsReport: 'View Metrics Report',
    manageApp: 'Manage App',
    apps: 'Apps',
    backToManageApps: 'Back To Manage Apps',
    appEngagementReport: 'App Engagement Report',
    noMetricsAvailable: 'No Metrics Available For This App At This Time',
    installFeed: {
      title: 'Activity',
      demoCompany: 'DEMO COMPANY',
    },
    detailedMetricsView: {
      totalMarketplaceVisits: 'Total Marketplace Visits',
      companyInstallations: 'Company Installations',
    },
    metricsTable: {
      headers: {
        date: 'Date',
        daily_marketplace_app_views_aggregates: 'Marketplace Visits',
        daily_app_installations_aggregates: 'Installations',
      },
    },
    sandboxManifestCreated: 'New Sandbox Manifest Version Created',
    visitSandbox:
      'Visit the Sandbox Account section below to retrieve your OAuth 2.0 credentials.',
    view: 'View',
    listingActivation: {
      title: 'Marketplace Listing (Optional)',
      description:
        "Access to Procore's Marketplace Listing is reserved for Procore Partners who have completed the vetting questionnaire and agreed to the Partnership Terms. Once your status is confirmed, the Marketplace Listing page will be available for submission. If you do not intend to list your integration on the Marketplace, you can skip this section.",
      contact:
        'If you have questions about the vetting and/or contracting process, please reach out to',
      activation: {
        title: "I'm a Procore Partner",
        description:
          'Complete and submit your Marketplace listing to publish your app on the Procore Marketplace',
        button: 'Request Listing Activation',
      },
      partner: {
        title: "I'm Not Yet a Procore Partner",
        description:
          'In order to list your integration on the App Marketplace, you’ll first need to complete our Procore Partner application.',
        button: 'Become a Partner',
      },
    },
  },
});
