import React from 'react';
import PT from 'prop-types';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../StateContainer';
import ChangeIndicator from '../ChangeIndicator';
import DropzoneModalsDocUpload from '@/react/shared/dropzone/DropzoneModalsDocUpload';
import {
  Box,
  Flex,
  FlexList,
  Switch,
  Typography,
  H3,
  P,
  Dropzone,
  useDropzone,
} from '@procore/core-react';
import { ShieldStar } from '@procore/core-icons/dist';
import styled from 'styled-components';

const FormContentColumn = styled(Form.Content)`
  flex-direction: column;
`;

const TabAppInfo = ({
  fields,
  validations,
  onFieldChange,
  I18n,
  isDisabled,
  isAdminView,
  hasChanged,
  publishedFields,
  FormLabel,
}) => {
  const onChange =
    (...fieldPath) =>
    (value) =>
      onFieldChange([MARKETPLACE_TABS.APP, ...fieldPath], value);
  const onDrop = (acceptedFiles) => {
    const uploadedFile = {
      file: acceptedFiles[0],
      type: 'Document',
      name: acceptedFiles[0].name,
      description: acceptedFiles[0].name,
      original_filename: acceptedFiles[0].name,
      url: acceptedFiles[0].url,
    };
    onChange('security_questionnaire_document')(uploadedFile);
  };
  const removeFile = () => {
    onChange('remove_media_ids')([
      ...(fields.remove_media_ids || []),
      fields.security_questionnaire_document.id,
    ]);
    onChange('security_questionnaire_document')(null);
  };
  const dropzoneState = useDropzone({
    multiple: false,
    onDrop,
    disabled: !fields.security_badge,
  });

  return (
    <FormContentColumn>
      <Flex>
        <Form.Column>
          <Form.Field
            required
            data-qa="marketplace-app-name"
            data-testid="marketplace-app-name"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['public_name']}
                contentI18nTag="name"
                copyText={fields.public_name}
              />
            }
            type="text"
            value={fields.public_name}
            errors={validations.public_name}
            onChange={(e) => onChange('public_name')(e.target.value)}
          />

          <Form.Textarea
            required
            data-qa="marketplace-description"
            data-testid="marketplace-description"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['description']}
                contentI18nTag="description"
                copyText={fields.description}
              />
            }
            type="text"
            value={fields.description}
            errors={validations.description}
            onChange={(e) => onChange('description')(e.target.value)}
          />

          <Form.Field
            required
            data-qa="marketplace-tagline"
            data-testid="marketplace-tagline"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['tagline']}
                contentI18nTag="tagline"
                copyText={fields.tagline}
              />
            }
            type="text"
            value={fields.tagline}
            errors={validations.tagline}
            onChange={(e) => onChange('tagline')(e.target.value)}
          />

          <Form.AsyncPicker
            required
            multi
            data-qa="marketplace-app-category"
            data-testid="marketplace-app-category"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['category_ids']}
                contentI18nTag="category"
                showCopyButton={false}
              />
            }
            value={fields.category_ids}
            errors={validations.category_ids}
            url="/api/v1/categories"
            placeholder={I18n.t('placeholders.marketplaceApp.category')}
            onChange={(values) => {
              onChange('category_ids')(values.map((value) => value.id));
            }}
          />
        </Form.Column>

        <Form.Column>
          <Form.Field
            required
            data-qa="marketplace-built-by"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['built_by']}
                contentI18nTag="builtBy"
                copyText={fields.built_by}
              />
            }
            type="text"
            value={fields.built_by}
            errors={validations.built_by}
            onChange={(e) => onChange('built_by')(e.target.value)}
          />

          <Form.Textarea
            required
            data-qa="marketplace-about"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['about']}
                contentI18nTag="about"
                copyText={fields.about}
              />
            }
            type="text"
            value={fields.about}
            errors={validations.about}
            onChange={(e) => onChange('about')(e.target.value)}
          />

          <Form.Textarea
            required
            data-qa="marketplace-how-it-works"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['how_it_works']}
                contentI18nTag="howItWorks"
                copyText={fields.how_it_works}
              />
            }
            type="text"
            value={fields.how_it_works}
            errors={validations.how_it_works}
            onChange={(e) => onChange('how_it_works')(e.target.value)}
          />

          {isAdminView && (
            <>
              <Form.Checkbox
                data-qa="marketplace-costs_money"
                disabled={isDisabled}
                label={
                  <ChangeIndicator
                    changed={
                      hasChanged(['costs_money']) &&
                      (fields.costs_money ||
                        publishedFields.costs_money !== undefined)
                    }
                  >
                    {I18n.t('labels.marketplaceApp.costsMoney')}
                  </ChangeIndicator>
                }
                checked={fields.costs_money}
                onClick={(e) => onChange('costs_money')(e.target.checked)}
              />
              <Form.Checkbox
                data-qa="marketplace-beta"
                disabled={isDisabled}
                label={
                  <ChangeIndicator
                    changed={
                      hasChanged(['beta']) &&
                      (fields.beta || publishedFields.beta !== undefined)
                    }
                  >
                    {I18n.t('labels.marketplaceApp.beta')}
                  </ChangeIndicator>
                }
                checked={fields.beta}
                onClick={(e) => onChange('beta')(e.target.checked)}
              />
            </>
          )}
        </Form.Column>
      </Flex>
      {isAdminView && (
        <Flex paddingTop="md" style={{ borderTop: '1px solid #f0f0f0' }}>
          <Form.Column>
            <Box>
              <Flex
                data-testid="security-trust-badge"
                marginTop="md"
                marginBottom="md"
                alignContent="flex-start"
                alignItems="center"
              >
                <Box marginRight="sm">
                  <ShieldStar />
                </Box>
                <H3>{I18n.t('labels.marketplaceApp.securityTrust.header')}</H3>
              </Flex>
              <Typography data-testid="security-trust-badge-description">
                <Typography weight="semibold">
                  {I18n.t('labels.marketplaceApp.securityTrust.badgeTitle')}
                </Typography>
                <Flex marginTop="sm">
                  {I18n.t(
                    'labels.marketplaceApp.securityTrust.badgeDescription'
                  )}
                </Flex>
              </Typography>
              <Flex
                alignContent="flex-start"
                alignItems="center"
                paddingTop="md"
              >
                <Switch
                  data-testid="security-trust-badge-switch"
                  checked={fields.security_badge}
                  onChange={() => {
                    onChange('security_badge')(!fields.security_badge);
                  }}
                />
                <Typography style={{ paddingLeft: '8px' }}>
                  {I18n.t('labels.marketplaceApp.securityTrust.badgeTitle')}
                </Typography>
              </Flex>
            </Box>
          </Form.Column>
          <Form.Column>
            <Flex
              data-testid="document-upload-container"
              marginTop="md"
              direction="column"
            >
              <Typography weight="semibold" style={{ marginTop: '40px' }}>
                {I18n.t(
                  'labels.marketplaceApp.securityTrust.selfAssessmentDocument'
                )}
              </Typography>
              <Box
                data-testid="dropzone"
                paddingTop="xs"
                style={{ height: '180px', width: '450px' }}
              >
                <Dropzone {...dropzoneState} />
              </Box>

              <FlexList data-testid="uploaded-file-container" wrap="wrap">
                {fields.security_questionnaire_document && (
                  <DropzoneModalsDocUpload
                    disabled={false}
                    file={fields.security_questionnaire_document}
                    removeFile={removeFile}
                  />
                )}
              </FlexList>
            </Flex>
          </Form.Column>
        </Flex>
      )}
    </FormContentColumn>
  );
};

TabAppInfo.propTypes = {
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  isAdminView: PT.bool.isRequired,
  fields: PT.shape({
    public_name: PT.string,
    description: PT.string,
    tagline: PT.string,
    category_ids: PT.arrayOf(PT.string),
    built_by: PT.string,
    how_it_works: PT.string,
    costs_money: PT.bool,
    beta: PT.bool,
    security_badge: PT.bool,
    security_questionnaire_document: PT.shape({}),
    remove_media_ids: PT.arrayOf(PT.string),
  }).isRequired,
  publishedFields: PT.shape({}).isRequired,
  validations: PT.objectOf(PT.arrayOf(PT.string)).isRequired,
  isDisabled: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  hasChanged: PT.func.isRequired,
  FormLabel: PT.elementType.isRequired,
};

export default TabAppInfo;
