import React from 'react';
import PT from 'prop-types';
import { pathOr } from 'ramda';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../StateContainer';

class TabFeatures extends React.Component {
  renderField(feature, publishedFeature, index) {
    const { onFieldChange, isDisabled, validations, FormLabel } = this.props;

    const onChange = (...fieldPath) => (value) =>
      onFieldChange([MARKETPLACE_TABS.FEATURES, ...fieldPath], value);

    return (
      <li key={index}>
        <Form.Field
          required
          data-qa={`marketplace-app-feature-bullet-${index}`}
          disabled={isDisabled}
          label={
            <FormLabel
              changeFields={['feature_bullets', index]}
              contentI18nTag="featureBullet"
              copyText={feature}
              showPopover={false}
            />
          }
          type="text"
          value={feature}
          errors={pathOr([], ['feature_bullets', index], validations)}
          onChange={(e) => onChange('feature_bullets', index)(e.target.value)}
        />
      </li>
    );
  }

  render() {
    const { fields, publishedFields, I18n } = this.props;

    return (
      <Form.Content>
        <Form.Column>
          <p>{I18n.t('labels.marketplaceApp.info.featureBullets')}</p>
          <ul>
            {fields.feature_bullets.map((feature, index) =>
              this.renderField(
                feature,
                publishedFields.feature_bullets[index],
                index
              )
            )}
          </ul>
        </Form.Column>
      </Form.Content>
    );
  }
}

TabFeatures.propTypes = {
  fields: PT.shape({}).isRequired,
  publishedFields: PT.shape({}).isRequired,
  validations: PT.shape({}).isRequired,
  I18n: PT.shape({}).isRequired,
  onFieldChange: PT.func.isRequired,
  isDisabled: PT.bool.isRequired,
  hasChanged: PT.func.isRequired,
};

export default TabFeatures;
