import React from 'react';
import { RadioButton as CoreRadioButton } from '@procore/core-react';
import cx from 'classnames';
import PT from 'prop-types';
import { Label } from './Form';

class RadioButton extends React.Component {
  render() {
    const {
      label,
      checked,
      disabled,
      className,
      radioButtonClassName,
      text,
      required,
      'data-qa': qaTag,
      ...rest
    } = this.props;

    return (
      <div className={cx('a-field', className)}>
        <Label required={required} label={label} />
        <div className="a-radiobutton-wrapper">
          <CoreRadioButton
            disabled={disabled}
            checked={checked}
            data-qa={qaTag}
            className={cx('a-radiobutton', radioButtonClassName)}
            variant="form"
            size="lg"
            {...rest}
          >
            {text}
          </CoreRadioButton>
        </div>
      </div>
    );
  }
}

RadioButton.propTypes = {
  disabled: PT.bool,
  className: PT.string,
  checked: PT.bool,
  label: PT.node,
  required: PT.bool,
  text: PT.string,
};

RadioButton.defaultProps = {
  className: '',
  label: '',
  required: false,
};

export default RadioButton;
