import I18nUtil from '@/utils/i18nUtil';

export default I18nUtil.withI18n({
  en: {
    acceptableURI: 'http://localhost is the only acceptable non-HTTPS URI.',
    appCredentials: 'Production OAuth Credentials',
    viewManifests: 'View Manifests',
    manifestHelp: 'Learn more about creating a manifest.',
    appSettings: 'App Settings',
    apps: 'Apps',
    clientIdHelp: 'Learn more about application production credentials.',
    collaborators: 'Collaborators',
    collaboratorsTab: {
      addUser: 'Add Another User',
      admin: 'Admin',
      developer: 'Developer',
      owner: 'Owner',
      pendingInvite: 'Pending Invitation',
      updatePermissions: 'Update Permissions',
      user: 'User',
      viewPermissionDetails: 'View Permission Details',
      collaboratorMenu: {
        cancelInvite: 'Cancel Invitation',
        transferOwnership: 'Transfer App Ownership',
        removeCollaborator: 'Remove from Project',
      },
    },
    configurations: 'Configuration Builder',
    configurationsTab: {
      addComponent: 'Add Component',
      addPermissions: 'Add Permissions',
      admin: 'Admin',
      company: 'Company Level',
      components: 'Embedded Components',
      componentsDescription:
        'Customize the display of your application by adding components.',
      copySuccess:
        'The App Version Key was successfully copied to your clipboard',
      edit: 'Edit',
      editPermissions: 'Edit Permissions',
      emptyState:
        "Components determine your app's display. Choose Side panel, full-screen, or both. Apps without components sync data between Procore and external systems.",
      errorOccurred: 'An Error Occurred',
      fullscreen: 'Fullscreen',
      installationKeys: 'Installation Keys',
      installationKeysDetail:
        'Use these keys to do a custom installation of your app on Procore.',
      instructions: 'Instructions and Post Installation Notes',
      instructionsDetails:
        'Give specific instructions that must be carried out by the Procore user when installing and setting up the App.',
      instructionsFormInfo:
        'Instructions are displayed to the user at the time of installation, and later via the App Management page in Procore.',
      instructionsFormTitle: 'Instructions',
      instructionsUrl: 'Instructions URL',
      instructionsPageName: 'Instructions Page Name',
      newVersion: 'New Version (Draft)',
      none: 'None',
      postInstallationFormTitle: 'Post-Installation Notes',
      postInstallationFormInfo:
        'Use the notes attribute to provide a textual description of any post-installation steps required to properly complete the setup of the App.',
      newVersion: 'New Version (Draft)',
      params: 'Params',
      dataConnector: {
        title: 'Data Connector Components',
        description:
          'Choose the appropriate method for your data connection app based on your authentication and access requirements.',
      },
      productionAppVersionKey: 'Production App Version Key (v%{version}): ',
      project: 'Project Level',
      promote: 'Promote Version',
      promoteConfirmationModal: {
        header: 'Promote Manifest Version %{version}?',
        promotionExplanation:
          'Promoting this manifest version to production will enable it to be installed to Procore production accounts by input of its App Version ID. This manifest version will not be discoverable on the Procore Marketplace until you submit it for review and it is accepted by Procore.',
        releaseNotesExplanation:
          'The release notes for this manifest version will be published to the Procore Marketplace once the manifest version has been accepted by Procore. This will be publicly available to all Procore users and cannot be updated once submitted.',
        releaseNotes: 'Release Notes',
        confirm: 'Promote',
        cancel: 'Cancel',
      },
      readOnly: 'Read-only',
      submit: 'Marketplace Submit',
      sandboxAppVersionKey: 'Sandbox App Version Key (v%{version}): ',
      save: 'Save',
      sidepanel: 'Sidepanel',
      standard: 'Standard',
      title: 'New Version (Draft)',
      tool: 'Tool',
      type: 'Type',
      url: 'URL',
      views: 'Views',
      sandboxUrl: 'Sandbox URL',
      header: {
        draft: 'Draft',
        readyForTesting: 'Ready for Testing',
        promoted: 'Promoted',
        approvedVersion: 'Approved Version',
        underReview: 'Under Review',
        newVersion: 'New Version',
        version: 'Version %{version}',
        createVersion: 'Create Version',
        saveVersion: 'Save Version',
        viewManifest: 'View Manifest',
        unkownState: 'Unknown State',
      },
    },
    appManifestSectionInfo:
      'UID and name will be autogenerated under the "app_manifest" root key and will override any input for these fields.',
    manifestContent: 'Manifest Content',
    jsonParseError: 'An error occured parsing your manifest',
    credentials: {
      appIsCurrentlySetTo: 'App is set to',
      client_credentials: 'Client Credentials Grant Type',
      confidentialFalse: 'Implicit Grant Type',
      confidentialInformation: 'Learn about %{oauth2GrantType}',
      confidentialTrue: 'Authorization Code Grant Type',
      noCredentialsMessage:
        'You can generate app credentials by defining a data connection component in your manifest and promoting a Sandbox manifest to production. Read more about data connection components %{dataConnectionDocLink}',
      noCredentialsMessageLinkText: 'here.',
      oauth2GrantType: 'Choosing an OAuth 2.0 Grant Type',
    },
    labels: {
      appAvatar: 'App Avatar',
      appName: 'App Name',
      clientId: 'Client ID',
      clientSecret: 'Client Secret',
      redirectUri: 'Redirect URI',
      sandboxUrl: 'Sandbox URL',
    },
    manageCollaborators: 'Manage Collaborators',
    marketplaceListing: 'Marketplace Listing',
    metricsGraph: {
      title: 'App Engagement Over Time',
      tooltip: {
        header: 'App Engagement',
      },
      metrics: {
        daily_marketplace_app_views_aggregates: 'Marketplace Visits',
        daily_app_installations_aggregates: 'Company Installations',
      },
    },
    modals: {
      addCollaborator: {
        admin: 'Admin',
        cancel: 'Cancel',
        confirm: 'Send Invitation',
        developer: 'Developer',
        emailAddress: 'Email Address',
        role: 'Role',
        title: 'Invite a new collaborator to work on this app',
      },
      addComponent: {
        addParameter: 'Add Parameter',
        bodyTitle: 'Add Component',
        cancel: 'Cancel',
        components: 'Components',
        configurationsBuilder: 'Configurations Builder',
        configurationParam: 'Configuration Parameter',
        configurationParams: 'Configuration Parameters',
        configurationParamsDetail:
          'Configurations define the various properties, or configurable fields, Procore company administrators use to configure your app in a Procore project.',
        description: 'Description',
        descriptionPlaceholder: 'Custom Description',
        descriptionTooltip: 'Specify a custom description for your component',
        fullScreen: 'Full Screen',
        key: 'Key',
        keyRequired:
          'Key, name, and description are required to add another parameter',
        name: 'Name',
        no: 'No',
        parameterInterpolation: 'Parameter Interpolation',
        parameterInterpolationDescription:
          'Dynamically adapt app URLs with variable data for personalized configurations.',
        required: 'Required',
        save: 'Save Component',
        sidePanel: 'Side Panel',
        string: 'String',
        title: 'Components',
        type: 'Type',
        typePlaceholder:
          'Select Type',
        typeTooltip:
          'Select the display type for your application: a context-aware side panel or full experience.',
        url: 'URL',
        urlPlaceholder: 'https://example.com/1234/12',
        urlTooltip: 'Specify the URL address of your application.',
        views: 'Views',
        view: 'View',
        yes: 'Yes',
      },
      addParameter: {
        cancel: 'Cancel',
        confirm: 'Save Parameter',
        descriptionPlaceholder: 'Example Description',
        footerText: 'Add multiple parameters anytime.',
        keyDetails:
          'Assign a distinct key to represent the parameter in the URL structure.',
        keyPlaceholder: 'Assign a concise key, e.g., "uid,"',
        nameDetails: 'Designate a unique identifier for the parameter.',
        namePlaceholder: 'e.g., "user_id,"',
        requiredDescription:
          'Specify if the parameter is mandatory for proper functionality.',
        title: 'Add Parameter',
      },
      credentials: {
        implicitProductionAccessRequestExplanation:
          '%{important_text} You will not be issued a Client Secret. If your App uses the OAuth 2.0 Authorization Code flow and you require a Client Secret, close this confirmation box and choose the ‘Confidential’ option.',
        importantText: 'Important!',
        onceEverText: 'once, ever',
        singleUseCredentialsExplanation:
          '%{important_text} The Client Secret for your App is only shown here %{once_ever_text}. You will not be able to retrieve the same Client Secret from your Developer Portal account, ever. Therefore, it is imperative that you immediately store the displayed Client Secret in a safe and secure location. In the event the Client Secret is lost, you must generate a new one which invalidates the old one and may cause a temporary loss of functionality. In addition, your users will be required to re-authenticate before they are allowed to continue using your App.',
      },
      editPermissions: {
        cancel: 'Cancel',
        permissions: 'Permissions',
        save: 'Save Component',
        title: 'Data Connector Components',
        tool: 'Tool',
        subtitle: 'Add Data Connector Component',
        description:
          'Data Connector App enables you to create, update, and read data from Procore to the connected platform through a Client ID and Client Secret',
        helper:
          'Determine how your app interacts with Procore tools. Select the Permissions that are required to install and use your app.',
        infoCTA: 'Click here for more information.',
        componentType: 'Component Type',
        learnMore: 'Learn More',
        permissionsGridSubtitle: 'Permissions for Service Account',
        authCode:  'User Level Authentication | The integration will interact on behalf of a user and limit the API response body based on their Procore permissions.',
        serviceAccount:  'Service Account Authentication | The integration will use a service account to interact with Procore items.',
        authCodeHelper: 'When you create a new version, User Level Authentication becomes a permanent part of the app by default.'
      },
      permissionsDetails: {
        title: 'Permissions Details',
        cancel: 'Cancel',
      },
      productionCredentials: {
        title: 'Your Production Credentials',
        close: 'Close',
        button: 'Show Client Secret',
      },
      resetProductionCredentials: {
        generateNewClientSecret: 'Reset client secret',
        title: 'Reset Client Secret',
        submit: 'Reset My Client Secret',
        cancel: 'Cancel',
        message: 'Here you may reset the client secret for your app.',
        confirmMessage:
          'I understand that generating a new secret will revoke my previous secret and all tokens generated using it.',
      },
      semanticVersion: {
        defaultError: 'Something went wrong when saving the new version.',
        cancel: 'Cancel',
        create: 'Create',
        title: 'Create New Version',
        versionNumber: 'Version Number',
        lastVersion: 'Last Version: %{version}',
        noVersion: 'Last Version: None',
        helper:
          "In order to save your manifest, you'll need to specificy a version number; otherwise, we'll assign you one automatically.",
      },
      showProductionCredentials: {
        button: 'Show Client Secret',
      },
      viewManifest: {
        copy: 'Copy Code',
        copyDetails: 'Easily transfer the code to a code editor by copying it to your clipboard.',
        manifest: '%{version} Manifest',
        successfulCopy: 'The data was successfully copied to your clipboard.',
      },
    },
    oauthCredentials: 'OAuth Credentials',
    oneLineURI: 'Use one line per URI.',
    permissionsDetailsTable: {
      addPermissions: 'Add Components',
      admin: 'Admin',
      deleteApp: 'Delete App',
      developer: 'Developer',
      editAppSettings: 'Edit App Settings',
      editManifests: 'Create, Modify and Publish Manifests',
      emptySubtitle: 'Create Data Connector Components to Get Started',
      emptyState:
        "Data connector components define how your app syncs data with Procore. Select between User Level Authentication, Service Account Authentication, or both based on your app's functionality and security requirements.",
      owner: 'Owner',
      resetSecrets: 'Reset Client Secret',
      submitListing: 'Submit Marketplace Listing for Review',
      updatePermissions: 'Update Collaborator Permissions',
      user: 'User',
      viewManifests: 'View Manifests',
      viewProdClientId: 'View Production Client ID',
      viewSandboxCreds: 'View Sandbox Credentials',
    },
    cancel: 'Cancel',
    error: 'Error',
    errorOccurred: 'An error occurred',
    delete: 'Delete',
    deleteApp: 'Delete App',
    deleteAppTitle: 'Delete This App',
    deleteAppInfo: `Deleting this app will make this app inaccessible and remove it from the
    "My Apps" page. It will be removed from all collaborators' accounts as well.`,
    deleteAppWarning: `Deleting this app will make this app inaccessible and remove it
    from the "My Apps" page. It will be removed from all collaborators' accounts as well. You cannot undo this.`,
    deleteAppConfirmation: 'Are you sure you want to delete this app?',
    redirectUriHelp:
      'Learn more about setting the redirect URI for your application.',
    requiredField: 'Required Field',
    settings: 'App Settings',
    update: 'Update',
    urnRedirectlessOAuth:
      'Use urn:ietf:wg:oauth:2.0:oob for redirectless OAuth.',
    versions: 'Versions',
    versionsTab: {
      appVersionKey: 'App Version Key',
      clickToCopy: 'Click to copy',
      createdAt: 'Created',
      publishedToMarketplace: 'Published to Marketplace',
      version: 'Version',
    },
    metrics: 'Metrics',
    viewMetricsReport: 'View Metrics Report',
    sandboxAccount: 'Sandbox OAuth Credentials',
    sandbox: {
      requestInfo:
        'A Sandbox can be furnished on your request! The generation of the Sandbox takes about 10 minutes.',
      completedInfo:
        'Once completed, Sandbox credentials will be available and you can visit your Sandbox company at %{sandbox_url}',
      confirmSandbox: 'Confirm Sandbox',
      contactSupport: 'Contact Support',
      generationInfo:
        'We will be generating a sandbox environment for you. This may take some time. You will receive an email once it is complete. Once generated, you will have access to your sandbox client ID and sandbox client secret.',
      somethingWentWrong: 'Something went wrong building your sandbox.',
      teamNotified:
        'The team has been notified and will attempt to resolve this issue. Your sandbox status will be updated automatically.',
      ongoingIssue:
        'If this is an ongoing issue, feel free to %{contact_support}.',
      buildingSandbox: 'Building your sandbox',
      thisMayTakeTime:
        'This may take several minutes. You will receive an email once it is complete.',
      supportSite: 'Support Site',
      visitSupportSite:
        'Feel free to visit our %{support_site} to access additional learning resources.',
      sandboxAccount: 'Sandbox OAuth Credentials',
      askOwner: 'Please ask the app owner to generate a sandbox',
    },
    sandboxMissingTitle: 'Looks like Procore is really popular right now',
    sandboxMissingDetail:
      "We're experiencing a high volume of visitors. Please try refreshing in a few minutes. Your sandbox is being created, and you'll receive an email with instructions shortly.",
    validation: {
      required: 'This field is required',
      views: 'At least one view is required',
      url: 'A valid URL is required',
      missingFields:
        'Please fill in the required fields below before creating a version.',
    },
    listingActivation: {
      title: 'Marketplace Listing (Optional)',
      description: "Access to Procore's Marketplace Listing is reserved for Procore Partners who have completed the vetting questionnaire and agreed to the Partnership Terms. Once your status is confirmed, the Marketplace Listing page will be available for submission. If you do not intend to list your integration on the Marketplace, you can skip this section.",
      contact: 'If you have questions about the vetting and/or contracting process, please reach out to',
      activation: {
        title: "I'm a Procore Partner",
        description: 'Complete and submit your Marketplace listing to publish your app on the Procore Marketplace',
        button: 'Request Listing Activation',
      },
      partner: {
        title: "I'm Not Yet a Procore Partner",
        description: 'In order to list your integration on the App Marketplace, you’ll first need to complete our Procore Partner application.',
        button: 'Become a Partner'
      },
    }
  },
});
