import React, {
  Fragment,
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
} from 'react';
import { number } from 'prop-types';
import { H1, Card } from '@procore/core-react';
import { Sticky, StickyContainer } from 'react-sticky';
import MarkdownRenderer from '@/react/shared/MarkdownRenderer';
import moment from 'moment';
import { useBranchSelector } from '@/react/hooks';

import ChangelogToken from './ChangelogToken';
import { getIndexData } from '../../utils';
import { getParams, setParams } from '../../actions/params';
import { DEFAULT_PAGE, PER_PAGE } from '../../constants/pagination';
import { i18n } from './i18n';
import { StyledPagination } from './styles';
import { SearchBar } from '../Sidebar/styles';

const lineHeight = 22;
const expandedDescriptionSize = lineHeight * 3;
const isoDate = (date) => moment(date).format('YYYY-MM');
const showVersion = (versions) =>
  Array.isArray(versions) && versions.length ? `${versions.sort()[0]}+` : '?';
const endpointMethod = (endpoint) =>
  endpoint ? endpoint.split(/\s/)[0].toLowerCase() : '';
const isArraysEqual = (a1, a2) =>
  JSON.stringify(a1.sort()) === JSON.stringify(a2.sort());
const params = getParams();

function LogsPagination({ total, activePage, setActivePage }) {
  function setPage(page) {
    setParams({ page: parseInt(page, 10) });
    setActivePage(page);
  }

  return (
    <StyledPagination
      dropdown
      onSelectPage={(page) => setPage(page)}
      activePage={activePage}
      perPage={parseInt(params.per_page, 10)}
      items={total}
    />
  );
}

function getSectionSummary(link) {
  const words = link.split('?')[0].split('-');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
}

function ChangelogEntry({
  summary,
  description,
  endpoint,
  type,
  breaking,
  datestamp,
  versions,
  link,
}) {
  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const isBig = height > expandedDescriptionSize;

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <Card
      className={`aggregated-card ${isExpanded ? 'expanded' : ''} ${
        isBig ? 'button-card' : ''
      }`}
    >
      <div className={`aggregated-content ${isExpanded ? 'expanded' : ''}`}>
        <H1 className="aggregated-header">{summary}</H1>
        <div className="changelog-modal-body aggregated-card-body">
          <div>
            <p>{isoDate(datestamp)}</p>
          </div>
          <div>
            <p>
              {i18n.version}
              {showVersion(versions)}
            </p>
          </div>
          <div>
            <ChangelogToken variant={type} label={i18n.label[type].name} />
            {breaking && (
              <ChangelogToken
                variant="breaking"
                label={i18n.label.breaking.name}
              />
            )}
          </div>
        </div>
        <div
          className={`endpoint-block endpoint-request endpoint-request--${endpointMethod(
            endpoint
          )}`}
        >
          {endpoint}
        </div>
        <div className="changelog-modal-description" ref={ref}>
          <MarkdownRenderer source={description} />
        </div>
        <a href={`reference/rest/v1/${link}`}>{getSectionSummary(link)}</a>
        {isBig && (
          <a
            className="aggregated-show-toggle button http-response-toggle-button"
            onClick={toggleExpanded}
          >
            {isExpanded ? i18n.showLess : i18n.showMore}
          </a>
        )}
      </div>
    </Card>
  );
}

function AggregatedBlock({ mount }) {
  const BREAKING = 'breaking';
  const allTypeNames = Object.keys(i18n.label).filter(
    (item) => item !== BREAKING
  );
  const [changelog, setChangelog] = useState();
  const [typeFilter, setTypeFilter] = useState(prepareFiltersParams());
  const [isBreaking, setIsBreaking] = useState(prepareBreakingParams());
  const [totalCount, setTotalCount] = useState(0);
  const [activePage, setActivePage] = useState(parseInt(params.page, 10));
  const [searchTerm, setSearchTerm] = useState(
    params.filters && params.filters.search ? params.filters.search : ''
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { isAdmin, currentBranch, devApiUrl, isEmployee } = mount;
  const [branch] = useBranchSelector({ currentBranch, isAdmin, isEmployee });
  const isEmployeeOrAdmin = isEmployee || isAdmin;
  const toggleBreakingFilter = () => setIsBreaking((current) => !current);
  const toggleFilterItem = (item) =>
    typeFilter.includes(item)
      ? typeFilter.filter((i) => i !== item)
      : [...typeFilter, item];
  const toggleAll = () =>
    isArraysEqual(typeFilter, allTypeNames) ? [] : allTypeNames;
  const applyTypeFilter = (e) =>
    typeFilter.includes(e.type) ||
    (e.breaking && typeFilter.includes(BREAKING));
  const applyHiddenFilter = (item) =>
    item['x-doc'].visibility === 'public' || isEmployeeOrAdmin;

  function prepareFiltersParams() {
    const filtersParams = checkBreakingParams().concat(checkTypeParams());
    return filtersParams.length === 0
      ? allTypeNames.filter((item) => item !== BREAKING)
      : filtersParams;
  }

  function prepareBreakingParams() {
    return params.filters && params.filters.breaking === 'true';
  }

  function checkBreakingParams() {
    return params.filters && params.filters.breaking === 'true'
      ? [BREAKING]
      : [];
  }

  function checkTypeParams() {
    return params.filters && params.filters.type
      ? params.filters.type?.slice(1, -1).split(',')
      : [];
  }

  function getBreakingParam(isBreaking) {
    return isBreaking ? BREAKING : null;
  }

  function setFilters(types, isBreaking) {
    const typesParams = prepareQueryTypes(types);
    const breakingParams = prepareQueryBreaking(getBreakingParam(isBreaking));
    const updatedFilters = {
      filters: { ...typesParams, ...breakingParams, ...getQuerySearch() },
    };
    setParams(updatedFilters);
  }

  function setSearchFilters(value) {
    const updatedFilters = {
      filters: {
        ...getParams().filters,
        ...(value === '' ? {} : { search: value }),
      },
    };
    if (value === '') {
      delete updatedFilters.filters.search;
    }
    setParams(updatedFilters);
    return value;
  }

  function getQuerySearch() {
    const params = getParams();
    return params.filters && params.filters.search
      ? { search: params.filters.search }
      : {};
  }

  function prepareQueryTypes(types) {
    const typesParams = {
      type: `[${types.filter((item) => item !== BREAKING).toString()}]`,
    };
    return typesParams;
  }

  function prepareQueryBreaking(breaking) {
    return breaking === BREAKING ? { breaking: true } : null;
  }

  useEffect(() => {
    getIndexData({
      branchName: branch,
      devApiUrl,
      group: 'aggregated_changelog',
      breaking: getBreakingParam(isBreaking),
      types: typeFilter,
      search: setSearchFilters(searchTerm),
      page: activePage,
      perPage: parseInt(params.per_page, 10),
    }).then(({ data, headers }) => {
      data && setChangelog(data);
      const total = parseInt(headers?.total, 10) || 0;
      setTotalCount(total > 0 ? total : 0);
    });
  }, [currentBranch, activePage, isBreaking, typeFilter, debouncedSearchTerm]);

  const displayPrimaryTypeFilter = (
    <p className="aggregated-checkbox primary">
      <input
        type="checkbox"
        checked={isArraysEqual(typeFilter, allTypeNames)}
        onChange={() => {
          setTypeFilter(toggleAll());
          setFilters(toggleAll(), isBreaking);
        }}
      />
      {i18n.all}
    </p>
  );
  const displayTypeFilters = allTypeNames
    .filter((item) => item !== BREAKING)
    .map((type, i) => (
      <p className="aggregated-checkbox" key={i}>
        <input
          type="checkbox"
          checked={typeFilter.includes(type)}
          onChange={() => {
            setTypeFilter(toggleFilterItem(type));
            setFilters(toggleFilterItem(type), isBreaking);
          }}
        />
        {i18n.label[type].name}
      </p>
    ));
  const displayPrimaryBreakingFilter = (
    <p className="aggregated-checkbox primary">
      <input
        type="checkbox"
        checked={isBreaking}
        onChange={() => {
          setFilters(typeFilter, !isBreaking);
          toggleBreakingFilter();
        }}
      />
      {i18n.label.breaking.name} (only)
    </p>
  );
  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
    return debouncedValue;
  }
  const displayAggregated = () => {
    const display =
      changelog &&
      changelog
        .filter(applyHiddenFilter)
        .filter(applyTypeFilter)
        .sort((a, b) => moment(b.datestamp).diff(moment(a.datestamp)))
        .map(
          (
            {
              summary,
              description,
              endpoint,
              type,
              breaking,
              datestamp,
              versions,
              link,
            },
            i
          ) => (
            <ChangelogEntry
              summary={summary}
              description={description}
              endpoint={endpoint}
              type={type}
              breaking={breaking}
              datestamp={datestamp}
              versions={versions}
              key={summary + endpoint + i}
              link={link}
            />
          )
        );
    if (!display) {
    } else if (display.length) {
      return display;
    } else if (display.length === 0) {
      return <h2>{i18n.noMatch}</h2>;
    }
  };

  return (
    <>
      <div className="aggregated-column-sidebar">
        <div>
          <SearchBar
            id="search_changelog_input_field"
            onChange={(value) => setSearchTerm(value)}
            placeholder={i18n.searchChangelogs}
            value={searchTerm}
          />
        </div>
        <div className="aggregated-filter">
          {displayPrimaryTypeFilter}
          {displayTypeFilters}
          <div className="divider" />
          {displayPrimaryBreakingFilter}
        </div>
      </div>
      <div className="endpoint-block changelog-block aggregated-block">
        <div className="pagination-header">
          <LogsPagination
            total={totalCount}
            activePage={activePage}
            setActivePage={setActivePage}
          />
          <br />
        </div>
        {displayAggregated()}
        <div>
          <LogsPagination
            total={totalCount}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        </div>
      </div>
      <StickyContainer className="doc-column-sidebar __print-none">
        <Sticky className="full-height-box" id="change-types">
          <div className="doc-nav-right-options changelog-col-right">
            <h3>{i18n.changeTypes}</h3>
            {allTypeNames.map((type) => (
              <Fragment key={type}>
                <div className="change-log-agg-token">
                  <ChangelogToken
                    variant={type}
                    label={i18n.label[type].name}
                  />
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: i18n.label[type].summary,
                  }}
                />
              </Fragment>
            ))}
          </div>
        </Sticky>
      </StickyContainer>
    </>
  );
}

LogsPagination.defaultProps = {
  page: DEFAULT_PAGE,
  per_page: PER_PAGE,
};

LogsPagination.propTypes = {
  page: number,
  per_page: number,
  total: number,
};

export default AggregatedBlock;
