import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import {
  Button,
  Box,
  Card,
  useI18nContext,
  Typography,
  Flex,
  Spinner,
  Grid,
  Link,
  LinkButton,
  ErrorBanner,
  Banner,
} from '@procore/core-react';
import { useToastAlertContext } from '@procore/toast-alert';
import {
  ArrowUpShare,
  ChevronDown,
  ChevronRight,
  Duplicate,
} from '@procore/core-icons';
import { MANIFEST_TYPES } from '@/react/shared/constants';
import ProductionManifestsTable from '@/react/common/ProductionManifestsTable';
import trackEvent from '@/react/shared/analytics';
import SemanticVersionModal from '../modals/SemanticVersionModal';
import PromoteConfirmationModal from '../modals/PromoteConfirmationModal';
import { UseShowContext } from '../Show.context';
import Components from '../cards/configurations/Components';
import Instructions from '../cards/configurations/Instructions';
import Permissions from '../cards/configurations/Permissions';
import { ConfigurationsHeader } from '../cards/configurations/ConfigurationsHeader';
import * as yup from 'yup';
import {
  postInstallationInstructionsValidation,
  isInstructionsEmpty,
} from '../cards/configurations/Instructions';

const ConfigurationsCard = ({ titleKey, descriptionKey, children, open }) => {
  const [expanded, setExpanded] = useState(open);
  const toggleExpanded = () => setExpanded(!expanded);

  const I18n = useI18nContext();

  return (
    <Box padding="lg none" data-testid="configurations-card">
      <Card>
        <Box padding="lg">
          <Flex>
            <Typography
              intent="h2"
              onClick={toggleExpanded}
              data-testid="expand-card-button"
            >
              {expanded ? <ChevronDown /> : <ChevronRight />}
            </Typography>
            <Flex direction="column">
              <Typography intent="h2" onClick={toggleExpanded}>
                {I18n.t(titleKey)}
              </Typography>
              <Typography intent="body" color="gray40">
                {I18n.t(descriptionKey)}
              </Typography>
            </Flex>
          </Flex>
          {expanded && <>{children}</>}
        </Box>
      </Card>
    </Box>
  );
};

ConfigurationsCard.propTypes = {
  titleKey: PT.string.isRequired,
  descriptionKey: PT.string.isRequired,
  children: PT.shape({}).isRequired,
  open: PT.bool.isRequired,
};

// one of fullscreen or sidepanel must be present
// if any of the post installation instruction fields are present, all must be present
const displayValidation = yup.object().shape({
  fullscreen: yup.object(),
  sidepanel: yup.object(),
  permissions: yup.object(),
  post_installation_instruction: postInstallationInstructionsValidation,
});

const saveValidation = displayValidation.test(
  'at-least-one-component',
  'At least one of fullscreen or sidepanel must be present',
  (value) => {
    if (value.authCodeEnabled) {
      return true;
    }
    const hasFullscreen =
      value.fullscreen && Object.keys(value.fullscreen).length > 0;
    const hasSidepanel =
      value.sidepanel && Object.keys(value.sidepanel).length > 0;
    const hasProjectPermissions =
      Object.keys(value.permissions?.project || {}).length > 0;
    const hasCompanyPermissions =
      Object.keys(value.permissions?.company || {}).length > 0;
    const hasPostInstallInstructions = !isInstructionsEmpty(
      value.post_installation_instruction
    );
    return Boolean(
      hasFullscreen ||
        hasSidepanel ||
        hasProjectPermissions ||
        hasCompanyPermissions ||
        hasPostInstallInstructions
    );
  }
);

const validateSave = (manifest) => {
  try {
    saveValidation.validateSync(manifest);
    return true;
  } catch (e) {
    return false;
  }
};

const validateForm = (manifest) => {
  try {
    displayValidation.validateSync(manifest);
    return true;
  } catch (e) {
    return false;
  }
};

// The manfiest doesn't populate the submittable or promotable fields on the manifest
// when retrieved from this endpoint, so we need to add them manually from the app version
const patchManifestWithAppVersion = (manifest, appVersion) => ({
  ...manifest,
  submittable: appVersion.submittable,
  promotable: appVersion.promotable,
});

const Configurations = ({ initialAuthCodeEnabled = false }) => {
  const {
    developerAppId,
    canSetSemanticVersions,
    api,
    state: {
      currentManifest,
      setCurrentManifest,
      appVersion,
      setAppVersion,
      addComponentModalOpen,
      editPermissionsModalOpen,
      devApp,
    },
  } = UseShowContext();
  const { showToast } = useToastAlertContext();

  const formOpen = addComponentModalOpen || editPermissionsModalOpen;
  const I18n = useI18nContext();
  const [sandboxManifests, setSandboxManifests] = useState([]);
  const [productionManifests, setProductionManifests] = useState([]);
  const [loading, setLoading] = useState(Boolean(currentManifest));
  const [displayError, setDisplayError] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(() =>
    validateSave(currentManifest.configured_manifest_content)
  );
  const [authCodeEnabled, setauthCodeEnabled] = useState(initialAuthCodeEnabled);
  const [authCodeLocked, setAuthCodeLocked] = useState(false);

  useEffect(() => {
    setSaveEnabled(() =>
      validateSave({
        ...currentManifest?.configured_manifest_content,
        authCodeEnabled,
      })
    );
    setDisplayError(
      () => !validateForm(currentManifest?.configured_manifest_content)
    );
  }, [currentManifest, authCodeEnabled]);

  useEffect(() => {
    setLoading(true);
    const manifestPromise = api
      .getManifests(developerAppId, MANIFEST_TYPES.SANDBOX)
      .then(({ data }) => {
        setSandboxManifests(data);
      });
    const prodManifestPromise = api
      .getManifests(developerAppId, MANIFEST_TYPES.PRODUCTION)
      .then(({ data }) => {
        setProductionManifests(data);
      });

    Promise.all([manifestPromise, prodManifestPromise]).finally(() =>
      setLoading(false)
    );
  }, []);

  useEffect(() => {
    if (sandboxManifests[0]) {
      api.getVersion(sandboxManifests[0].app_version_id).then(({ data }) => {
        setCurrentManifest(data);
        setAuthCodeLocked(true);
        setauthCodeEnabled(data.auth_code_enabled);
      });
    }
  }, [sandboxManifests]);

  const currentSandboxManifest = sandboxManifests[0] || null;
  const currentProductionManifest = productionManifests[0] || null;

  const [semanticVersionModalOpen, setSemanticVersionModalOpen] =
    useState(false);
  const [promoteConfirmationModalOpen, setPromoteConfirmationModalOpen] =
    useState(false);
  const [promoteDisabled, setPromoteDisabled] = useState(false);

  const saveManifest = async (newVersion) => {
    const { data } = await api.createManifest(developerAppId, {
      ...currentManifest,
      semantic_version: newVersion,
    });
    setSandboxManifests([data.manifest, ...sandboxManifests]);
    setSemanticVersionModalOpen(false);
    setCurrentManifest(data);
  };

  const promoteManifest = (appVersionId, releaseNotes) => {
    api.promoteManifest(appVersionId, releaseNotes).then(({ data }) => {
      setCurrentManifest({
        ...currentManifest,
        changelog: data.changelog,
      });
      data.submittable = true;
      setPromoteConfirmationModalOpen(false);
      setProductionManifests([data, ...productionManifests]);
    });
  };

  const updateAppVersion = (appVersion) => {
    api.updateAppVersion(appVersion).then(({ data }) => {
      setAppVersion(data);

      const newManifest = patchManifestWithAppVersion(data.manifest, data);
      setProductionManifests([newManifest, ...productionManifests]);
    });
  };

  const trackSubmitReviewEvent = (key, params) => {
    trackEvent(key, {
      developer_app_id: developerAppId,
      ...params,
    });
  };

  return (
    <Spinner loading={loading}>
      <div data-testid="configurations">
        {displayError && (
          <ErrorBanner data-testid="error-banner">
            <Banner.Content>
              <Banner.Body>{I18n.t('validation.missingFields')}</Banner.Body>
            </Banner.Content>
          </ErrorBanner>
        )}

        <ConfigurationsHeader
          sandboxManifest={currentSandboxManifest}
          productionManifest={currentProductionManifest}
          promotionDisabled={promoteDisabled && !formOpen}
          saveDisabled={formOpen || !saveEnabled}
          onSave={() => {
            setSemanticVersionModalOpen(true);
          }}
          onPromote={() => {
            setPromoteConfirmationModalOpen(true);
            setPromoteDisabled(true);
          }}
          reviewSubmitComponent={
            <ProductionManifestsTable
              parentApp={{
                id: developerAppId,
                type: MANIFEST_TYPES.PRODUCTION,
              }}
              manifests={productionManifests}
              canSetSemanticVersions={canSetSemanticVersions}
              formBasedExperience
              formBasedSubmitWithdrawOnly
              currentProductionManifest={currentProductionManifest ?? {}}
              canModifyManifests
              updateAppVersion={updateAppVersion}
              trackEvent={trackSubmitReviewEvent}
            />
          }
          loading={loading}
        />

        <SemanticVersionModal
          open={semanticVersionModalOpen}
          setOpen={setSemanticVersionModalOpen}
          onSave={saveManifest}
          currentVersion={currentManifest.semantic_version}
        />

        <PromoteConfirmationModal
          open={promoteConfirmationModalOpen}
          setOpen={setPromoteConfirmationModalOpen}
          appVersionId={currentManifest.id}
          semanticVersion={currentManifest.semantic_version}
          promoteManifest={promoteManifest}
        />

        {sandboxManifests.length > 0 && (
          <Card>
            <Box padding="lg">
              <Typography intent="h2">
                {I18n.t('configurationsTab.installationKeys')}
              </Typography>
              <Box paddingBottom="sm">
                <Typography intent="body" color="gray40">
                  {I18n.t('configurationsTab.installationKeysDetail')}
                </Typography>
              </Box>
              <Grid.Row>
                <Grid.Col colWidth={6}>
                  {currentSandboxManifest && (
                    <Box
                      onClick={() => {
                        navigator.clipboard.writeText(
                          currentSandboxManifest.app_version_id
                        );
                        showToast.success(
                          I18n.t('configurationsTab.copySuccess')
                        );
                      }}
                      display="flex"
                      flexDirection="column"
                      paddingBottom="sm"
                    >
                      <Typography weight="bold">
                        {I18n.t('configurationsTab.sandboxAppVersionKey', {
                          version: currentSandboxManifest.semantic_version,
                        })}
                      </Typography>
                      <span>
                        <Typography intent="body">
                          {currentSandboxManifest.app_version_id}
                        </Typography>
                        <Button
                          icon={<Duplicate />}
                          size="sm"
                          variant="tertiary"
                          data-testid="sandbox-copy-button"
                        />
                      </span>
                    </Box>
                  )}

                  {currentProductionManifest && (
                    <Box
                      onClick={() => {
                        navigator.clipboard.writeText(
                          currentProductionManifest.app_version_id
                        );
                        showToast.success(
                          I18n.t('configurationsTab.copySuccess')
                        );
                      }}
                      display="flex"
                      flexDirection="column"
                    >
                      <Typography weight="bold">
                        {I18n.t('configurationsTab.productionAppVersionKey', {
                          version: currentProductionManifest.semantic_version,
                        })}
                      </Typography>
                      <span>
                        <Typography intent="body">
                          {currentProductionManifest.app_version_id}
                        </Typography>
                        <Button
                          icon={<Duplicate />}
                          size="sm"
                          variant="tertiary"
                        />
                      </span>
                    </Box>
                  )}
                </Grid.Col>
                <Grid.Col colWidth={6}>
                  <Box display="flex" flexDirection="column" paddingBottom="sm">
                    <Typography weight="bold">
                      {I18n.t('configurationsTab.sandboxUrl', {
                        version: currentSandboxManifest.semantic_version,
                      })}
                    </Typography>
                    <span>
                      <Link href={devApp?.sandbox?.url} target="_blank">
                        sandbox.procore.com
                      </Link>
                      <LinkButton
                        href={devApp?.sandbox?.url}
                        target="_blank"
                        icon={<ArrowUpShare />}
                        size="sm"
                        variant="tertiary"
                        data-testid="sandbox-url"
                      />
                    </span>
                  </Box>
                </Grid.Col>
              </Grid.Row>
            </Box>
          </Card>
        )}

        <ConfigurationsCard
          titleKey={'configurationsTab.components'}
          descriptionKey={'configurationsTab.componentsDescription'}
          open
        >
          <Components
            currentSandboxManifest={currentSandboxManifest ?? {}}
            currentProductionManifest={currentProductionManifest ?? {}}
            currentManifest={currentManifest}
            setCurrentManifest={setCurrentManifest}
            open={true}
          />
        </ConfigurationsCard>

        <ConfigurationsCard
          titleKey={'configurationsTab.dataConnector.title'}
          descriptionKey={'configurationsTab.dataConnector.description'}
          open={false}
        >
          <Permissions
            currentManifest={currentManifest}
            setCurrentManifest={setCurrentManifest}
            authCodeEnabled={authCodeEnabled}
            authCodeLocked={authCodeLocked}
            setAuthCodeEnabled={setauthCodeEnabled}
          />
        </ConfigurationsCard>

        <ConfigurationsCard
          titleKey={'configurationsTab.instructions'}
          descriptionKey={'configurationsTab.instructionsDetails'}
          open={false}
        >
          <Instructions
            installInstructions={
              currentManifest?.configured_manifest_content
                ?.post_installation_instruction
            }
            updateInstructions={(postInstallInstructions) =>
              setCurrentManifest({
                ...currentManifest,
                configured_manifest_content: {
                  ...currentManifest.configured_manifest_content,
                  post_installation_instruction: postInstallInstructions,
                },
              })
            }
          />
        </ConfigurationsCard>
      </div>
    </Spinner>
  );
};

Components.propTypes = {
  currentManifest: PT.shape({
    content: PT.shape({}),
  }),
  setCurrentManifest: PT.func,
};

export default Configurations;
