import React from 'react';
import PT from 'prop-types';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../StateContainer';
import ChangeIndicator from '../ChangeIndicator';

class TabRequirements extends React.Component {
  renderField(requirement, publishedRequirement, index) {
    const {
      I18n,
      onFieldChange,
      isDisabled,
      hasChanged,
      FormLabel,
    } = this.props;

    const onChange = (...fieldPath) => (value) =>
      onFieldChange([MARKETPLACE_TABS.REQUIREMENTS, ...fieldPath], value);

    return (
      <li key={index}>
        <Form.Field
          data-qa={`marketplace-app-integration-requirement-${index}`}
          disabled={isDisabled}
          label={
            <FormLabel
              changeFields={['requirements', index]}
              contentI18nTag="integrationRequirement"
              copyText={requirement}
              showPopover={false}
            />
          }
          type="text"
          value={requirement}
          onChange={(e) => onChange('requirements', index)(e.target.value)}
        />
      </li>
    );
  }

  render() {
    const { fields, publishedFields, I18n } = this.props;

    const requirements = fields.requirements || [];
    const publishedRequirements = publishedFields.requirements || [];

    return (
      <Form.Content>
        <Form.Column>
          <p>{I18n.t('labels.marketplaceApp.info.integrationRequirement')}</p>
          <ul>
            {requirements.map((requirement, index) =>
              this.renderField(requirement, publishedRequirements[index], index)
            )}
          </ul>
        </Form.Column>
      </Form.Content>
    );
  }
}

TabRequirements.propTypes = {
  I18n: PT.shape({}).isRequired,
  fields: PT.shape({}).isRequired,
  publishedFields: PT.shape({}).isRequired,
  isDisabled: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  hasChanged: PT.func.isRequired,
};

export default TabRequirements;
