import React from 'react';
import { I18nProvider, useI18nContext } from '@procore/core-react';
import withBugsnag from '@/react/shared/BugsnagProvider';
import { compose } from 'recompose';
import Show from './Show';
import i18n from './i18n';
import StateContainer from './StateContainer';
import {
  ToastAlertProvider,
  useToastAlertContext,
} from '@procore/toast-alert';

const WrappedComponent = (props) => {
  const I18n = useI18nContext();
  return <Show I18n={I18n} {...props} />;
};

const ShowWithI18n = (props) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <WrappedComponent {...props} />
  </I18nProvider>
);

const WrappedStateContainer = compose(
  withBugsnag,
  StateContainer
)(ShowWithI18n);

const StateContainerWithToast = (props) => {
  const { showToast } = useToastAlertContext();

  return <WrappedStateContainer showToast={showToast} {...props} />;
};

const ContainerWithToastProvider = (props) => (
  <ToastAlertProvider>
    <StateContainerWithToast {...props} />
  </ToastAlertProvider>
);

export default ContainerWithToastProvider;