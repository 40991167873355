import React, { useState, useEffect } from 'react';
import {
  Banner,
  Box,
  Button,
  ErrorBanner,
  InfoBanner,
  Spinner,
  SuccessBanner,
  Switch,
} from '@procore/core-react';
import PT from 'prop-types';
import {
  MARKETPLACE_APP_STATES,
  MARKETPLACE_APPROVAL_STATES,
} from '@/react/shared/constants';
import { omit, pathOr } from 'ramda';
import Form from '@/react/shared/form';
import PopoverTag from '@/react/shared/PopoverTag';
import { Info } from '@procore/core-icons';
import StateContainer, { MARKETPLACE_TABS } from './StateContainer';
import AdminMessageModal from './modals/AdminMessageModal';
import TabbedFormBody from './FormTabs/TabbedFormBody';
import { AdminFooter, Footer } from './Footer';
import AdminMessageForm from './AdminMessageForm';

const MarketplaceAppForm = (props) => {
  const {
    I18n,
    fields,
    unmappedFields,
    unmappedPublishedFields,
    mapMarketplaceAppFields,
    setLoadingValue,
    setError,
    setValid,
    save,
    onUpdate,
    isAdminView,
    canEdit,
    onChange,
    trackEvent,
    canSubmitMarketplaceApp,
    marketplaceDraftListingPath,
    newDraftPreviewActive,
    isProcoreEmployee,
    helpEmail,
    valid,
    validateFields,
    errors,
    loadingValuesMap,
    hasPublishedApp,
    developerAppId,
    marketplaceEnabled,
    onAppChange,
    updateDeveloperApp,
    applicationEnabled = false
  } = props;

  useEffect(() => {
    if (unmappedFields) {
      mapMarketplaceAppFields(unmappedFields, unmappedPublishedFields);
      setLoadingValue('global', false);
    }
  }, []);

  useEffect(() => {
    mapMarketplaceAppFields(unmappedFields, unmappedPublishedFields);
    setLoadingValue('global', false);
  }, [unmappedFields]);

  const getSaveFields = () => {
    const tabs = Object.values(MARKETPLACE_TABS);

    const topLevelFields = omit(
      [
        '__modified__',
        'product_tools',
        'regions',
        'countries',
        'approval_state',
        ...tabs,
      ],
      fields
    );

    const tabsFields = tabs.reduce((acc, currTab) => {
      return { ...acc, ...fields[currTab] };
    }, {});

    const pathedProductTools = pathOr(
      [],
      [MARKETPLACE_TABS.TOOLS, 'product_tools'],
      fields
    );

    const pathedRegions = pathOr(
      [],
      [MARKETPLACE_TABS.REGIONS, 'regions'],
      fields
    );

    const pathedCountries = pathOr(
      [],
      [MARKETPLACE_TABS.REGIONS, 'countries'],
      fields
    );

    return {
      product_tool_ids: Object.keys(pathedProductTools).filter(
        (productToolId) => pathedProductTools[productToolId]
      ),
      region_ids: Object.keys(pathedRegions).filter(
        (regionId) => pathedRegions[regionId]
      ),
      country_ids: Object.keys(pathedCountries).filter(
        (countryId) => pathedCountries[countryId]
      ),
      ...topLevelFields,
      ...tabsFields,
    };
  };

  const saveForm = (formFields) => {
    save(
      formFields,
      (marketplaceAppFieldsSuccess) => {
        if (onUpdate) {
          onUpdate(marketplaceAppFieldsSuccess);
        }
        setValid(true);
        setError(null);
        setLoadingValue('global', false);
      },
      (marketplaceAppFieldsError) => {
        setError(marketplaceAppFieldsError);
        setLoadingValue('global', false);
      }
    );
  };

  const AdminMessage = () => {
    const [adminMessageModalOpen, setAdminMessageModalOpen] = useState(false);

    if (
      fields.state === MARKETPLACE_APP_STATES.IN_REVIEW ||
      fields.approval_state === MARKETPLACE_APPROVAL_STATES.UNREVIEWED ||
      !fields.admin_message
    ) {
      if (isAdminView) {
        return <StateBanner />;
      }
      return null;
    }

    const BannerType =
      fields.approval_state === MARKETPLACE_APPROVAL_STATES.APPROVED
        ? SuccessBanner
        : ErrorBanner;
    const bodyMessage =
      fields.approval_state === MARKETPLACE_APPROVAL_STATES.APPROVED
        ? 'labels.marketplaceApp.approvalMessage'
        : 'labels.marketplaceApp.rejectionMessage';

    return (
      <Form.Row>
        <AdminMessageModal
          isVisible={adminMessageModalOpen}
          hide={() => setAdminMessageModalOpen(false)}
          message={fields.admin_message}
          I18n={I18n}
        />
        <Box padding="none xl xl xl">
          <BannerType data-qa="marketplace-review-status-banner">
            <Banner.Content>
              <Banner.Title>{I18n.t(bodyMessage)}</Banner.Title>
            </Banner.Content>
            {fields.admin_message && (
              <Banner.Action>
                <Button
                  variant="secondary"
                  onClick={() => setAdminMessageModalOpen(true)}
                >
                  {I18n.t('labels.marketplaceApp.detailsButton')}
                </Button>
              </Banner.Action>
            )}
            {!isAdminView && (
              <Banner.Dismiss
                onClick={() => {
                  saveForm({
                    approval_state: MARKETPLACE_APPROVAL_STATES.UNREVIEWED,
                  });
                }}
              />
            )}
          </BannerType>
        </Box>
      </Form.Row>
    );
  };

  const InReviewMessage = () => (
    <Box padding="none xl none xl">
      <Form.Row>
        <InfoBanner data-qa="marketplace-success-banner">
          <Banner.Content>
            <Banner.Title>
              {I18n.t('labels.marketplaceApp.inReviewMessage')}
            </Banner.Title>
            <Banner.Body>
              {I18n.t('labels.marketplaceApp.inReviewDetails')}
            </Banner.Body>
          </Banner.Content>
        </InfoBanner>
      </Form.Row>
    </Box>
  );

  const StateBanner = () => (
    <Box padding="none xl xl xl">
      <Form.Row>
        <InfoBanner data-qa="marketplace-info-banner">
          <Banner.Content>
            <Banner.Title>
              {I18n.t('labels.marketplaceApp.statusMessage')}
              {fields.state === MARKETPLACE_APP_STATES.IN_REVIEW
                ? I18n.t('labels.marketplaceApp.inReview')
                : fields.state}
            </Banner.Title>
          </Banner.Content>
        </InfoBanner>
      </Form.Row>
    </Box>
  );

  const Errors = () => (
    <Box padding="none xl none xl">
      <Form.Row>
        <ErrorBanner data-qa="marketplace-error-banner">
          <Banner.Content>
            <Banner.Title>
              {errors.length === 1 ? errors[0] : I18n.t('errors.bannerTitle')}
            </Banner.Title>

            {errors.length > 1 && (
              <Banner.Body>
                <ul>
                  {errors.map((error, idx) => (
                    <li key={idx}>{error}</li>
                  ))}
                </ul>
              </Banner.Body>
            )}
          </Banner.Content>
        </ErrorBanner>
      </Form.Row>
    </Box>
  );

  return (
    <Spinner loading={loadingValuesMap.global}>
      <Box padding="xl" data-testid='marketplace-app-form'>
        <h2 className="display-inline">{I18n.t('marketplaceListing')}</h2>
        <h4 className="display-inline float-right">
          <a
            style={{ color: '#000' }}
            title="Document describing how you can maximize conversion rates for your marketplace listing"
            target="_blank"
            href="https://developers.procore.com/documentation/building-apps-maximizing-conversion-rates"
          >
            <Info size="sm" />
          </a>
          <a href="https://developers.procore.com/documentation/building-apps-maximizing-conversion-rates">
            {' '}
            Maximizing Marketplace Conversion Rates
          </a>
        </h4>
        {hasPublishedApp && (
          <PopoverTag title={I18n.t('published').toUpperCase()}>
            <p>{I18n.t('publishedDetails')}</p>
          </PopoverTag>
        )}
        {isAdminView && applicationEnabled && (
          <Box padding="xl xl xl none">
            <Switch
              onChange={() => {
                updateDeveloperApp({
                  id: developerAppId,
                  marketplace_enabled: !marketplaceEnabled,
                }).then((resp) => {
                  onAppChange(
                    'marketplace_enabled',
                    resp.data.marketplace_enabled
                  );
                });
              }}
              checked={marketplaceEnabled}
            >
              {I18n.t('marketplaceEnabled')}
            </Switch>
          </Box>
        )}
      </Box>

      <AdminMessage />
      {!isAdminView && fields.state === MARKETPLACE_APP_STATES.IN_REVIEW && (
        <InReviewMessage />
      )}
      {errors.length > 0 && <Errors />}

      <TabbedFormBody {...props} />

      {isAdminView ? (
        <>
          <AdminMessageForm
            adminMessage={fields.admin_message}
            onChange={(field, value) =>
              onChange(field, value, 'marketplaceApp')
            }
            isConcierge={fields.is_concierge_app}
            I18n={I18n}
          />
          <AdminFooter
            fields={fields}
            saveForm={saveForm}
            getSaveFields={getSaveFields}
            I18n={I18n}
            onChange={onChange}
            trackEvent={trackEvent}
            marketplaceDraftListingPath={marketplaceDraftListingPath}
            newDraftPreviewActive={newDraftPreviewActive}
          />
        </>
      ) : (
        <Footer
          canEdit={canEdit}
          canSubmitMarketplaceApp={canSubmitMarketplaceApp}
          marketplaceDraftListingPath={marketplaceDraftListingPath}
          newDraftPreviewActive={newDraftPreviewActive}
          valid={valid}
          helpEmail={helpEmail}
          fields={fields}
          saveForm={saveForm}
          getSaveFields={getSaveFields}
          I18n={I18n}
          onChange={onChange}
          trackEvent={trackEvent}
          validateFields={validateFields}
          setError={setError}
        />
      )}
    </Spinner>
  );
};

MarketplaceAppForm.propTypes = {
  canEdit: PT.bool.isRequired,
  canSubmitMarketplaceApp: PT.bool.isRequired,
  marketplaceDraftListingPath: PT.string.isRequired,
  newDraftPreviewActive: PT.bool.isRequired,
  fields: PT.shape({
    silent: PT.string,
    id: PT.string,
    state: PT.string,
    __modified__: PT.bool,
    public_name: PT.string,
    admin_message: PT.string,
    approval_state: PT.string,
    is_concierge_app: PT.bool,
  }).isRequired,
  publishedFields: PT.shape({}).isRequired,
  validations: PT.shape({}).isRequired,
  onChange: PT.func.isRequired,
  save: PT.func.isRequired,
  switchTab: PT.func.isRequired,
  tab: PT.string.isRequired,
  isProcoreEmployee: PT.bool,
  productTools: PT.arrayOf(
    PT.shape({
      id: PT.string,
      title: PT.string,
    })
  ),
  regions: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
      countries: PT.arrayOf(PT.shape({})),
    })
  ),
  countries: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
    })
  ),
  errors: PT.arrayOf(PT.string),
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  isAdminView: PT.bool.isRequired,
  setError: PT.func.isRequired,
  setImages: PT.func.isRequired,
  hasPublishedApp: PT.bool,
  unmappedFields: PT.shape({}),
  unmappedPublishedFields: PT.shape({}),
  mapMarketplaceAppFields: PT.func.isRequired,
  setLoadingValue: PT.func,
  setValid: PT.func,
  onUpdate: PT.func,
  errorsPresent: PT.func,
  trackEvent: PT.func,
  helpEmail: PT.string,
  valid: PT.bool.isRequired,
  validateFields: PT.func.isRequired,
  loadingValuesMap: PT.shape({
    global: PT.bool,
  }).isRequired,
  applicationEnabled: PT.bool,
};

MarketplaceAppForm.defaultProps = {
  hasPublishedApp: false,
  unmappedFields: {},
  unmappedPublishedFields: {},
  setLoadingValue: () => {},
  setValid: () => {},
  errorsPresent: () => {},
  trackEvent: () => {},
  isProcoreEmployee: false,
  helpEmail: '',
  marketplaceDraftListingPath: '',
  newDraftPreviewActive: false,
};

export default StateContainer(MarketplaceAppForm);
