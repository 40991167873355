import React from 'react';
import PT from 'prop-types';
import { pathOr } from 'ramda';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../StateContainer';

class TabSupport extends React.Component {
  render() {
    const {
      fields,
      validations,
      onFieldChange,
      I18n,
      isDisabled,
      FormLabel,
    } = this.props;

    const onChange = (...fieldPath) => (value) =>
      onFieldChange([MARKETPLACE_TABS.SUPPORT, ...fieldPath], value);

    return (
      <Form.Content>
        <Form.Column>
          <Form.Field
            required
            data-qa="marketplace-app-support-email"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['support_email']}
                contentI18nTag="supportEmail"
                copyText={fields.support_email}
              />
            }
            type="text"
            value={fields.support_email}
            errors={validations.support_email}
            onChange={(e) => onChange('support_email')(e.target.value)}
          />

          <Form.Field
            required
            data-qa="marketplace-app-support-website-url"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['website_link', 'url']}
                contentI18nTag="supportWebsiteUrl"
                copyText={fields.website_link.url}
              />
            }
            type="text"
            value={fields.website_link.url}
            errors={pathOr([], ['website_link', 'url'], validations)}
            onChange={(e) => onChange('website_link', 'url')(e.target.value)}
          />
        </Form.Column>

        <Form.Column>
          <Form.Field
            required
            data-qa="marketplace-app-support-website-label"
            disabled={isDisabled}
            label={
              <FormLabel
                changeFields={['website_link', 'label']}
                contentI18nTag="supportWebsiteLabel"
                copyText={fields.website_link.label}
              />
            }
            type="text"
            value={fields.website_link.label}
            errors={pathOr([], ['website_link', 'label'], validations)}
            onChange={(e) => onChange('website_link', 'label')(e.target.value)}
          />
        </Form.Column>
      </Form.Content>
    );
  }
}

TabSupport.propTypes = {
  I18n: PT.shape({}).isRequired,
  fields: PT.shape({}).isRequired,
  publishedFields: PT.shape({}).isRequired,
  validations: PT.shape({}).isRequired,
  isDisabled: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  hasChanged: PT.func.isRequired,
};

export default TabSupport;
