import React from 'react';
import { I18nProvider, useI18nContext } from '@procore/core-react';
import i18n from './i18n';
import MarketplaceAppForm from './MarketplaceAppForm';

const WrappedComponent = (props) => {
  const I18n = useI18nContext();
  return <MarketplaceAppForm I18n={I18n} {...props} />;
};

export default (props) => {
  return (
    <I18nProvider locale={i18n.locale} translations={i18n.translations}>
      <WrappedComponent {...props} />
    </I18nProvider>
  );
};
