import React from 'react';
import cx from 'classnames';
import PT from 'prop-types';
import { Field, RevealOnHover } from './Field';
import Textarea from './Textarea';
import Button from './Button';
import RadioButton from './RadioButton';
import { AsyncPicker, SelectPicker } from './Pickers';
import Checkbox from './Checkbox';
import {
  Column,
  Content,
  Description,
  Dropzone,
  Label,
  PopoverLabel,
  Row,
} from './Form';

const Form = ({ className, children, vertical }) => {
  const classes = cx('a-form', className, {
    vertical: !!vertical,
  });

  return <div className={classes}>{children}</div>;
};

Form.propTypes = {
  className: PT.string,
  children: PT.element,
  vertical: PT.bool,
};

Form.defaultProps = {
  className: '',
  children: <></>,
  vertical: false,
};

Form.Dropzone = Dropzone;
Form.Content = Content;
Form.Description = Description;
Form.Row = Row;
Form.Column = Column;

Form.Field = Field;
Form.RevealOnHover = RevealOnHover;
Form.Label = Label;
Form.PopoverLabel = PopoverLabel;
Form.Textarea = Textarea;
Form.Button = Button;
Form.RadioButton = RadioButton;

Form.AsyncPicker = AsyncPicker;
Form.SelectPicker = SelectPicker;
Form.Checkbox = Checkbox;

export default Form;
