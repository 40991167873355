import React from 'react';
import PT from 'prop-types';
import { filter, isEmpty, path, pathOr } from 'ramda';
import {
  Spinner,
  Box,
  Card,
  ErrorBanner,
  FlexList,
  Banner,
  Button,
  Breadcrumbs,
  Flex,
  Grid,
  ToolHeader,
} from '@procore/core-react';
import FlashArea from '@/react/shared/flashArea/FlashArea';
import { MANIFEST_TABS, MANIFEST_TYPES } from '@/react/shared/constants';
import MetricsGraph from '@/react/common/Metrics/MetricsGraph';
import {
  DEVELOPER_APP_PT,
  MANIFEST_PT,
  METRICS_DATA_PT,
  PRODUCT_TOOLS_PT,
  REGIONS_PT,
  COUNTRIES_PT,
} from '@/react/shared/developerAppShowPropTypes';
import AppSettingsForm from './AppSettingsForm';
import AppCredentialsForm from './AppCredentialsForm';
import ManifestsCard from './ManifestsCard';
import MarketplaceCard from './MarketplaceCard';
import SandboxCard from './sandbox/SandboxCard';
import FormDrivenNotAvailableModal from './modals/FormDrivenNotAvailableModal';
import { SECTIONS } from './StateContainer';

const PRODUCTION_PENDING = 'production_pending';
class Show extends React.Component {
  componentDidMount() {
    const { developerAppShowStore, developerAppId } = this.props;
    const { handlers } = developerAppShowStore;

    handlers.fetchPermissions(
      developerAppId,
      handlers.fetchPermissionsSuccess,
      handlers.onError
    );

    handlers.fetchDeveloperApp(
      developerAppId,
      handlers.fetchDeveloperAppSuccess,
      handlers.onError
    );

    handlers.fetchProductionManifests(
      developerAppId,
      handlers.fetchProductionManifestsSuccess,
      handlers.onError
    );

    handlers.fetchSandboxManifests(
      developerAppId,
      handlers.fetchSandboxManifestsSuccess,
      handlers.onError
    );
  }

  onSave = (section) => {
    const { developerAppShowStore, developerAppId } = this.props;
    const { handlers } = developerAppShowStore;

    handlers.updateDeveloperApp(
      developerAppId,
      section,
      handlers.updateDeveloperAppSuccess,
      handlers.onError
    );
  };

  onPostFeatureServiceJob = () => {
    const { developerAppShowStore, featureServiceKickoffPath } = this.props;
    const { handlers } = developerAppShowStore;

    handlers.postFeatureServiceJob(
      featureServiceKickoffPath,
      handlers.featureServiceKickoffPath,
      handlers.onError
    );
  };

  generateFakeSandbox = () => {
    const { developerAppShowStore, generateFakeSandboxPath } = this.props;
    const { handlers } = developerAppShowStore;

    handlers.generateFakeSandbox(
      generateFakeSandboxPath,
      handlers.generateFakeSandboxSuccess,
      handlers.onError
    );
  };

  onUpdateSandbox = (sandboxId) => {
    const { handlers } = this.props.developerAppShowStore;

    handlers.updateSandbox(
      sandboxId,
      handlers.updateSandboxSuccess,
      handlers.onError
    );
  };

  hasPreviousOAuthComponent = (manifests) => {
    return filter((manifest) => {
      return pathOr(
        [],
        ['content', 'components', 'oauth', 'instances'],
        manifest
      ).length;
    }, manifests).length;
  };

  onCreateManifest = (manifest, semanticVersion) => {
    const { developerAppShowStore, developerAppId } = this.props;
    const { state, handlers } = developerAppShowStore;

    const tab = state.manifestTab;

    if (tab === MANIFEST_TABS.PRODUCTION) {
      handlers.createManifest(
        developerAppId,
        MANIFEST_TYPES.PRODUCTION,
        manifest,
        semanticVersion,
        (appVersion) => {
          handlers.createProductionManifestSuccess(appVersion);
          this.enqueueToast(
            'New production manifest version created',
            'success'
          );
        },
        handlers.onError
      );
    } else {
      handlers.createManifest(
        developerAppId,
        MANIFEST_TYPES.SANDBOX,
        manifest,
        semanticVersion,
        (appVersion) => {
          // If this is the first sandbox manifest created with an OAuth component,
          // set the isFirstOAuthSandbox bool to true.
          // We only want to bother them about checking out their credentials once :^)
          if (
            !this.hasPreviousOAuthComponent(state.sandboxManifests) &&
            pathOr(
              [],
              ['manifest', 'content', 'components', 'oauth', 'instances'],
              appVersion
            ).length
          ) {
            handlers.setIsFirstOAuthManifest(true);
          }
          handlers.createSandboxManifestSuccess(appVersion);
          if (!path(['sandbox', 'app_id'], state.developerApp)) {
            this.onFetchDeveloperApp(developerAppId);
          }
          this.enqueueToast('New sandbox manifest version created', 'success');
        },
        handlers.onError
      );
    }
  };

  onPromoteManifest = (appVersionId, releaseNotes) => {
    const { state, handlers } = this.props.developerAppShowStore;
    const { developerAppId } = this.props;

    handlers.promoteManifest(
      appVersionId,
      releaseNotes,
      (promotedManifest) => {
        // If this is the first production manifest,
        // retrieve all dev app credentials including client secret
        if (!state.productionManifests.length) {
          this.onFetchDeveloperApp(developerAppId, () => {
            handlers.promoteManifestSuccess();
            handlers.fetchProductionManifests(
              developerAppId,
              handlers.fetchProductionManifestsSuccess,
              handlers.onError
            );
            handlers.fetchSandboxManifests(
              developerAppId,
              handlers.fetchSandboxManifestsSuccess,
              handlers.onError
            );
          });
        } else {
          handlers.promoteManifestSuccess();
          handlers.fetchProductionManifests(
            developerAppId,
            handlers.fetchProductionManifestsSuccess,
            handlers.onError
          );
          handlers.fetchSandboxManifests(
            developerAppId,
            handlers.fetchSandboxManifestsSuccess,
            handlers.onError
          );
        }
      },
      handlers.onError
    );
  };

  onUpdateAppVersion = (appVersion) => {
    const { handlers } = this.props.developerAppShowStore;
    const { developerAppId } = this.props;

    handlers.updateAppVersion(
      appVersion,
      () => {
        handlers.updateAppVersionSuccess();
        handlers.fetchProductionManifests(
          developerAppId,
          handlers.fetchProductionManifestsSuccess,
          handlers.onError
        );
        handlers.fetchSandboxManifests(
          developerAppId,
          handlers.fetchSandboxManifestsSuccess,
          handlers.onError
        );
      },
      handlers.onError
    );
  };

  onFetchDeveloperApp = (appID, cb = () => {}) => {
    const { handlers } = this.props.developerAppShowStore;

    handlers.fetchDeveloperApp(
      appID,
      (devApp) => {
        handlers.fetchDeveloperAppSuccess(devApp);
        cb();
      },
      handlers.onError
    );
  };

  enqueueToast = (message, variant) => {
    if (!this.props.enqueueToast[variant]) {
      console.error(`Invalid toast variant: ${variant}`);
      return;
    }
    this.props.enqueueToast[variant](message);
  };

  trackEvent = (key, params) => {
    const { handlers, state } = this.props.developerAppShowStore;
    const { developerAppId } = this.props;

    handlers.trackEvent(key, {
      developer_app_id: developerAppId,
      app_name: state.developerApp.settings.internal_name,
      ...params,
    });
  };

  createOrUpdateMarketplaceApp = (
    marketplaceAppFields,
    marketplaceAppOnSuccess,
    marketplaceAppOnError
  ) => {
    const {
      developerAppShowStore: {
        handlers: {
          createOrUpdateMarketplaceApp,
          createOrUpdateMarketplaceAppSuccess,
          onError,
        },
      },
    } = this.props;

    createOrUpdateMarketplaceApp(
      marketplaceAppFields,
      (marketplaceAppFieldsSuccess) => {
        createOrUpdateMarketplaceAppSuccess(marketplaceAppFieldsSuccess);
        marketplaceAppOnSuccess(marketplaceAppFieldsSuccess);
      },
      (errorMapKey, marketplaceAppFieldsError) => {
        onError(errorMapKey, marketplaceAppFieldsError);
        marketplaceAppOnError(marketplaceAppFieldsError);
      }
    );
  };

  // eslint-disable-next-line complexity
  render() {
    const {
      developerAppShowStore: {
        state: {
          appFieldPermissions,
          api,
          developerApp,
          developerAppsIndexPath,
          developerAppsShowPath,
          errorMap,
          loadingMap,
          manifestTab,
          productionManifests,
          resetCredentialsConfirmed,
          resetSandboxCredentialsConfirmed,
          savingMap,
          sandboxManifests,
          isFirstOAuthManifest,
        },
        handlers: {
          canGenerateSandbox,
          forgetSecret,
          onChange,
          onError,
          resetAppSecret,
          resetSandboxAppSecret,
          resetOrGetAppSecretSuccess,
          resetOrGetSandboxAppSecretSuccess,
          setIsFirstOAuthManifest,
          switchManifestTab,
          toggleResetCredentialsConfirmed,
          toggleResetSandboxCredentialsConfirmed,
          updateMarketplaceApp,
        },
      },
      appCredentialsDocPath,
      sandboxCredentialsDocPath,
      redirectUriDocPath,
      canEdit,
      canGenerateFakeSandbox,
      canModifyCredentials,
      canModifyManifests,
      canSubmitMarketplaceApp,
      marketplaceDraftListingPath,
      newDraftPreviewActive,
      canViewClientCredentials,
      canSetSemanticVersions,
      canCreateSandboxManifests,
      developerAppId,
      hasPublishedApp,
      I18n,
      manifestSupportDocPath,
      marketplaceReviewerEmail,
      productTools,
      regions,
      isProcoreEmployee,
      countries,
      sandboxStatus,
      secretResetCount,
      metricsData,
      developerAppMetricsPath,
      wantingFormDrivenExperience,
      dmsaPermissions,
      marketplaceListingApplicationEnabled
    } = this.props;

    const internalAppName = pathOr(
      '',
      ['settings', 'internal_name'],
      developerApp
    );

    const { app_installations, developer_app_id, ...aggregatesData } =
      metricsData;

    const maxBodyWidth = '1200px';

    return (
      <div style={{ margin: 'auto', maxWidth: maxBodyWidth }}>
        {wantingFormDrivenExperience && (
          <FormDrivenNotAvailableModal I18n={I18n} />
        )}
        {path(['settings', 'published_at'], developerApp) && (
          <FlashArea>
            {I18n.t('publishedAppWarning')}
            Please contact
            <a href="mailto:apisupport@procore.com"> apisupport@procore.com </a>
            if you need something changed.
          </FlashArea>
        )}
        {developerApp.production_access === PRODUCTION_PENDING && (
          <FlashArea>
            <i className="fa fa-exclamation-circle" aria-hidden="true" />
            {I18n.t('apiKeyApproval')}
          </FlashArea>
        )}
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Breadcrumbs className="breadcrumbs">
                <a href={developerAppsIndexPath}>
                  <Breadcrumbs.Crumb>{I18n.t('apps')}</Breadcrumbs.Crumb>
                </a>
                <a href={developerAppsShowPath}>
                  <Breadcrumbs.Crumb>{internalAppName}</Breadcrumbs.Crumb>
                </a>
                <Breadcrumbs.Crumb active>
                  {I18n.t('manageApp')}
                </Breadcrumbs.Crumb>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Grid>

        {!isEmpty(aggregatesData) && (
          <>
            <Grid>
              <Grid.Row>
                <Grid.Col>
                  <Flex justifyContent="flex-end">
                    <Box paddingTop="md">
                      <Button
                        onClick={() => {
                          this.trackEvent(
                            'developers.app_metrics.view_report.clicked'
                          );
                          window.location = developerAppMetricsPath;
                        }}
                      >
                        {I18n.t('viewMetricsReport')}
                      </Button>
                    </Box>
                  </Flex>
                </Grid.Col>
              </Grid.Row>
            </Grid>
            <ToolHeader>
              <ToolHeader.Title>{I18n.t('manageApp')}</ToolHeader.Title>
            </ToolHeader>
          </>
        )}
        <Box paddingTop="xl">
          {errorMap[SECTIONS.GLOBAL] && (
            <Box padding="xl none">
              <ErrorBanner>
                <Banner.Content>
                  <Banner.Title>{I18n.t('errorOccurred')}</Banner.Title>
                  <Banner.Body>{errorMap[SECTIONS.GLOBAL]}</Banner.Body>
                </Banner.Content>
              </ErrorBanner>
            </Box>
          )}
          <Spinner
            loading={loadingMap[SECTIONS.GLOBAL] || savingMap[SECTIONS.GLOBAL]}
          >
            <FlexList size="lg" direction="column" alignItems="stretch">
              {!isEmpty(aggregatesData) && (
                <MetricsGraph I18n={I18n} metricsData={aggregatesData} />
              )}
              <Spinner
                loading={
                  loadingMap[SECTIONS.SETTINGS] || savingMap[SECTIONS.SETTINGS]
                }
              >
                <Card>
                  <AppSettingsForm
                    onSave={() => this.onSave('settings')}
                    appFieldPermissions={appFieldPermissions}
                    canEdit={canEdit}
                    onChange={onChange}
                    fields={developerApp.settings}
                    developerAppId={developerAppId}
                    error={errorMap[SECTIONS.SETTINGS]}
                  />
                </Card>
              </Spinner>
              <ManifestsCard
                createManifest={this.onCreateManifest}
                promoteManifest={this.onPromoteManifest}
                updateAppVersion={this.onUpdateAppVersion}
                developerAppId={developerAppId}
                dmsaPermissions={dmsaPermissions}
                I18n={I18n}
                loadingState={loadingMap[SECTIONS.MANIFESTS]}
                manifestSupportDocPath={manifestSupportDocPath}
                productionManifests={productionManifests}
                sandboxManifests={sandboxManifests}
                sandboxStatus={sandboxStatus}
                canCreateSandboxManifests={canCreateSandboxManifests}
                sandboxUid={
                  sandboxStatus === 'live'
                    ? path(['sandbox', 'app_id'], developerApp)
                    : null
                }
                switchTab={switchManifestTab}
                tab={manifestTab}
                error={errorMap[SECTIONS.MANIFESTS]}
                showSandboxBanner={isFirstOAuthManifest}
                toggleSandboxBanner={setIsFirstOAuthManifest}
                canModifyManifests={canModifyManifests}
                trackEvent={this.trackEvent}
                canViewClientCredentials={canViewClientCredentials}
                canSetSemanticVersions={canSetSemanticVersions}
              />
              <Spinner
                loading={
                  loadingMap[SECTIONS.SANDBOX] || savingMap[SECTIONS.SANDBOX]
                }
              >
                <SandboxCard
                  I18n={I18n}
                  canEdit={canEdit}
                  developerAppId={developerAppId}
                  canGenerateSandbox={canGenerateSandbox}
                  canGenerateFakeSandbox={canGenerateFakeSandbox}
                  generateFakeSandbox={this.generateFakeSandbox}
                  fields={developerApp.sandbox}
                  onChange={onChange}
                  onPostFeatureServiceJob={this.onPostFeatureServiceJob}
                  onUpdateSandbox={this.onUpdateSandbox}
                  sandboxStatus={sandboxStatus}
                  error={errorMap[SECTIONS.SANDBOX]}
                  sandboxCredentialsDocPath={sandboxCredentialsDocPath}
                  redirectUriDocPath={redirectUriDocPath}
                  resetCredentialsConfirmed={resetSandboxCredentialsConfirmed}
                  toggleResetCredentialsConfirmed={
                    toggleResetSandboxCredentialsConfirmed
                  }
                  resetAppSecret={resetSandboxAppSecret}
                  resetAppSecretSuccess={resetOrGetSandboxAppSecretSuccess}
                  onError={onError}
                  hasSandboxManifest={!!sandboxManifests.length}
                />
              </Spinner>
              <Spinner
                loading={
                  loadingMap[SECTIONS.DEV_APP_FETCH] ||
                  loadingMap[SECTIONS.CREDENTIALS] ||
                  savingMap[SECTIONS.CREDENTIALS]
                }
              >
                {errorMap[SECTIONS.DEV_APP_FETCH] ? (
                  <FlashArea>
                    {I18n.t('credentialsError')} Please reload this page, and if
                    you continue to experience issues contact us at{' '}
                    <a href="mailto:apisupport@procore.com">
                      apisupport@procore.com
                    </a>
                  </FlashArea>
                ) : (
                  <Card>
                    <AppCredentialsForm
                      onSave={() => this.onSave('credentials')}
                      onError={onError}
                      canEdit={canEdit}
                      onChange={onChange}
                      fields={developerApp.credentials}
                      appFieldPermissions={appFieldPermissions}
                      appCredentialsDocPath={appCredentialsDocPath}
                      redirectUriDocPath={redirectUriDocPath}
                      resetAppSecret={resetAppSecret}
                      resetAppSecretSuccess={resetOrGetAppSecretSuccess}
                      developerAppId={developerAppId}
                      credentials={developerApp.credentials}
                      forgetSecret={forgetSecret}
                      loading={loadingMap[SECTIONS.CREDENTIALS]}
                      resetCredentialsConfirmed={resetCredentialsConfirmed}
                      toggleResetCredentialsConfirmed={
                        toggleResetCredentialsConfirmed
                      }
                      canModifyCredentials={canModifyCredentials}
                      error={errorMap[SECTIONS.CREDENTIALS]}
                      secretResetCount={secretResetCount}
                    />
                  </Card>
                )}
              </Spinner>
              <MarketplaceCard
                canEdit={canEdit}
                canSubmitMarketplaceApp={canSubmitMarketplaceApp}
                marketplaceDraftListingPath={marketplaceDraftListingPath}
                newDraftPreviewActive={newDraftPreviewActive}
                fields={developerApp.marketplaceApp}
                api={api}
                onUpdate={updateMarketplaceApp}
                onError={onError}
                productTools={productTools}
                isProcoreEmployee={isProcoreEmployee}
                regions={regions}
                countries={countries}
                helpEmail={marketplaceReviewerEmail}
                hasPublishedApp={hasPublishedApp}
                trackEvent={this.trackEvent}
                developerAppId={developerAppId}
                createOrUpdateMarketplaceApp={this.createOrUpdateMarketplaceApp}
                marketplaceEnabled={developerApp.settings.marketplace_enabled}
                marketplaceListingApplicationEnabled={marketplaceListingApplicationEnabled}
              />
            </FlexList>
          </Spinner>
        </Box>
      </div>
    );
  }
}

Show.propTypes = {
  developerAppShowStore: PT.shape({
    state: PT.shape({
      manifestTab: PT.string.isRequired,
      developerApp: PT.shape(DEVELOPER_APP_PT.TYPES),
      developerAppUid: PT.string.isRequired,
      productionManifests: PT.arrayOf(MANIFEST_PT),
      sandboxManifests: PT.arrayOf(MANIFEST_PT),
      appFieldPermissions: PT.shape({}).isRequired,
      resetCredentialsConfirmed: PT.bool.isRequired,
      errorMap: PT.shape({}).isRequired,
      loadingMap: PT.shape({}).isRequired,
      savingMap: PT.shape({}).isRequired,
      api: PT.shape({}).isRequired,
      isFirstOAuthManifest: PT.bool.isRequired,
      developerAppsIndexPath: PT.string,
      developerAppsShowPath: PT.string,
      resetSandboxCredentialsConfirmed: PT.bool,
    }),
    handlers: PT.shape({
      canGenerateSandbox: PT.func.isRequired,
      createManifest: PT.func.isRequired,
      createOrUpdateMarketplaceApp: PT.func.isRequired,
      createOrUpdateMarketplaceAppSuccess: PT.func.isRequired,
      createProductionManifestSuccess: PT.func.isRequired,
      createSandboxManifestSuccess: PT.func.isRequired,
      fetchDeveloperApp: PT.func.isRequired,
      fetchDeveloperAppSuccess: PT.func.isRequired,
      fetchPermissions: PT.func.isRequired,
      fetchPermissionsSuccess: PT.func.isRequired,
      fetchProductionManifests: PT.func.isRequired,
      fetchProductionManifestsSuccess: PT.func.isRequired,
      fetchSandboxManifests: PT.func.isRequired,
      fetchSandboxManifestsSuccess: PT.func.isRequired,
      forgetSecret: PT.func.isRequired,
      generateFakeSandbox: PT.func.isRequired,
      generateFakeSandboxSuccess: PT.func.isRequired,
      getAppSecret: PT.func.isRequired,
      onError: PT.func.isRequired,
      onChange: PT.func.isRequired,
      postFeatureServiceJob: PT.func.isRequired,
      postFeatureServiceJobSuccess: PT.func.isRequired,
      promoteManifest: PT.func.isRequired,
      promoteManifestSuccess: PT.func.isRequired,
      resetAppSecret: PT.func.isRequired,
      resetSandboxAppSecret: PT.func.isRequired,
      resetOrGetAppSecretSuccess: PT.func.isRequired,
      resetOrGetSandboxAppSecretSuccess: PT.func.isRequired,
      setIsFirstOAuthManifest: PT.func.isRequired,
      switchManifestTab: PT.func.isRequired,
      toggleResetCredentialsConfirmed: PT.func.isRequired,
      toggleResetSandboxCredentialsConfirmed: PT.func.isRequired,
      trackEvent: PT.func.isRequired,
      updateAppVersion: PT.func.isRequired,
      updateAppVersionSuccess: PT.func.isRequired,
      updateDeveloperApp: PT.func.isRequired,
      updateDeveloperAppSuccess: PT.func.isRequired,
      updateMarketplaceApp: PT.func.isRequired,
      updateSandbox: PT.func.isRequired,
      updateSandboxSuccess: PT.func.isRequired,
    }),
  }).isRequired,
  appCredentialsDocPath: PT.string.isRequired,
  redirectUriDocPath: PT.string.isRequired,
  canEdit: PT.bool.isRequired,
  canModifyCredentials: PT.bool.isRequired,
  canModifyManifests: PT.bool.isRequired,
  canSubmitMarketplaceApp: PT.bool.isRequired,
  marketplaceDraftListingPath: PT.string.isRequired,
  newDraftPreviewActive: PT.bool.isRequired,
  developerAppId: PT.string.isRequired,
  enqueueToast: PT.func.isRequired,
  hasPublishedApp: PT.bool.isRequired,
  featureServiceKickoffPath: PT.string.isRequired,
  manifestSupportDocPath: PT.string.isRequired,
  sandboxStatus: PT.string.isRequired,
  generateFakeSandboxPath: PT.string,
  errorMap: PT.shape({}),
  loadingMap: PT.shape({}),
  savingMap: PT.shape({}),
  sandboxCredentialsDocPath: PT.string,
  secretResetCount: PT.number.isRequired,
  canGenerateFakeSandbox: PT.bool,
  I18n: PT.shape({
    t: PT.func,
  }),
  marketplaceReviewerEmail: PT.string,
  productTools: PT.arrayOf(PT.shape(PRODUCT_TOOLS_PT.TYPES)),
  regions: PT.arrayOf(PT.shape(REGIONS_PT.TYPES)),
  isProcoreEmployee: PT.bool.isRequired,
  countries: PT.arrayOf(PT.shape(COUNTRIES_PT.TYPES)),
  metricsData: PT.shape(METRICS_DATA_PT.TYPES),
  developerAppMetricsPath: PT.string,
  canViewClientCredentials: PT.bool.isRequired,
  canSetSemanticVersions: PT.bool.isRequired,
  canCreateSandboxManifests: PT.bool.isRequired,
  dmsaPermissions: PT.shape({
    company: PT.array,
    project: PT.array,
  }),
};

Show.defaultProps = {
  errorMap: {},
  loadingMap: {},
  savingMap: {},
  generateFakeSandboxPath: '',
  sandboxCredentialsDocPath: '/',
  canGenerateFakeSandbox: false,
  I18n: {
    t: () => {},
  },
  marketplaceReviewerEmail: '',
  productTools: [],
  regions: [],
  isProcoreEmployee: false,
  countries: [],
  metricsData: METRICS_DATA_PT.DEFAULTS,
  developerAppMetricsPath: '',
};

export default Show;
