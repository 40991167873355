import React, { useEffect } from 'react';
import classNames from 'classnames';
import {
  FlexList,
  Tabs,
  useI18nContext,
} from '@procore/core-react';
import {
  ALL_TABS,
  DEFAULT_TABS,
  StyledTab,
  conditionalTabs,
} from './SideNav.constants';
import { UseShowContext } from '../Show.context';

const SideNav = () => {
  const i18n = useI18nContext();
  const {
    state: {
      devApp,
      setTab,
      tab,
      trackEvent,
    },
  } = UseShowContext();

  useEffect(()=>{},[]);

  const tabs = {
    ...DEFAULT_TABS,
    ...conditionalTabs(devApp)
  };

  const changeTab = (val) => {
    setTab(val);

    if (val === ALL_TABS.METRICS) {
      trackEvent('developers.app_metrics.view_report.clicked');
    }
  };

  return (
    <div data-testid={'side-nav-container'}>
      <FlexList direction='column' padding='16px 0'  space='none'>
        {Object.values(tabs).map(val => (
          <StyledTab
            className={classNames({selected: tab === val})}
            data-testid={`${val}-nav`}
            key={val}
          >
            <Tabs.Link
              data-testid={`${val}-nav-link`}
              onClick={() => changeTab(val)}
              paddingLeft='16px'
            >
              {i18n.t(val)}
            </Tabs.Link>
          </StyledTab>
        ))}
      </FlexList>
    </div>
  );
}

export default SideNav;
