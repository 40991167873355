import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Card = ({ className, children }) => {
  return <div className={cx('a-card', className)}>{children}</div>;
};

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Card.defaultProps = {
  className: '',
};

export default Card;
