import React, { useState, useEffect } from 'react';
import {
  Box,
  Breadcrumbs,
  H1,
  Page,
  Spinner,
  useI18nContext,
} from '@procore/core-react';
import { UseShowContext } from './Show.context';
import SideNav from './SideNav/SideNav';
import { ALL_TABS as TABS } from './SideNav/SideNav.constants';
import Collaborators from './tabs/Collaborators';
import Credentials from './tabs/Credentials';
import MarketplaceForm from './tabs/MarketplaceForm';
import Settings from './tabs/Settings';
import Versions from './tabs/Versions';
import Configurations from './tabs/Configurations';
import Metrics from './tabs/Metrics';
import SandboxMissingCard from './cards/SandboxMissingCard';

const PageContent = ({ tab, i18n }) => {
  switch (tab) {
    case TABS.CONFIGURATIONS:
      return <Configurations />;
    case TABS.VERSIONS:
      return <Versions />;
    case TABS.SETTINGS:
      return <Settings />;
    case TABS.COLLABORATORS:
      return <Collaborators />;
    case TABS.OAUTH_CREDENTIALS:
      return <Credentials />;
    case TABS.MARKETPLACE_LISTING:
      return <MarketplaceForm />;
    case TABS.METRICS:
      return <Metrics />;
    default:
      return `Insert ${i18n.t(tab, {
        defaultValue: 'Version',
      })} component here`;
  }
};

const Show = () => {
  const i18n = useI18nContext();
  const {
    developerAppId,
    api,
    state: {
      devApp,
      setDevApp,
      permissions,
      setPermissions,
      tab,
      currentManifest,
      setCurrentManifest,
    },
  } = UseShowContext();
  const [loading, setLoading] = useState(Boolean(devApp));

  useEffect(() => {
    if (Object.keys(devApp).length === 0) {
      setLoading(true);
      api.fetchDeveloperApp(developerAppId).then(({ data }) => {
        setDevApp(data);
        setLoading(false);
      });
    }
  }, [api, developerAppId, devApp, setDevApp]);

  useEffect(() => {
    if (Object.keys(permissions).length === 0) {
      api.fetchPermissions(developerAppId).then(({ data }) => {
        setPermissions(data);
      });
    }
  }, [api, developerAppId, permissions, setPermissions]);

  const maxBodyWidth = '1200px';

  return (
    <Spinner loading={loading}>
      <Page data-testid="show-container">
        <Page.Main>
          <Page.Header>
            <Page.Breadcrumbs>
              <a href="/developer_apps" target="_blank">
                <Breadcrumbs.Crumb>{i18n.t('apps')}</Breadcrumbs.Crumb>
              </a>
              <a href={`/developer_apps/${developerAppId}`} target="_blank" data-testid="path">
                <Breadcrumbs.Crumb>{devApp.internal_name}</Breadcrumbs.Crumb>
              </a>
            </Page.Breadcrumbs>
            <Page.Title data-testid="title">
              <H1>{devApp.internal_name}</H1>
            </Page.Title>
          </Page.Header>
          <Page.Body style={{ margin: 'auto', maxWidth: maxBodyWidth }}>
            {!devApp?.sandbox?.live ?
              (<SandboxMissingCard />) :
              (
                <Page.Row>
                  <Page.Column colWidth={3} style={{ height: '70vh' }}>
                    <SideNav />
                  </Page.Column>
                  <Page.Column colWidth={9}>
                    <Box data-testid={`${tab}-body`}>
                      <PageContent tab={tab} i18n={i18n} />
                    </Box>
                  </Page.Column>
                </Page.Row>
              )
            }
          </Page.Body>
        </Page.Main>
      </Page>
    </Spinner>
  );
};

export default Show;
