import React from 'react';
import PT from 'prop-types';
import { Card, Box } from '@procore/core-react';
import MarketplaceAppForm from '../../common/marketplace_form';
import { ListingApplication } from '../../developer_apps_beta/show/cards/marketplace/ListingApplication';

const MarketplaceCard = ({
  fields,
  api,
  onUpdate,
  productTools,
  isProcoreEmployee,
  regions,
  countries,
  canEdit,
  canSubmitMarketplaceApp,
  marketplaceDraftListingPath,
  newDraftPreviewActive,
  helpEmail,
  hasPublishedApp,
  trackEvent,
  createOrUpdateMarketplaceApp,
  developerAppId,
  marketplaceEnabled,
  marketplaceListingApplicationEnabled
}) => (
  <Card data-qa="marketplace-listing-card">
    {marketplaceListingApplicationEnabled && !marketplaceEnabled && (
      <Box padding="lg">
        <ListingApplication devAppId={developerAppId} />
      </Box>
    )}
    {(marketplaceEnabled || !marketplaceListingApplicationEnabled) && (
      <MarketplaceAppForm
        isAdminView={false}
        canEdit={canEdit}
        canSubmitMarketplaceApp={canSubmitMarketplaceApp}
        marketplaceDraftListingPath={marketplaceDraftListingPath}
        newDraftPreviewActive={newDraftPreviewActive}
        unmappedFields={fields}
        save={createOrUpdateMarketplaceApp} // Save expects a function that returns a promise
        uploadWistiaVideo={api.uploadWistiaVideo} // uploadWistiaVideo expects a function that returns a promise
        onUpdate={onUpdate}
        isProcoreEmployee={isProcoreEmployee}
        productTools={productTools}
        regions={regions}
        countries={countries}
        helpEmail={helpEmail}
        hasPublishedApp={hasPublishedApp}
        trackEvent={trackEvent}
      />
    )}
  </Card>
);

MarketplaceCard.propTypes = {
  fields: PT.shape({}),
  api: PT.shape({
    updateMarketplaceApp: PT.func,
    createMarketplaceApp: PT.func,
    uploadWistiaVideo: PT.func,
  }).isRequired,
  onUpdate: PT.func,
  productTools: PT.arrayOf(
    PT.shape({
      id: PT.string,
      title: PT.string,
    })
  ).isRequired,
  regions: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
      countries: PT.arrayOf(PT.shape({})),
    })
  ).isRequired,
  countries: PT.arrayOf(
    PT.shape({
      id: PT.string,
      name: PT.string,
    })
  ).isRequired,
  isProcoreEmployee: PT.bool.isRequired,
  canEdit: PT.bool.isRequired,
  canSubmitMarketplaceApp: PT.bool.isRequired,
  marketplaceDraftListingPath: PT.string.isRequired,
  newDraftPreviewActive: PT.bool.isRequired,
  helpEmail: PT.string.isRequired,
  hasPublishedApp: PT.bool.isRequired,
  trackEvent: PT.func,
  createOrUpdateMarketplaceApp: PT.func.isRequired,
};

MarketplaceCard.defaultProps = {
  fields: {},
  onUpdate: () => {},
  trackEvent: () => {},
};

export default MarketplaceCard;
