import React from 'react';
import PT from 'prop-types';
import { path } from 'ramda';
import Form from '@/react/shared/form';
import { Typography } from '@procore/core-react';

import { Info } from '@procore/core-icons';
import { MARKETPLACE_TABS } from '../StateContainer';
import ChangeIndicator from '../ChangeIndicator';

class TabRegions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      regionsState: props.regions.map((region) => {
        region.checked = !!props.fields.regions[region.id];
        const regionCountries = region.countries;
        if (regionCountries.length) {
          regionCountries.forEach((country) => {
            country.checked = !!props.fields.countries[country.id];
          });
          return this.checkIndeterminate(region);
        }

        return region;
      }),
    };
  }

  checkIndeterminate(region) {
    const { onFieldChange } = this.props;

    const regionCountries = region.countries;
    region.indeterminate = false;
    if (regionCountries.length) {
      const checkedCountries = regionCountries.filter(
        (country) => country.checked === true
      );
      region.indeterminate =
        checkedCountries.length > 0 &&
        checkedCountries.length < regionCountries.length;
      region.checked = checkedCountries.length === regionCountries.length;
      onFieldChange(
        [MARKETPLACE_TABS.REGIONS, 'regions', region.id],
        region.checked
      );
    }
    return region;
  }

  renderRegion(region, index) {
    const {
      fields,
      publishedFields,
      onFieldChange,
      isDisabled,
      isAdminView,
    } = this.props;

    const { regionsState } = this.state;

    const onChange = (...fieldPath) => (value) => {
      const newState = [...regionsState];
      newState[index].checked = value;

      newState[index].indeterminate = false;
      if (newState[index].countries.length) {
        newState[index].countries.forEach((country) => {
          country.checked = value;
          onFieldChange(
            [MARKETPLACE_TABS.REGIONS, 'countries', country.id],
            value
          );
        });
      }
      this.setState({
        regionsState: newState,
      });
      onFieldChange([MARKETPLACE_TABS.REGIONS, ...fieldPath], value);
    };

    return (
      <Form.Checkbox
        key={index}
        data-qa={`marketplace-region-${index}`}
        disabled={isDisabled}
        label={
          <ChangeIndicator
            changed={
              isAdminView &&
              !!path(['regions', region.id], fields) !==
                !!path(['regions', region.id], publishedFields)
            }
          >
            {region.name}
          </ChangeIndicator>
        }
        checked={region.checked}
        onClick={(e) => onChange('regions', region.id)(e.target.checked)}
        indeterminate={region.indeterminate}
      />
    );
  }

  renderCountry(country, index, regionIndex) {
    const {
      fields,
      publishedFields,
      onFieldChange,
      isDisabled,
      isAdminView,
    } = this.props;

    const { regionsState } = this.state;

    const onChange = (...fieldPath) => (value) => {
      const newState = [...regionsState];
      newState[regionIndex].countries[index].checked = value;
      newState[regionIndex] = this.checkIndeterminate(newState[regionIndex]);

      this.setState({
        regionsState: newState,
      });

      onFieldChange([MARKETPLACE_TABS.REGIONS, ...fieldPath], value);
    };

    return (
      <Form.Checkbox
        key={index}
        data-qa={`marketplace-country-${index}`}
        disabled={isDisabled}
        label={
          <ChangeIndicator
            changed={
              isAdminView &&
              !!path(['countries', country.id], fields) !==
                !!path(['countries', country.id], publishedFields)
            }
          >
            {country.name}
          </ChangeIndicator>
        }
        checked={country.checked}
        onClick={(e) => onChange('countries', country.id)(e.target.checked)}
      />
    );
  }

  render() {
    const NUM_OF_COLUMNS = 1;
    const { I18n, validations } = this.props;
    const { regionsState } = this.state;

    const chunkArray = (arr, chunkSize) => {
      const chunkedArr = [];
      for (let index = 0; index < arr.length; index += chunkSize) {
        const chunk = arr.slice(index, index + chunkSize);
        chunkedArr.push(chunk);
      }
      return chunkedArr;
    };

    return (
      <div>
        <Typography color="red50" intent="small" italic style={{ display: 'block', paddingBottom: '30px' }}>
          {validations.region_or_country}
        </Typography>
        <p>
          <strong>
            {I18n.t('labels.marketplaceApp.info.regionsRequirement')}
          </strong>
          <span className="required" style={{ color: '#d93a3c' }}>
            *
          </span>
          <a
            style={{ color: '#000' }}
            title="About Procore Regions & Countries"
            target="_blank"
            href="https://developers.procore.com/documentation/partner-content-reqs"
          >
            <Info size="sm" />
          </a>
        </p>
        <p>{I18n.t('labels.marketplaceApp.info.regionsNote')}<br />
        {I18n.t('labels.marketplaceApp.info.regionsOtherNote')}</p>
        <Form.Content>
          {regionsState.map((region, regionIdx) => (
            <div
              key={`body-region-${regionIdx}`}
              style={{
                borderBottom: '1px solid #e5e7e8',
                width: '100%',
                paddingTop: '10px',
                paddingBottom: '20px',
              }}
            >
              <div style={{ fontWeight: 'bold', fontSize: 'larger' }}>
                {this.renderRegion(region, regionIdx)}
              </div>
              <table style={{ width: '95%', marginLeft: '5%' }}>
                <thead>
                  <tr>
                    {[...Array(NUM_OF_COLUMNS)].map((_, idx) => (
                      <th
                        style={{ width: '30%' }}
                        key={`region-${regionIdx}-${idx}`}
                      />
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {chunkArray(region.countries, 3).map((row, rowIdx) => (
                    <tr key={`body-row-${rowIdx}}`}>
                      {row.map((country, colIdx) => (
                        <td
                          style={{ width: '30%' }}
                          key={`body-column-${colIdx}`}
                        >
                          {this.renderCountry(
                            country,
                            rowIdx * 3 + colIdx,
                            regionIdx
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </Form.Content>
        <div style={{ width: '95%', marginLeft: '5%', marginTop: '30px', marginBottom: '-40px', paddingLeft: '12px' }}>
          <strong>Don’t see your country?</strong>&nbsp;&nbsp;
          <a href="mailto:marketplaceqa@procore.com">Contact us to add it.</a>
        </div>
      </div>
    );
  }
}

TabRegions.propTypes = {
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  isAdminView: PT.bool.isRequired,
  fields: PT.shape({
    countries: PT.shape({}),
    regions: PT.shape({}),
  }).isRequired,
  publishedFields: PT.shape({}).isRequired,
  isDisabled: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  regions: PT.arrayOf(PT.shape({})),
};

export default TabRegions;
