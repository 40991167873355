import React from 'react';
import PT, { number } from 'prop-types';
import cx from 'classnames';
import MarkdownRenderer from '@/react/shared/MarkdownRenderer';
import moment from 'moment';

const i18n = {
  seeAllAnnouncements: 'See All Announcements',
  learnMore: 'Learn More',
};

class TopNavNotificationsList extends React.Component {
  getNotificationUrl = (basePath, notificationId) => {
    return `${basePath}/${notificationId}`;
  };

  getNotifications(notificationsBaseUrl, notifications) {
    return notifications.map((n) => {
      const clazz = cx('top-nav-notification-list-item', {
        unread: !n.read_at,
      });

      return (
        <li className={clazz} key={n.id}>
          <div className="top-nav-notification-header">
            <div className="title-column">{n.title}</div>
            <div className="date-column">
              <span className="dev-notification-timestamp">
                {moment(n.created_at).format('M/D/YY')}
              </span>
            </div>
          </div>
          <div className="summary">
            <MarkdownRenderer source={n.summary} />
          </div>
          <a href={this.getNotificationUrl(notificationsBaseUrl, n.id)}>
            {i18n.learnMore}
          </a>
        </li>
      );
    });
  }

  render() {
    const { notifications, previewNum, notificationsBaseUrl } = this.props;
    const previewNotifications = notifications.slice(0, previewNum);

    return (
      <div className="top-nav-notifications-list">
        {this.getNotifications(notificationsBaseUrl, previewNotifications)}
        <div className="see-all-announcements">
          <a href={notificationsBaseUrl}>{i18n.seeAllAnnouncements}</a>
        </div>
      </div>
    );
  }
}

TopNavNotificationsList.propTypes = {
  notificationsBaseUrl: PT.string.isRequired,
  notifications: PT.arrayOf(
    PT.shape({
      created_at: number,
      id: PT.number,
      summary: PT.string,
      title: PT.string,
      unread: PT.bool,
    })
  ).isRequired,
  previewNum: PT.number,
};

TopNavNotificationsList.defaultProps = {
  previewNum: 3,
};

export default TopNavNotificationsList;
