import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const CardBlock = ({ hasDivider, children }) => {
  const classes = cx('a-card-block', {
    'a-card-block--line-after': hasDivider,
  });

  return <div className={classes}>{children}</div>;
};

CardBlock.propTypes = {
  hasDivider: PropTypes.bool,
  children: PropTypes.node,
};

CardBlock.defaultProps = {
  hasDivider: false,
};

export default CardBlock;
