import React, { useEffect, useState } from 'react';
import { arrayOf, bool, number } from 'prop-types';
import { Dropdown, useI18nContext } from '@procore/core-react';
import { debounce } from '@/utils/functionUtil';
import { ExternalIcon, NavReferenceDropdown } from './styles';

const getRestOptions = (versions, I18n) =>
  versions.map((v) => ({
    label: `${I18n.t('rest')} v${v}`,
    value: `/reference/rest/v${v}/docs/rest-api-overview`,
  }));

const MA_DOCS = [
  {
    displayName: 'Levelset',
    href: 'https://developer.levelset.com/',
  },
  {
    displayName: 'Workforce Planning',
    href: 'https://wfp-quickconnect.readme.io/docs',
  },
];

function TopNavReference({ isActive, majorRestVersions, darkTheme }) {
  const I18n = useI18nContext();
  const [isMobile, setIsMobile] = useState();

  const restOptions = getRestOptions(majorRestVersions, I18n);

  const setMenuPosition = () => {
    setIsMobile(window.innerWidth < 1100);
  };

  useEffect(() => {
    setMenuPosition();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', debounce(setMenuPosition, 300));
    }
    return () => {
      window.removeEventListener('resize', debounce(setMenuPosition, 300));
    };
  }, []);

  return (
    <NavReferenceDropdown
      data-qa="nav-resource-dropdown-trigger"
      active={isActive}
      darkTheme={darkTheme}
      label={I18n.t('reference')}
      placement={isMobile ? 'left' : 'bottom'}
    >
      {restOptions.map((restOpt) => (
        <Dropdown.Item key={restOpt.label}>
          <a href={restOpt.value}>{restOpt.label}</a>
        </Dropdown.Item>
      ))}
      {MA_DOCS.map(({ displayName, href }) => (
        <Dropdown.Item
          target="_blank"
          rel="noopener noreferrer"
          key={displayName}
        >
          <a href={href}>
            {displayName}
            <ExternalIcon />
          </a>
        </Dropdown.Item>
      ))}
    </NavReferenceDropdown>
  );
}

TopNavReference.propTypes = {
  majorRestVersions: arrayOf(number),
  darkTheme: bool,
  isActive: bool,
};

TopNavReference.defaultProps = {
  majorRestVersions: [],
  darkTheme: false,
  isActive: false,
};

export default TopNavReference;
