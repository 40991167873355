import React from 'react';
import PT from 'prop-types';
import { I18nProvider } from '@procore/core-react';
import Show from './Show';
import { ShowContextProvider } from './Show.context';
import i18n from './i18n';
import { METRICS_DATA_PT } from '@/react/shared/developerAppShowPropTypes';

import { ToastAlertProvider } from '@procore/toast-alert';

const AppShowContainer = ({
  developerEmail,
  developerAppId,
  canEdit,
  canDestroy,
  regions,
  productTools,
  marketplaceReviewerEmail,
  marketplaceDraftListingPath,
  metricsData,
  developerAppMetricsPath,
  appCredentialsDocPath,
  redirectUriDocPath,
  wistiaApiKey,
  wistiaMarketplaceProjectId,
  isProcoreEmployee,
  sandboxCredentialsDocPath,
  sandboxStatus,
  generateFakeSandboxPath,
  featureServiceKickoffPath,
  dmsaPermissions,
  secretResetCount,
  manifestSupportDocPath,
  newDraftPreviewActive,
  marketplaceListingApplicationEnabled
}) => (
  <I18nProvider locale={i18n.locale} translations={i18n.translations}>
    <ToastAlertProvider>
      <ShowContextProvider
        developerEmail={developerEmail}
        developerAppId={developerAppId}
        canEdit={canEdit}
        canDestroy={canDestroy}
        regions={regions}
        productTools={productTools}
        marketplaceReviewerEmail={marketplaceReviewerEmail}
        marketplaceDraftListingPath={marketplaceDraftListingPath}
        metricsData={metricsData}
        developerAppMetricsPath={developerAppMetricsPath}
        appCredentialsDocPath={appCredentialsDocPath}
        redirectUriDocPath={redirectUriDocPath}
        wistiaApiKey={wistiaApiKey}
        wistiaMarketplaceProjectId={wistiaMarketplaceProjectId}
        isProcoreEmployee={isProcoreEmployee}
        sandboxCredentialsDocPath={sandboxCredentialsDocPath}
        sandboxStatus={sandboxStatus}
        generateFakeSandboxPath={generateFakeSandboxPath}
        featureServiceKickoffPath={featureServiceKickoffPath}
        dmsaPermissions={dmsaPermissions}
        secretResetCount={secretResetCount}
        manifestSupportDocPath={manifestSupportDocPath}
        newDraftPreviewActive={newDraftPreviewActive}
        marketplaceListingApplicationEnabled={marketplaceListingApplicationEnabled}
      >
        <Show />
      </ShowContextProvider>
    </ToastAlertProvider>
  </I18nProvider>
);

AppShowContainer.propTypes = {
  developerEmail: PT.string.isRequired,
  developerAppId: PT.string.isRequired,
  canEdit: PT.bool.isRequired,
  regions: PT.arrayOf(PT.shape({})),
  productTools: PT.arrayOf(PT.shape({})).isRequired,
  marketplaceReviewerEmail: PT.string.isRequired,
  marketplaceDraftListingPath: PT.string.isRequired,
  metricsData: PT.shape(METRICS_DATA_PT.TYPES),
  developerAppMetricsPath: PT.string.isRequired,
  appCredentialsDocPath: PT.string.isRequired,
  redirectUriDocPath: PT.string.isRequired,
  wistiaApiKey: PT.string.isRequired,
  wistiaMarketplaceProjectId: PT.string.isRequired,
  isProcoreEmployee: PT.bool.isRequired,
  dmsaPermissions: PT.shape({}),
  secretResetCount: PT.number.isRequired,
  manifestSupportDocPath: PT.string.isRequired,
};

export default AppShowContainer;
